import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { FormContext } from "../context/FormProvider";
import * as Messages from "../messages/messages.js";
import "./styles/text-field.css";
// import tooltipStyles from "./styles/tooltip.module.css";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { handleKeyDown, handleShowPhonePlaceholder } from "../main-helper.js";

/**
 * PhoneField Component
 * @description A component that renders a mui outlined input made specifically for phone fields.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the phone field.
 * @param {string} props.label - The label for the phone field.
 * @param {string} props.val - The value of the phone field.
 * @param {Object} props.state - The state object.
 * @param {Function} props.setState - The state setter function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {Array} props.autoData - The array of phone numbers.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @returns {JSX.Element} The rendered PhoneField component.
 */
const PhoneField = ({
  idProp,
  label,
  val,
  state,
  setState,
  req,
  debouncedSave,
  setIsLoading,
  autoData,
  disabledInput,
  disabledInputMessage,
  invalidData,
}) => {
  const phoneRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [suggestedPhone, setSuggestedPhone] = useState("");
  const [adjustMargin, setAdjustMargin] = useState(false);
  const { requestStatus, disableAll, handleInputFocus, handleInputDisable } =
    useContext(FormContext);
  const { clientId, projectId, queryClient, handleFormatPhone, userId } =
    useContext(DashboardContext);
  const formData = queryClient.getQueryData([
    "currentProject",
    clientId,
    projectId,
  ]);

  // Adds margin under the container if the helper text is open and the input is required
  useEffect(() => {
    if (req && openHelper) {
      setAdjustMargin(true);
    } else {
      setAdjustMargin(false);
    }
  }, [req, openHelper]);

  // Use effect that sets the placeholder value if one is found
  useEffect(() => {
    handleShowPhonePlaceholder(
      idProp,
      state,
      formData,
      placeholder,
      setPlaceholder,
      setSuggestedPhone,
      showPlaceholder,
    );
  }, [showPlaceholder, state, idProp, formData, placeholder]);

  // Outlined Inputs Event Handler
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const formattedPhone = handleFormatPhone(value);
    let newState = { ...state, [idProp]: formattedPhone };
    setState(newState);

    if (!placeholder || !placeholder.startsWith(formattedPhone)) {
      if (autoData && autoData.length > 0) {
        let match = autoData.find((number) => {
          if (!number) return false;
          return number.slice(0, formattedPhone.length) === formattedPhone;
        });

        if (match && value !== "") {
          setPlaceholder(match);
          setShowPlaceholder(true);
        } else if (value === "" && suggestedPhone !== "") {
          setPlaceholder("");
          setShowPlaceholder(true);
        } else {
          setPlaceholder("");
          setShowPlaceholder(false);
        }
      }
    } else if (suggestedPhone && suggestedPhone.startsWith(formattedPhone)) {
      let existingMatch =
        formattedPhone + suggestedPhone.slice(formattedPhone.length);
      setPlaceholder(existingMatch);
      setShowPlaceholder(true);
    }
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <>
      <div
        className="input-text-container"
        style={
          requestStatus === "RV" &&
          disabledInput === false &&
          invalidData &&
          invalidData["initialValue"] === val &&
          disabledInputMessage === ""
            ? { marginBottom: "15px" }
            : requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              ((invalidData["initialValue"] === val &&
                disabledInputMessage !== "") ||
                val !== "")
            ? { marginBottom: "35px" }
            : requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              val !== invalidData["initialValue"] &&
              val !== ""
            ? { marginBottom: "15px" }
            : adjustMargin && openHelper
            ? { marginBottom: "35px" }
            : { marginBottom: "15px" }
        }
      >
        <label className="text-form-label" htmlFor={"text-form"}>
          {label}:{" "}
        </label>
        <div className="text-info-container">
          <FormControl
            variant="outlined"
            id="text-form"
            classes={{ root: "form-text-root" }}
            className={`${idProp}-form-control ${
              requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              (val === invalidData["initialValue"] || val === "")
                ? "revise-input"
                : requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  val !== invalidData["initialValue"] &&
                  val !== ""
                ? "revised-input"
                : req
                ? "required"
                : ""
            }`}
          >
            <InputLabel
              id="text-field-label"
              htmlFor="text-field-input"
              classes={{ shrink: "text-field-label-shrink" }}
            >
              {`Add ${label}`}
            </InputLabel>
            <OutlinedInput
              type="tel"
              id="text-field"
              className={`${idProp}-input`}
              ref={phoneRef}
              label={`Add ${label}`}
              value={val}
              onChange={handlePhoneChange}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  idProp,
                  state,
                  setState,
                  autoData,
                  placeholder,
                  debouncedSave,
                  setIsLoading,
                  setPlaceholder,
                  setShowPlaceholder,
                  setOpen,
                  setOpenHelper,
                )
              }
              maxLength={12}
              autoComplete="off"
              onFocus={() => handleInputFocus(phoneRef, setOpenHelper)}
              onSelect={() => {
                if (state[idProp] === "") {
                  setOpen(true);
                } else {
                  setOpen(false);
                }
                setOpenHelper(true);
                setShowPlaceholder(true);
              }}
              onBlur={() => {
                setOpen(false);
                setShowPlaceholder(false);
                setOpenHelper(false);
              }}
              disabled={handleInputDisable(
                idProp,
                state,
                disabledInput,
                userId,
              )}
              classes={{
                root: "text-field-root",
                notchedOutline: "text-notchedOutline",
                input: "text-field-input",
              }}
              endAdornment={
                <Tooltip
                  id="clear-btn-tooltip"
                  classes={{
                    tooltip: "tooltip-clear-btn",
                    popper: "popper-clear-btn",
                    tooltipPlacementTop: "tooltip-top",
                  }}
                  title={val === "" ? "" : "Clear Field"}
                  placement="top"
                >
                  <InputAdornment
                    className="text-field-clear-adornment"
                    id="text-adornment"
                    position="end"
                    style={
                      (idProp === "submitter" && state["submitter"] !== "") ||
                      val === "" ||
                      handleInputDisable(
                        idProp,
                        state,
                        disabledInput,
                        userId,
                      ) === true
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    <IconButton
                      style={
                        (idProp === "submitterPhone" &&
                          state["submitterPhone"] !== "") ||
                        val === ""
                          ? { pointerEvents: "none", cursor: "default" }
                          : { pointerEvents: "auto", cursor: "pointer" }
                      }
                      aria-label="clear input field"
                      onClick={() => {
                        if (debouncedSave) {
                          let newState = { ...state, [idProp]: "" };
                          setState(newState);
                          setIsLoading(true);
                          setPlaceholder("");
                          setShowPlaceholder(false);
                          debouncedSave.mutate(newState);
                        } else {
                          let newState = { ...state, [idProp]: "" };
                          setState(newState);
                          setPlaceholder("");
                          setShowPlaceholder(false);
                        }
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      id="clear-icon-button"
                      disabled={
                        (idProp === "submitterPhone" &&
                          state["submitterPhone"] !== "") ||
                        val === ""
                          ? true
                          : false
                      }
                    >
                      <CloseIcon className="close-icon" />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              }
            />
            {openHelper === true && (
              <FormHelperText
                className={`helper-text ${
                  requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  (val === invalidData["initialValue"] || val === "")
                    ? "invalid-input-message"
                    : requestStatus === "RV" &&
                      disabledInput === false &&
                      invalidData &&
                      val !== invalidData["initialValue"] &&
                      val !== ""
                    ? "revised-input-message"
                    : requestStatus === "RV" &&
                      disabledInput === false &&
                      !disabledInputMessage &&
                      invalidData &&
                      val === ""
                    ? "revise-input-message"
                    : ""
                }`}
                classes={{
                  root: "text-field-helper",
                }}
              >
                {(requestStatus === "IP" || requestStatus === "I") &&
                  placeholder !== "" && (
                    <span className="fill-text-info">
                      Press <strong id="apple-tab">tab</strong> or the right
                      arrow key to autofill field with suggested value
                    </span>
                  )}
                {requestStatus === "RV" &&
                  disabledInput === false &&
                  disabledInputMessage &&
                  invalidData &&
                  val === invalidData["initialValue"] &&
                  `Note: ${disabledInputMessage}`}
                {requestStatus === "RV" &&
                  disabledInput === false &&
                  !disabledInputMessage &&
                  invalidData &&
                  val === "" &&
                  "This field was marked as invalid. Please provide an updated value."}
                {requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  val !== invalidData["initialValue"] &&
                  val !== "" &&
                  `**Revised: Value changed from ${invalidData["initialValue"]} to ${val}**`}
              </FormHelperText>
            )}
          </FormControl>
          <input
            key={`${idProp}-placeholder`}
            className={`${
              req ? "predictive-placeholder shift" : "predictive-placeholder"
            }`}
            type="tel"
            autoComplete="off"
            id="predictive-placeholder"
            value={placeholder}
            disabled={true}
            readOnly={true}
          />
          <Tooltip
            id="pf-tooltip"
            classes={{
              tooltip: "text-field-tooltip",
              popper: "text-field-popper",
              arrow: "text-field-arrow",
              tooltipPlacementRight: "text-field-right",
            }}
            arrow
            title={Messages[`${idProp}Message`]}
            placement={"top"}
            open={open}
            sx={{
              backgroundColor: "rgb(0, 0, 0, 0.8) !important",
            }}
          >
            <InfoCircle
              className="text-info-circ"
              id={`info-${idProp}`}
              color="white"
              size={30}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default PhoneField;
