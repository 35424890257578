import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import "../Tooltip/styles/tooltip.css";
import {
  fetchToken,
  validAdminUser,
  validClientUser,
} from "../Validation/useVerifyToken";
import DashboardAppBar from "./DashboardComponents/AppBar/DashboardAppBar";
import Sidebar from "./DashboardComponents/Sidebar/Sidebar";
import UserModal from "./DashboardComponents/UserModal/UserModal";
import { DashboardContext } from "./DashboardContext/DashboardContextProvider";
import "./styles/dashboard.css";

/**
 * Get the URL search parameters.
 * @see {@linkcode fetchToken}
 * @see {@linkcode validAdminUser}
 * @see {@linkcode validClientUser}
 * @returns {URLSearchParams} The URL search parameters.
 */
const getSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};

/**
 * Dashboard Component
 * @returns {JSX.Element} The rendered Dashboard component.
 * @description
 * The Dashboard component is the main entry point for the application. It
 * renders the main portal container, the dashboard app/nav bar, and the
 * sidebar. The component also handles the verification of user tokens and
 * displays a modal for users to sign in or add themselves to their company's
 * database.
 * @see {@linkcode DashboardAppBar}
 * @see {@linkcode Sidebar}
 * @see {@linkcode UserModal}
 * @see {@linkcode DashboardContext}
 */
const Dashboard = () => {
  const dispatch = useDispatch();

  // Context variables
  const {
    isAdmin,
    user,
    navigate,
    queryClient,
    openModal,
    setOpenModal,
    setHeading,
  } = useContext(DashboardContext);

  // Get the token from the URL if it exists
  const searchParams = getSearchParams();
  const token = searchParams.get("token");

  // Verify the token and set the user information in the redux store if the token is exists
  useEffect(() => {
    const tokenValidation = async () => {
      // Fetch the token and verify its validity
      const tokenValidity = await fetchToken(token, navigate);

      // Verify admin token
      if (tokenValidity.token.is_admin) {
        await validAdminUser(
          tokenValidity.data,
          setOpenModal,
          dispatch,
          navigate,
          queryClient,
          setHeading,
        );
      } else {
        // Verify client token
        await validClientUser(
          tokenValidity.data,
          openModal,
          setOpenModal,
          dispatch,
          navigate,
          queryClient,
          setHeading,
        );
      }
    };
    // If the token exists, validate it
    if (token) {
      tokenValidation();
    }
  }, [token]);

  return (
    <div className="main-portal-container" id="main-portal-container">
      <DashboardAppBar />
      {token === null &&
        isAdmin === false &&
        (user.id === null || user.id === "") && <UserModal />}
      <Outlet />
      <Sidebar />
    </div>
  );
};

export default Dashboard;
