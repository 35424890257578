import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import React, { useRef, useState } from "react";
// import styles from "../Inputs/styles/tooltip.module.css";
import "../../../../Tooltip/styles/tooltip.css";
import "../styles/form-container.css";
import "./styles/saved-indicator.css";

// Component that visualizes the saving status of the form
const SavedIndicator = ({ timeStamp, isLoading }) => {
  /*
  ^ If the timeStamp is not null and isLoading is false, display CheckCircle
  ^ If the timeStamp is null and isLoading is false, display Error
  ^ If the timeStamp is null and isLoading is true, display CircularProgress
  */

  const savedIcon = useRef(null);
  const savingIcon = useRef(null);
  const errorIcon = useRef(null);

  const [openSaved, setOpenSaved] = useState(false);
  const [openSaving, setOpenSaving] = useState(false);
  const [openError, setOpenError] = useState(false);

  return (
    <>
      <Box
        component="div"
        className="saved-indicator-container"
        id={"saved-container"}
      >
        <Tooltip
          title={`${
            !isLoading && timeStamp
              ? `Saved at ${timeStamp}`
              : isLoading
              ? "Saving..."
              : openError
              ? "An error occurred when saving this slide"
              : ""
          }`}
          id="saved-indicator-tooltip"
          classes={{
            tooltip: "saved-indicator-tooltip",
            popper: "popperSavedIndicator",
            tooltipPlacementBottom: "saved-indicator-popper",
          }}
        >
          <div id="save-icons-container">
            <Fade
              in={!isLoading && timeStamp !== null}
              timeout={{ enter: 50, exit: 15 }}
            >
              <SvgIcon
                ref={savedIcon}
                className="save-indicator"
                id="saved-indicator-icon"
                onMouseEnter={() => setOpenSaved(true)}
                onMouseLeave={() => setOpenSaved(false)}
                component={DoneIcon}
                shapeRendering="geometricPrecision"
              />
            </Fade>
            <Fade in={isLoading === true} timeout={{ enter: 50, exit: 15 }}>
              <CircularProgress
                ref={savingIcon}
                className="save-indicator"
                id="saving-progress-icon"
                onMouseEnter={() => setOpenSaving(true)}
                onMouseLeave={() => setOpenSaving(false)}
                size={42}
                variant="indeterminate"
                sx={{ color: "rgb(232, 232, 232)" }}
                thickness={5}
              />
            </Fade>
            <Fade
              in={!isLoading && !timeStamp}
              timeout={{ enter: 50, exit: 15 }}
            >
              <CloseIcon
                ref={errorIcon}
                className="save-indicator"
                id="saved-error-indicator-icon"
                onMouseEnter={() => setOpenError(true)}
                onMouseLeave={() => setOpenError(false)}
              />
            </Fade>
          </div>
        </Tooltip>
      </Box>
    </>
  );
};

export default SavedIndicator;
