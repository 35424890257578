import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../Redux/Actions/actions";
import "../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "./styles/user-settings.css";
import { useSnackbar } from "notistack";

// Component to edit user information (client only)
/**
 * UserSettings Component
 * @description The UserSettings component allows the user to edit their name, email, phone number, and title.
 * @returns {JSX.Element} The rendered UserSettings component.
 */
const UserSettings = () => {
  const { clientId, user, setHeading, handleFormatPhone } =
    useContext(DashboardContext);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [readOnly, setReadOnly] = useState(true);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [title, setTitle] = useState(user.title);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const titleRef = useRef(null);

  // Update the Appbars heading
  useEffect(() => {
    setHeading("User Settings");
  }, []);

  // Update user mutation
  const updateUserMutation = useMutation({
    mutationFn: async (userData) => {
      const res = await axios.patch(
        `/api/client/${clientId}/user/${user.id}/`,
        {
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          title: userData.title,
        },
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    onError: (error) => {
      enqueueSnackbar(
        "There was an error updating your information. Please try again.",
        { variant: "error" },
      );
    },
    onSuccess: (data) => {
      dispatch(
        updateUser({
          name: data.name,
          email: data.email,
          phone: data.phone,
          title: data.title,
          currentClient: data.client,
          archivedProjects: data.archivedProjects,
        }),
      );
      setReadOnly(true);

      enqueueSnackbar("Successfully updated your information!", {
        variant: "success",
      });
    },
  });

  return (
    <div id="user-settings-container">
      <div id="user-settings-form-container">
        <header id="user-settings-header">
          <h1 id="user-settings-h1">User Information</h1>
        </header>
        {/* <hr id="user-settings-hr" /> */}
        <div id="user-info-container">
          {/* Name */}
          <div className="settings-label-container">
            <label className="settings-label" htmlFor="settings-name-form">
              Name:
            </label>
            <FormControl
              id="settings-name-form"
              className="user-settings-form"
              classes={{ root: "user-settings-form-root" }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="user-name-input"
                className="user-settings-input-label"
                id="user-name-input-label"
                classes={{ shrink: "user-label-shrink" }}
              >
                Add your first and last name
              </InputLabel>
              <OutlinedInput
                id="user-name-input"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={readOnly}
                ref={nameRef}
                classes={{
                  root: "name-input-root",
                  notchedOutline: "settings-notchedOutline",
                  input: "name-input",
                  focused: "name-input-focused",
                }}
                endAdornment={
                  <Tooltip
                    id="clear-btn-tooltip"
                    classes={{
                      tooltip: "tooltip-clear-btn",
                      popper: "popper-clear-btn",
                      tooltipPlacementTop: "tooltip-top",
                    }}
                    title={name === "" ? "" : "Clear Field"}
                    placement="top"
                  >
                    <InputAdornment
                      className="text-field-clear-adornment"
                      id="text-adornment"
                      position="end"
                      style={
                        name === "" || readOnly
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <IconButton
                        style={
                          name === "" || readOnly
                            ? { pointerEvents: "none", cursor: "default" }
                            : { pointerEvents: "auto", cursor: "pointer" }
                        }
                        aria-label="clear input field"
                        onClick={() => {
                          setName("");
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        id="clear-icon-button"
                        disabled={name === "" || readOnly ? true : false}
                      >
                        <CloseIcon className="close-icon" />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </FormControl>
          </div>
          {/* Email */}
          <div className="settings-label-container">
            <label className="settings-label" htmlFor="settings-email-form">
              Email:
            </label>
            <FormControl
              id="settings-email-form"
              className="user-settings-form"
              classes={{ root: "user-settings-form-root" }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="user-email-input"
                className="user-settings-input-label"
                id="user-email-input-label"
                classes={{ shrink: "user-label-shrink" }}
              >
                Add your email address
              </InputLabel>
              <OutlinedInput
                id="user-email-input"
                type="email"
                value={email}
                ref={emailRef}
                onChange={(e) => setEmail(e.target.value)}
                disabled={readOnly}
                classes={{
                  root: "email-input-root",
                  input: "email-input",
                  notchedOutline: "settings-notchedOutline",
                  focused: "email-input-focused",
                }}
                endAdornment={
                  <Tooltip
                    id="clear-btn-tooltip"
                    classes={{
                      tooltip: "tooltip-clear-btn",
                      popper: "popper-clear-btn",
                      tooltipPlacementTop: "tooltip-top",
                    }}
                    title={email === "" ? "" : "Clear Field"}
                    placement="top"
                  >
                    <InputAdornment
                      className="text-field-clear-adornment"
                      id="text-adornment"
                      position="end"
                      style={
                        email === "" || readOnly
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <IconButton
                        style={
                          email === "" || readOnly
                            ? { pointerEvents: "none", cursor: "default" }
                            : { pointerEvents: "auto", cursor: "pointer" }
                        }
                        aria-label="clear input field"
                        onClick={() => {
                          setEmail("");
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        id="clear-icon-button"
                        disabled={email === "" || readOnly ? true : false}
                      >
                        <CloseIcon className="close-icon" />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </FormControl>
          </div>

          {/* Phone Number */}
          <div className="settings-label-container">
            <label className="settings-label" htmlFor="settings-phone-form">
              Phone Number:
            </label>
            <FormControl
              variant="outlined"
              id="settings-phone-form"
              className="user-settings-form"
              classes={{ root: "user-settings-form-root" }}
            >
              <InputLabel
                htmlFor="user-phone-input"
                className="user-settings-input-label"
                id="user-phone-input-label"
                classes={{ shrink: "user-label-shrink" }}
              >
                Add your phone number
              </InputLabel>
              <OutlinedInput
                id="user-phone-input"
                type="tel"
                value={phone}
                ref={phoneRef}
                onChange={(e) => {
                  const formattedPhone = handleFormatPhone(e.target.value);

                  setPhone(formattedPhone);
                }}
                disabled={readOnly}
                classes={{
                  root: "phone-input-root",
                  input: "phone-input",
                  notchedOutline: "settings-notchedOutline",
                  focused: "phone-input-focused",
                }}
                endAdornment={
                  <Tooltip
                    id="clear-btn-tooltip"
                    classes={{
                      tooltip: "tooltip-clear-btn",
                      popper: "popper-clear-btn",
                      tooltipPlacementTop: "tooltip-top",
                    }}
                    title={phone === "" ? "" : "Clear Field"}
                    placement="top"
                  >
                    <InputAdornment
                      className="text-field-clear-adornment"
                      id="text-adornment"
                      position="end"
                      style={
                        phone === "" || readOnly
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <IconButton
                        style={
                          phone === "" || readOnly
                            ? { pointerEvents: "none", cursor: "default" }
                            : { pointerEvents: "auto", cursor: "pointer" }
                        }
                        aria-label="clear input field"
                        onClick={() => {
                          setPhone("");
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        id="clear-icon-button"
                        disabled={name === "" || readOnly ? true : false}
                      >
                        <CloseIcon className="close-icon" />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </FormControl>
          </div>
          {/* Title */}
          <div className="settings-label-container">
            <label className="settings-label" htmlFor="settings-title-form">
              Title:
            </label>
            <FormControl
              id="settings-title-form"
              className="user-settings-form"
              classes={{ root: "user-settings-form-root" }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="user-title-input"
                id="user-title-input-label"
                className={`user-settings-input-label ${
                  title === "" && readOnly ? "empty-value-label" : ""
                }`}
                classes={{ shrink: "user-label-shrink" }}
              >
                {title === "" ? "Add your title" : "Update your title"}
              </InputLabel>
              <OutlinedInput
                id="user-title-input"
                type="text"
                value={title}
                ref={titleRef}
                onChange={(e) => setTitle(e.target.value)}
                disabled={readOnly}
                classes={{
                  root: "title-input-root",
                  input: "title-input",
                  notchedOutline: "settings-notchedOutline",
                  focused: "title-input-focused",
                }}
                endAdornment={
                  <Tooltip
                    id="clear-btn-tooltip"
                    classes={{
                      tooltip: "tooltip-clear-btn",
                      popper: "popper-clear-btn",
                      tooltipPlacementTop: "tooltip-top",
                    }}
                    title={title === "" ? "" : "Clear Field"}
                    placement="top"
                  >
                    <InputAdornment
                      className="text-field-clear-adornment"
                      id="text-adornment"
                      position="end"
                      style={
                        title === "" || readOnly
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      <IconButton
                        style={
                          title === "" || readOnly
                            ? { pointerEvents: "none", cursor: "default" }
                            : { pointerEvents: "auto", cursor: "pointer" }
                        }
                        aria-label="clear input field"
                        onClick={() => {
                          setTitle("");
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        id="clear-icon-button"
                        disabled={title === "" || readOnly ? true : false}
                      >
                        <CloseIcon className="close-icon" />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </FormControl>
          </div>
        </div>
        {/* Update/Save Changes Button */}
        <div id="settings-btn-container">
          <Button
            className="settings-btn"
            id={readOnly ? "update-settings-btn" : "save-changes-btn"}
            variant="contained"
            onClick={async (e) => {
              e.preventDefault();
              if (readOnly) {
                setReadOnly(false);
              } else {
                // dispatch user action
                const userData = {
                  ...user,
                  name: name,
                  email: email,
                  phone: phone,
                  title: title,
                };
                await updateUserMutation.mutateAsync(userData);
              }
            }}
          >
            {readOnly ? "Edit" : "Save Changes"}
          </Button>
          {readOnly === false && (
            <Button
              className="settings-btn"
              id="cancel-changes-btn"
              variant="contained"
              onClick={() => {
                setReadOnly(true);
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
