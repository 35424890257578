import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SvgIcon from "@mui/material/SvgIcon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { LockFill, UnlockFill } from "react-bootstrap-icons";
import useUpdateFormSlide from "../../../QueryHooks/request/useUpdateFormSlide";
import useDeleteInvalidInput from "../../../QueryHooks/validate/useDeleteInvalidInput";
import useSetInvalidInputs from "../../../QueryHooks/validate/useSetInvalidInput";
import "./styles/chip-modal.css";

/**
 * ChipModal Component
 * @description Modal component that allows the user to validate or invalidate a form input
 * @param {Object} props - Component props
 * @param {string} props.clientId - The client ID
 * @param {string} props.projectId - The project ID
 * @param {string} props.name - The name of the input
 * @param {boolean} props.open - The open state of the modal
 * @param {Function} props.setOpen - Function to set the open state of the modal
 * @param {string} props.openName - The name of the input that is open
 * @param {Function} props.setOpenName - Function to set the name of the input that is open
 * @param {Object} props.pageState - The state of the page
 * @param {Array} props.invalidInputs - The invalid inputs
 * @param {Object} props.formData - The form data
 * @param {Function} props.updateQuestionnaire - Function to update the questionnaire
 * @returns {JSX.Element} The rendered ChipModal component
 */
const ChipModal = ({
  clientId,
  projectId,
  name,
  open,
  setOpen,
  openName,
  setOpenName,
  pageState,
  invalidInputs,
  formData,
  updateQuestionnaire,
  enqueueSnackbar,
  inputGroup,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [slideNumber, setSlideNumber] = useState(pageState[name][2]);
  const [newInputValue, setNewInputValue] = useState(
    slideNumber
      ? formData[`slide${slideNumber}`][name]
      : formData[`slide${pageState[name[2]]}`][name],
  );
  const [inputLocked, setInputLocked] = useState(true);
  const [invalidInitialValue, setInvalidInitialValue] = useState("");
  const dateRef = useRef(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  // useEffect to get the value of the field from the form data
  useEffect(() => {
    if (slideNumber) {
      setNewInputValue(formData[`slide${slideNumber}`][name]);
    }
  }, [slideNumber]);
  // useEffect to set the initial value of the input
  useEffect(() => {
    if (invalidInputs && invalidInputs.length > 0) {
      // find the invalidInput that matches the name of the input
      // if it exists, set invalidInitialValue to the initialValue of the invalidInput
      const invalidInput = invalidInputs.filter((input) => input.name === name);

      if (invalidInput.length > 0 && invalidInput[0]["initialValue"] !== "") {
        setInvalidInitialValue(invalidInput[0].initialValue);
      }
    }
  }, []);

  // Open the modal if the open prop is true and the openName prop matches the name of the input
  useEffect(() => {
    if (open === true && openName === name) {
      setOpenModal(true);
    }
  }, [open, openName, name]);

  // useEffect to set the slide number of the input
  useEffect(() => {
    if (invalidInputs && invalidInputs.length > 0) {
      const invalidInput = invalidInputs.filter((input) => input.name === name);
      if (invalidInput.length > 0) {
        setInvalidMessage(invalidInput[0].message);
        setInvalid(() => true);
      }
    }
  }, [invalidInputs, name]);

  // ADD INVALID INPUT MUTATION
  const addInvalidInput = useSetInvalidInputs(clientId, projectId);

  // DELETE INVALID INPUT MUTATION
  const deleteInvalidInput = useDeleteInvalidInput(
    clientId,
    projectId,
    enqueueSnackbar,
  );

  // Mutation function d
  const updateFormSlide = useUpdateFormSlide(clientId, projectId, slideNumber);

  // Event handler that updates the form data with the new input value
  const handleInputValidation = async () => {
    const slideData = formData[`slide${slideNumber}`];
    const inputValue = slideData[name];
    const inputName = pageState[name][0];
    // this means the input is valid & we should delete it from the invalid inputs table
    if (invalid === false) {
      // If the newInputValue is empty, we won't update slide value and can
      if (newInputValue !== inputValue) {
        if (!slideData || slideData[name] === "undefined") return;
        const updatedSlide = { ...slideData, [name]: newInputValue };
        try {
          await updateFormSlide.mutateAsync(updatedSlide);
        } catch (err) {
          console.log("ERROR UPDATING FORM SLIDE: ", err);
          enqueueSnackbar(`Error updating ${inputName}. Please try again.`, {
            variant: "error",
          });

          return;
        }
        // Update the existing questionnaire pdf
        try {
          await updateQuestionnaire.mutateAsync();
        } catch (err) {
          // If there is an error updating the questionnaire, set the input to locked and display the error
          setInputLocked(true);
          enqueueSnackbar(`Error updating ${inputName}. Please try again.`, {
            variant: "error",
          });
          return;
        } finally {
          // If the update was successful, close the modal and display a success message
          setOpen(false);
          setOpenModal(false);
          setOpenName("");
          setInputLocked(true);
          enqueueSnackbar(`${inputName} has been updated successfully.`, {
            variant: "success",
          });
        }
      }
      // If the input is in the invalid inputs table, delete the input
      if (
        invalidInputs.length > 0 &&
        invalidInputs.filter((input) => input.name === name).length > 0
      ) {
        try {
          await deleteInvalidInput.mutateAsync(name);
        } catch (err) {
          enqueueSnackbar(`Error deleting ${inputName}. Please try again.`, {
            variant: "error",
          });
          return;
        } finally {
          // If the deletion was successful, close the modal and display a success message
          setOpen(false);
          setOpenModal(false);
          setOpenName("");
          enqueueSnackbar(`${inputName} has been validated successfully.`, {
            variant: "success",
          });
        }
      }
    } else {
      // this means the input is invalid & we should add it to the invalid inputs table
      let invalidData = {
        name: name,
        message: invalidMessage,
        slide: slideNumber,
        initialValue: inputValue.toString(),
      };
      try {
        // Add the input to the invalid inputs table
        await addInvalidInput.mutateAsync(invalidData);
        if (inputGroup[name]) {
          // If the input is part of a group, invalidate all inputs in the group
          for (const input of inputGroup[name]) {
            let inputData = {
              name: input,
              message: `Invalidated in association with ${inputName}`,
              slide: slideNumber,
              initialValue: slideData[input],
            };
            // Add the input to the invalid inputs table
            try {
              await addInvalidInput.mutateAsync(inputData);
            } catch (err) {
              enqueueSnackbar(
                `Error invalidating ${inputName}. Please try again.`,
                { variant: "error" },
              );
              return;
            }
          }
        }
      } catch (err) {
        enqueueSnackbar(`Error invalidating ${inputName}. Please try again.`, {
          variant: "error",
        });
        return;
      } finally {
        setOpen(false);
        setOpenModal(false);
        setOpenName("");

        enqueueSnackbar(`${inputName} has been invalidated successfully.`, {
          variant: "success",
        });
      }
    }
  };

  // Event handler to update date field inputs
  const handleDateChange = (newValue) => {
    let newState;
    let date = new Date(newValue["$d"]).toISOString().split("T")[0];
    let [year, month, day] = date.split("-");
    day = day[0] === "0" ? day[1] : day;
    date = `${month}-${day}-${year}`;
    newState = date;
    setNewInputValue(newState);
  };

  return openName === name ? (
    <Modal
      key={`${name}-modal`}
      id="validate-modal"
      open={openModal}
      onClose={() => {
        setOpen(false);
        setOpenModal(false);
        setOpenName("");
      }}
      aria-labelledby="input-modal-validation"
      aria-describedby="input-modal-validation"
      classes={{ root: "modal-root", backdrop: "modal-backdrop" }}
    >
      <Box
        id="chip-modal-container"
        sx={{
          height: "fit-content",
          width: pageState[name][3] !== "text" ? "810px" : "900px",
        }}
        key={`${name}-modal-form`}
      >
        <h2 id="modal-chip-header">{pageState[name][0]}</h2>
        <hr id="chip-modal-hr" />
        <p id="modal-description">
          Manually update the form value for the client here or set the switch
          to invalid to mark this value as incorrect on the web form. Note all
          changes made to this page will not save automatically and must be
          saved via the save button.
        </p>
        <div
          className={`modal-switch-container ${
            pageState[name][3] === "text" ? "text-area-switch" : ""
          }`}
        >
          {pageState[name][3] !== "bool" && (
            <div id="label-container">
              <label
                id="modal-switch-label"
                htmlFor={"modal-switch-form-control-label"}
              >
                {invalid ? "Validate:" : "Invalidate:"}
              </label>
              <FormControlLabel
                classes={{
                  root: "switch-form-label-root",
                  label: "switch-form-label",
                }}
                label={invalid ? "Invalid" : "Valid"}
                id="modal-switch-form-control-label"
                className={`modal-switch-formControlLabel ${
                  invalid ? "invalid" : ""
                }`}
                control={
                  <Switch
                    className="modal-switch"
                    checked={invalid}
                    disableRipple
                    classes={{
                      root: "modal-switch-root",
                      switchBase: "modal-switch-base",
                      input: "modal-switch-base-input",
                      checked: "modal-switch-checked",
                      thumb: "modal-switch-thumb",
                      track: "modal-switch-track",
                      colorPrimary: "modal-switch-color-primary",
                    }}
                    onChange={(e) => {
                      setInvalid(e.target.checked);
                      if (e.target.checked === false) {
                        setInvalidMessage("");
                      }
                    }}
                  />
                }
              />
            </div>
          )}
        </div>
        {!invalid ? (
          <div
            className={`modal-text-container ${
              pageState[name][3] === "bool" ? "modal-bool-container" : ""
            }`}
          >
            <label id="modal-input-label" htmlFor={`${name}-new-value`}>
              {inputLocked ? "Current Value:" : "New Value:"}
            </label>
            {pageState[name][3] === "str" ||
            pageState[name][3] === "email" ||
            pageState[name][3] === "phone" ? (
              <div id="locked-input-container">
                <FormControl
                  variant="outlined"
                  id="validate-text-form"
                  classes={{ root: "form-validate-root" }}
                >
                  <InputLabel
                    id="validate-text-label"
                    htmlFor="validate-input"
                    classes={{ shrink: "validate-input-label-shrink" }}
                  >
                    {invalidInitialValue
                      ? `Initial Value: ${invalidInitialValue}`
                      : null}
                  </InputLabel>
                  <OutlinedInput
                    id={`validate-text-field`}
                    className={`${name}-text-input`}
                    type="text"
                    value={newInputValue}
                    onChange={(e) => setNewInputValue(e.target.value)}
                    disabled={inputLocked}
                    classes={{
                      root: "validate-text-root",
                      notchedOutline: "validate-text-notchedOutline",
                      input: "validate-text-input",
                    }}
                    endAdornment={
                      <Checkbox
                        id={`${name}-lock-input`}
                        className={`modal-checkbox ${
                          inputLocked ? "locked" : "unlocked"
                        }`}
                        checked={inputLocked}
                        onChange={(e) => setInputLocked(e.target.checked)}
                        icon={<UnlockFill id="unlocked-icon" />}
                        checkedIcon={<LockFill id="locked-icon" />}
                      />
                    }
                  />
                  <FormHelperText
                    classes={{ root: "validate-field-helper" }}
                    className="helper-text"
                  >
                    {inputLocked
                      ? "Input Locked - Unlock to Manually Update Value "
                      : "You will not be able to return to the previous value once you save."}
                  </FormHelperText>
                </FormControl>
              </div>
            ) : pageState[name][3] === "bool" ? (
              <div id="locked-input-container-bool">
                <RadioGroup
                  aria-label="input-radio"
                  className="modal-radio-grouping"
                  classes={{ row: "radio-group-row" }}
                  name="input-radio"
                  value={newInputValue}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setNewInputValue(true);
                    } else if (e.target.value === "false") {
                      setNewInputValue(false);
                    }
                  }}
                  row
                >
                  <FormControlLabel
                    control={
                      <Radio
                        id={"validate-bool-true"}
                        value={true}
                        checked={newInputValue === true}
                        disabled={inputLocked}
                        classes={{
                          root: "modal-btn-true",
                          checked: "modal-btn-checked",
                        }}
                        icon={
                          <SvgIcon
                            component={CheckBoxOutlineBlankIcon}
                            className="modal-btn-icon-true"
                            viewBox="3.6 3.6 16.8 16.8"
                          />
                        }
                        checkedIcon={
                          <SvgIcon
                            component={CheckBoxIcon}
                            className="modal-btn-icon-true"
                            viewBox="3.6 3.6 16.8 16.8"
                          />
                        }
                      />
                    }
                    label="YES"
                    id="modal-radio-label-true"
                    classes={{
                      root: "modal-radio-btn-root",
                      label: "modal-radio-btn-label",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        id={"validate-bool-false"}
                        value={false}
                        checked={newInputValue === false}
                        disabled={inputLocked}
                        classes={{
                          root: "modal-btn-false",
                          checked: "modal-btn-checked",
                        }}
                        icon={
                          <SvgIcon
                            component={CheckBoxOutlineBlankIcon}
                            className="modal-btn-icon-false"
                            viewBox="3 3 18 18"
                          />
                        }
                        checkedIcon={
                          <SvgIcon
                            component={CloseIcon}
                            className="modal-btn-icon-false"
                            viewBox="2.5 2.5 19 19"
                          />
                        }
                      />
                    }
                    label="NO"
                    id="modal-radio-label-false"
                    classes={{
                      root: "modal-radio-btn-root",
                      label: "modal-radio-btn-label",
                    }}
                  />
                </RadioGroup>
                <FormControlLabel
                  id={
                    inputLocked ? clsx("locked-locked") : clsx("unlocked-label")
                  }
                  classes={{
                    root: clsx([
                      "lock-form-control",
                      inputLocked && "locked-label",
                      !inputLocked && "unlocked-label",
                    ]),
                    label: "lock-form-label",
                  }}
                  control={
                    <Checkbox
                      id={`${name}-lock-input`}
                      className={`modal-checkbox ${
                        inputLocked ? "locked" : "unlocked"
                      }`}
                      checked={inputLocked}
                      onChange={(e) => setInputLocked(e.target.checked)}
                      icon={<UnlockFill id="unlocked-icon" />}
                      checkedIcon={<LockFill id="locked-icon" />}
                    />
                  }
                />
              </div>
            ) : pageState[name][3] === "date" ? (
              <div id="locked-input-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-picker"
                    id={clsx("date-form-control")}
                    open={openCalendar}
                    ref={dateRef}
                    value={
                      pageState[name][1] ? dayjs(pageState[name][1]) : null
                    }
                    onChange={handleDateChange}
                    views={["year", "month", "day"]}
                    timezones={["America/Phoenix"]}
                    onClose={() => {
                      let newDate = dateRef.current.value;

                      if (newDate && newDate.split("-").length === 3) {
                        setNewInputValue(newDate);
                        setOpenCalendar(false);
                      }

                      setOpenCalendar(false);
                      setOpen(false);
                    }}
                    sx={{
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                      width: "400px",
                      backgroundColor: "var(--input-white-bg)",
                      boxShadow: "var(--input-box-shadow)",
                    }}
                    slots={{
                      inputAdornment: (slotProps) => {
                        return (
                          <Tooltip
                            id="calendar-tooltip"
                            classes={{
                              tooltip: "tooltip-calendar",
                              popper: "popper-calendar",
                              tooltipPlacementTop: "calendar-tooltip-top",
                            }}
                            title={"Open Calendar"}
                            placement="top"
                          >
                            <IconButton
                              id="date-input-adornment-btn"
                              onClick={slotProps.children.props.onClick}
                              className="date-input-adornment"
                              sx={{
                                visibility: inputLocked ? "hidden" : "visible",
                              }}
                            >
                              <InsertInvitationIcon
                                id="date-input-adornment"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        );
                      },
                    }}
                    slotProps={{
                      field: {
                        id: "date-field",
                      },
                      textField: {
                        InputProps: {
                          className: "date-outlined",
                          classes: {
                            root: "validate-date-field-root",
                            notchedOutline: "date-notchedOutline",
                          },
                        },
                      },
                      inputAdornment: {
                        id: "date-field-adornment",
                      },
                      openPickerButton: {
                        onClick: () => {
                          setOpenCalendar(!openCalendar);
                        },
                        id: "date-input-adornment-btn",
                      },
                      openPickerIcon: {
                        id: "date-input-adornment",
                      },
                      popper: {
                        id: "date-popper",
                      },
                      desktopPaper: {
                        id: "date-desktop-paper",
                      },
                      layout: {
                        className: "date-layout",
                        classes: {
                          contentWrapper: "date-content-wrapper",
                        },
                      },
                      calendarHeader: {
                        className: "date-calendar-header",
                        id: clsx("date-calendar-header"),
                        classes: {
                          viewTransitionContainer:
                            "date-view-transition-container",
                        },
                      },
                      switchViewButton: {
                        id: "date-switch-view-btn",
                      },
                      switchViewIcon: {
                        id: "date-switch-view-icon",
                      },
                      day: {
                        id: "calendar-day",
                      },
                      previousIconButton: {
                        id: "date-previous-icon-btn",
                      },
                      leftArrowIcon: {
                        id: "date-left-arrow",
                      },
                      nextIconButton: {
                        id: "date-next-icon-btn",
                      },
                      rightArrowIcon: {
                        id: "date-right-arrow",
                      },
                    }}
                    disabled={inputLocked}
                  />
                </LocalizationProvider>
                <FormControlLabel
                  id={
                    inputLocked ? clsx("locked-locked") : clsx("unlocked-label")
                  }
                  classes={{
                    root: clsx([
                      "lock-form-control",
                      inputLocked && "locked-label",
                      !inputLocked && "unlocked-label",
                    ]),
                    label: "lock-form-label",
                  }}
                  control={
                    <Checkbox
                      id={`${name}-lock-input`}
                      className="modal-checkbox"
                      checked={inputLocked}
                      onChange={(e) => setInputLocked(e.target.checked)}
                      icon={<UnlockFill id="unlocked-icon" />}
                      checkedIcon={<LockFill id="locked-icon" />}
                    />
                  }
                  label={inputLocked ? "Locked" : "Unlocked"}
                />
              </div>
            ) : pageState[name][3] === "text" ? (
              <div id="locked-text-area-container">
                <textarea
                  id={`${name}-new-value`}
                  className="text-area-input"
                  type="text"
                  value={newInputValue}
                  onChange={(e) => setNewInputValue(e.target.value)}
                  disabled={inputLocked}
                />
                <FormControlLabel
                  classes={{
                    root: clsx([
                      "lock-form-control",
                      inputLocked && "locked-label",
                      !inputLocked && "unlocked-label",
                    ]),
                    label: "lock-form-label",
                  }}
                  control={
                    <Checkbox
                      id={`${name}-lock-input`}
                      className="modal-checkbox"
                      checked={inputLocked}
                      onChange={(e) => setInputLocked(e.target.checked)}
                      icon={<LockOpen id="unlocked-icon" />}
                      checkedIcon={<Lock id="locked-icon" />}
                    />
                  }
                  label={inputLocked ? "Locked" : "Unlocked"}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className="modal-message-container"
            id={pageState[name][3] === "text" ? "modal-message-ta" : ""}
          >
            <label id="modal-message-label" htmlFor={`${name}-message`}>
              Message:
            </label>
            <textarea
              id={`${name}-message`}
              className="message-input"
              type="text"
              value={invalidMessage}
              onChange={(e) => setInvalidMessage(e.target.value)}
              maxLength="250"
            />
          </div>
        )}
        <div className="modal-btns">
          <Button
            id="close-btn"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setOpenModal(false);
              setOpenName("");
            }}
          >
            Close
          </Button>
          <Button
            id="save-btn"
            variant="contained"
            onClick={handleInputValidation}
            disabled={updateQuestionnaire.isLoading}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  ) : null;
};

export default ChipModal;
