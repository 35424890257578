import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import "./styles/create-new-client.css";

// Custom Input Field component specific to CreateClient component
/**
 * InputField Component
 *
 * @description
 * The component renders an input field with a label and a tooltip that displays the tooltipMessage when the input field is focused.
 *
 * @returns {JSXElement}
 */
const InputField = ({
  label,
  state,
  valueKey,
  value,
  setValue,
  tooltipMessage,
  inputType,
}) => {
  const [openHelper, setOpenHelper] = useState(false);
  const { handleFormatPhone } = useContext(DashboardContext);

  // Phone number input handler
  const handlePhoneChange = (e) => {
    const formattedPhone = handleFormatPhone(e.target.value);
    setValue({ ...state, [valueKey]: formattedPhone });
  };

  // Text input handler
  const handleTextChange = (e) => {
    let value = e.target.value;
    let formattedValue = value.replace(/[^a-zA-Z0-9- ,'.& #]/g, "");

    setValue({ ...state, [valueKey]: formattedValue });
  };

  // Event handler object to be used to determine which handler to use the OutlinedInput onChange event
  const eventHanders = {
    phone: handlePhoneChange,
    text: handleTextChange,
  };
  return (
    <div className="inputs-container">
      <label htmlFor="input-form" className="input-label">
        {label}:
      </label>
      <div className="input-info-container">
        <FormControl
          variant="outlined"
          id="input-form"
          className="input-form"
          classes={{
            root: "input-form-root",
          }}
        >
          <InputLabel
            id="form-label"
            htmlFor="input-text"
            classes={{
              shrink: "input-label-shrink",
            }}
          >
            {`Add ${label}`}
          </InputLabel>
          <OutlinedInput
            type={inputType}
            id="input-text"
            value={value}
            autoComplete="off"
            classes={{
              root: "input-text-root",
              notchedOutline: "input-text-notched-outline",
              input: "input-text-input",
            }}
            onFocus={() => setOpenHelper(true)}
            onBlur={() => setOpenHelper(false)}
            endAdornment={
              <Tooltip
                id="clear-btn-tooltip"
                classes={{
                  tooltip: "tooltip-clear-btn",
                  popper: "popper-clear-btn",
                  tooltipPlacementTop: "tooltip-top",
                }}
                placement="top"
                title={value ? "Clear Field" : ""}
              >
                <InputAdornment
                  className="input-clear-adornment"
                  id="clear-adornment"
                  position="end"
                  style={{
                    visibility: value ? "visible" : "hidden",
                  }}
                >
                  <IconButton
                    style={{
                      pointerEvents: value ? "auto" : "none",
                      cursor: value ? "pointer" : "default",
                    }}
                    onClick={() => {
                      setValue({ ...state, [valueKey]: "" });
                    }}
                    edge="end"
                    id="clear-input"
                    disabled={value ? false : true}
                  >
                    <CloseIcon className="close-icon" />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            }
            onChange={eventHanders[inputType]}
          />
          {valueKey === "name" && openHelper && (
            <FormHelperText id="helper-text" className="helper-text">
              {
                "Full name to be used on official documents. (Sun State Builders, Inc., etc.)"
              }
            </FormHelperText>
          )}
          {valueKey === "shortName" && openHelper && (
            <FormHelperText id="helper-text" className="helper-text">
              Abbreviated name to be used in the system. (Sun State Builders,
              Okland, etc.)
            </FormHelperText>
          )}
        </FormControl>
        <Tooltip
          id="tf-tooltip"
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
            tooltipPlacementLeft: "text-field-right",
          }}
          placement="right"
          arrow
          sx={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
          title={tooltipMessage}
        >
          <InfoCircle
            className="text-info-circ client-info-circ"
            color="white"
            size={30}
          />
        </Tooltip>
      </div>
    </div>
  );
};

// Component to display a list of existing clients to allow for editing an already existing client
/**
 * ExistingClients Component
 *
 * @description
 * The component displays a list of existing clients and allows the user to edit an existing client's information.
 *
 * @returns {JSXElement}
 */
const ExistingClients = ({
  existingClientsData,
  setExistingClient,
  mode,
  setMode,
}) => {
  const [sortedClients, setSortedClients] = useState([]);

  // Sorting the existing clients by name
  useEffect(() => {
    if (existingClientsData) {
      setSortedClients(
        existingClientsData.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [existingClientsData]);

  return (
    <div
      className="existing-clients-container"
      id={mode === "update" ? "taller" : ""}
    >
      <header id="existing-client-header">
        <h1 id="existing-clients-header">Existing Clients</h1>
      </header>
      <div className="clients-list">
        {existingClientsData.map((client) => (
          <div key={client.id} className="client-item">
            <p className="client-data" id="name">
              {client.name}
            </p>
            {client.shortName && client.shortName !== client.name ? (
              <p className="client-data">({client.shortName})</p>
            ) : null}
            <Button
              variant="contained"
              id="edit-client-btn"
              className="edit-client-btn"
              onClick={() => {
                setExistingClient({
                  id: client.id,
                  name: client.name,
                  shortName: client.shortName,
                  address: client.address,
                  city: client.city,
                  zipCode: client.zipCode,
                  phone: client.phone,
                  passcode: client.companyCode,
                });
                setMode("update");
              }}
            >
              Edit
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

// Main CreateClient component
/**
 * CreateClient Component
 * @description The component allows the user to create a new client or edit an existing client's information.
 * @returns {JSXElement}
 */
const CreateClient = () => {
  const { setHeading, queryClient } = useContext(DashboardContext);

  // Update Appbars heading
  useEffect(() => {
    setHeading("Create New Client");
  }, []);
  // State for mode (create or update)
  const [mode, setMode] = useState("create");
  // State for new client data
  const [newClient, setNewClient] = useState({
    name: "",
    shortName: "",
    address: "",
    city: "",
    zipCode: "",
    phone: "",
  });
  // State for existing client data
  const [existingClient, setExistingClient] = useState({
    name: "",
    shortName: "",
    address: "",
    city: "",
    zipCode: "",
    phone: "",
    passcode: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  // Query to get existing clients
  const { data: existingClientsData, isFetched } = useQuery({
    queryKey: ["existingClients"],
    queryFn: async () => {
      const res = await axios.get("/api/clients/", { withCredentials: true });

      if (res.status === 200 && res.data.length > 0) {
        let returnData = res.data.sort((a, b) => a.name.localeCompare(b.name));

        return returnData;
      }

      return res.data;
    },
  });

  // Mutation to create a new client
  const createClientMutation = useMutation({
    mutationFn: async (newClient) => {
      let newData = {};
      if (newClient.shortName === "") {
        newData = {
          ...newClient,
          shortName: newClient.name,
        };
        setNewClient(newData);
      } else {
        newData = newClient;
      }
      const res = await axios.post("/api/client/new/", newData, {
        withCredentials: true,
      });
      return res;
    },
    onError: (error) => {
      enqueueSnackbar(`Error creating client ${newClient.shortName}`, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar(`${newClient.shortName} has successfully been created!`, {
        variant: "success",
      });
      setNewClient({
        name: "",
        shortName: "",
        address: "",
        city: "",
        zipCode: "",
        phone: "",
      });
    },
  });

  // Mutation to update an existing client
  const updateClientMutation = useMutation({
    mutationFn: async (existingClient) => {
      const res = await axios.patch(
        `/api/client/update/${existingClient.id}/`,
        existingClient,
        {
          withCredentials: true,
        },
      );
      return res;
    },
    onError: (error) => {
      enqueueSnackbar(
        `Error updating ${existingClient.shortName}'s information`,
        { variant: "error" },
      );
    },
    onSuccess: (data) => {
      enqueueSnackbar(
        `${existingClient.shortName}'s information has successfully been updated!`,
        { variant: "success" },
      );
      setExistingClient({
        name: "",
        shortName: "",
        address: "",
        city: "",
        zipCode: "",
        phone: "",
        passcode: "",
      });
      setMode("create");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["existingClients"] });
    },
  });

  return (
    <>
      <div className="create-client-container">
        <Box id={mode === "create" ? "create-client" : "edit-client"}>
          <header id="client-header">
            <h1 id="create-client-header">Create New Client</h1>
          </header>
          <div className="create-client-form-container">
            <InputField
              label="Client Name"
              state={mode === "create" ? newClient : existingClient}
              valueKey="name"
              value={mode === "create" ? newClient.name : existingClient.name}
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="Enter the full name of the client. (Argus Construction Services)"
              inputType="text"
            />
            <InputField
              label="Short Name"
              state={mode === "create" ? newClient : existingClient}
              valueKey="shortName"
              value={
                mode === "create"
                  ? newClient.shortName
                  : existingClient.shortName
              }
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="Enter an abbreviated name for the client. (Argus or Argus CS)"
              inputType="text"
            />
            <InputField
              label="Address"
              state={mode === "create" ? newClient : existingClient}
              valueKey="address"
              value={
                mode === "create" ? newClient.address : existingClient.address
              }
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="Client's physical address. (1234 Main St.)"
              inputType="text"
            />
            <InputField
              label="City"
              state={mode === "create" ? newClient : existingClient}
              valueKey="city"
              value={mode === "create" ? newClient.city : existingClient.city}
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="City where the client is located"
              inputType="text"
            />
            <InputField
              label="Zip Code"
              state={mode === "create" ? newClient : existingClient}
              valueKey="zipCode"
              value={
                mode === "create" ? newClient.zipCode : existingClient.zipCode
              }
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="Client's zip code"
              inputType="text"
            />
            <InputField
              label="Phone Number"
              state={mode === "create" ? newClient : existingClient}
              valueKey="phone"
              value={mode === "create" ? newClient.phone : existingClient.phone}
              setValue={mode === "create" ? setNewClient : setExistingClient}
              tooltipMessage="Client's phone number"
              inputType="phone"
            />
            {mode === "update" && (
              <InputField
                label="Passcode"
                state={existingClient}
                valueKey="passcode"
                value={existingClient.passcode}
                setValue={setExistingClient}
                tooltipMessage="Client's passcode"
                inputType="text"
              />
            )}
          </div>
          {mode === "create" && (
            <Button
              type="submit"
              variant="contained"
              id="submit-new-client-btn"
              className="submit-new-client-btn"
              disabled={
                !(
                  newClient.name &&
                  newClient.address &&
                  newClient.city &&
                  newClient.zipCode &&
                  newClient.phone
                )
              }
              onClick={async (e) => {
                e.preventDefault();
                if (
                  newClient.name &&
                  newClient.address &&
                  newClient.city &&
                  newClient.zipCode &&
                  newClient.phone
                ) {
                  await createClientMutation.mutateAsync(newClient);
                }
              }}
            >
              Create
            </Button>
          )}
          {mode === "update" && (
            <div className="update-client-btns">
              <Button
                variant="contained"
                id="update-client-btn"
                className="update-client-btn"
                onClick={async (e) => {
                  e.preventDefault();
                  await updateClientMutation.mutateAsync(existingClient);
                }}
              >
                Update
              </Button>
              <Button
                variant="contained"
                id="cancel-edit-client-btn"
                className="cancel-edit-client-btn"
                onClick={() => {
                  setMode("create");
                  setExistingClient({
                    name: "",
                    shortName: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    phone: "",
                    passcode: "",
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </Box>
        {isFetched && existingClientsData && existingClientsData.length > 0 && (
          <ExistingClients
            existingClientsData={existingClientsData}
            setExistingClient={setExistingClient}
            mode={mode}
            setMode={setMode}
          />
        )}
      </div>
    </>
  );
};

export default CreateClient;
