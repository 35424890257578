import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import HomeIcon from "@mui/icons-material/Home";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import UpdateIcon from "@mui/icons-material/Update";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SvgIcon from "@mui/material/SvgIcon";
import axios from "axios";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  clearClient,
  setClientID,
  setProjectID,
} from "../../../../Redux/Actions/actions";
import { fetchAdminClients } from "../../Admin/AdminQueries/useAdminClients";
import { fetchAdminRows } from "../../Admin/AdminQueries/useAdminRows";
import { fetchClientRows } from "../../Client/ClientQueries/useClientRows";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "./styles/sidebar.css";

// Dashboard Sidebar Component to display navigation options
const Sidebar = () => {
  const dispatch = useDispatch();

  const {
    navigate,
    clientId,
    isAdmin,
    user,
    userId,
    openSidebar,
    setOpenSidebar,
    openModal,
    queryClient,
  } = useContext(DashboardContext);

  const list = () => (
    <Box
      role="presentation"
      onKeyDown={() => setOpenSidebar(!openSidebar)}
      className="sidebar"
    >
      <List className="sidebar-list">
        <ListItem
          className="sidebar-options"
          classes={{ root: "sidebar-options" }}
        >
          <Button
            classes={{ root: "sidebar-button-root" }}
            className="sidebar-button"
            id="sidebar-btn"
            disableFocusRipple
            disableRipple
            onMouseOver={async () => {
              if (isAdmin) {
                await queryClient.prefetchQuery({
                  queryKey: ["adminRows"],
                  mutationFn: () => fetchAdminRows(isAdmin),
                  staleTime: 60000,
                });
              } else {
                await queryClient.prefetchQuery({
                  queryKey: ["clientRows", clientId],
                  mutationFn: () => fetchClientRows(clientId, userId),
                  staleTime: 60000,
                });
              }
            }}
            onClick={async () => {
              if (isAdmin) {
                dispatch(setClientID(null));
                dispatch(setProjectID(null));
                dispatch(clearClient());
                navigate("/dashboard/admin", { replace: true });
              } else {
                dispatch(setProjectID(null));
                navigate(`/dashboard/client/${clientId}`), { replace: true };
              }
              setOpenSidebar(!openSidebar);
            }}
          >
            <HomeIcon className="menu-icon" />
            {isAdmin ? "Admin Dashboard" : "Requests Dashboard"}
          </Button>
        </ListItem>
        <Divider classes={{ root: "divider-root" }} />
        {isAdmin ? (
          <>
            <ListItem className="sidebar-options">
              <Button
                classes={{ root: "sidebar-button-root" }}
                className="sidebar-button"
                disableFocusRipple
                disableRipple
                id="sidebar-btn"
                onMouseOver={async () => {
                  await queryClient.prefetchQuery({
                    queryKey: ["existingClients"],
                    queryFn: () => fetchAdminClients(isAdmin),
                    staleTime: 60000,
                  });
                }}
                onClick={async () => {
                  navigate("/dashboard/admin/create-client");
                  setOpenSidebar(!openSidebar);
                }}
              >
                <UpdateIcon className="menu-icon" />
                Create/Edit Client
              </Button>
            </ListItem>
            <Divider classes={{ root: "divider-root" }} />
            <ListItem className="sidebar-options">
              <Button
                classes={{ root: "sidebar-options-root" }}
                className="sidebar-button"
                disableFocusRipple
                disableRipple
                id="sidebar-btn"
                onMouseOver={async () => {
                  await queryClient.prefetchQuery({
                    queryKey: ["existingClients"],
                    queryFn: () => fetchAdminClients(isAdmin),
                    staleTime: 60000,
                  });
                }}
                onClick={async () => {
                  navigate("/dashboard/admin/create-invite-user");
                  setOpenSidebar(!openSidebar);
                }}
              >
                <PersonAddIcon className="menu-icon" />
                Create/Invite User
              </Button>
            </ListItem>
            <Divider classes={{ root: "divider-root" }} />
          </>
        ) : null}
        <ListItem className="sidebar-options">
          <Button
            classes={{ root: "sidebar-button-root" }}
            className="sidebar-button"
            id="sidebar-btn"
            disableFocusRipple
            disableRipple
            onMouseOver={async () => {
              if (isAdmin) {
                await queryClient.prefetchQuery({
                  queryKey: ["all-clients-structure"],
                  queryFn: async () => {
                    const res = await axios.get(`/api/all-clients-structure/`, {
                      withCredentials: true,
                    });
                    return res.data;
                  },
                  staleTime: 60000,
                });
              } else {
                await queryClient.prefetchQuery({
                  queryKey: ["client-structure", clientId],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/client-structure/${clientId}/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                  staleTime: 60000,
                });
              }
            }}
            onClick={async (e) => {
              e.preventDefault();
              if (isAdmin) {
                dispatch(clearClient());
                dispatch(setClientID(null));
                dispatch(setProjectID(null));

                navigate("/dashboard/files", { replace: true });
              } else {
                navigate(`/dashboard/files/${clientId}`);
              }
              setOpenSidebar(!openSidebar);
            }}
          >
            <DescriptionSharpIcon className="menu-icon" />
            Files
          </Button>
        </ListItem>
        <Divider classes={{ root: "divider-root" }} />
        {!isAdmin && (
          <>
            <ListItem className="sidebar-options">
              <Button
                classes={{ root: "sidebar-button-root" }}
                className="sidebar-button"
                id="sidebar-btn"
                disableFocusRipple
                disableRipple
                disabled={openModal}
                onClick={() => {
                  navigate(`/dashboard/user-settings/${user.id}`);
                  setOpenSidebar(!openSidebar);
                }}
              >
                <ManageAccountsIcon className="menu-icon" />
                User Settings
              </Button>
            </ListItem>
            <Divider classes={{ root: "divider-root" }} />
          </>
        )}
      </List>
      <Button
        id="logout-btn"
        variant="contained"
        color="secondary"
        onClick={() => {
          if (isAdmin) {
            dispatch(clearClient());
            dispatch(setClientID(null));
            dispatch(setProjectID(null));
          }
          navigate("/", { replace: true });
          dispatch(setProjectID(null));
          setOpenSidebar(!openSidebar);
        }}
      >
        Log out
      </Button>
      <div className="sidebar-footer">
        {/* Phone & Phone logo */}
        <div id="contact-phone">
          {/* phone icon */}
          <IconButton id="phone-icon-btn" href="tel:1+6022375937">
            <PhoneEnabledIcon id="phone-icon" />
            <p className="contact-text">602-237-5967</p>
          </IconButton>
        </div>
        {/* Email & email logo */}
        <div id="contact-email">
          {/* email icon */}
          <IconButton href="mailto:info@arguscs.com" id="email-icon-btn">
            <EmailIcon id="email-icon" />
            <p className="contact-text">info@arguscs.com</p>
          </IconButton>
        </div>
        {/* Follow us LinkedIn & Facebook */}
        <div id="social-media">
          <p id="social-media-p">Follow Us</p>
          {/* LinkedIn icon */}
          <div id="social-media-icons-container">
            <IconButton
              id="linkedin-icon-btn"
              href="https://www.linkedin.com/company/argus-construction-services-llc"
              disableFocusRipple
              disableRipple
            >
              <SvgIcon
                id="linkedin-icon"
                viewBox="3 3 18 18"
                component={LinkedInIcon}
              />
            </IconButton>
            <IconButton
              id="facebook-icon-btn"
              href="https://www.facebook.com/SWPPPServices"
            >
              <SvgIcon
                id="facebook-icon"
                viewBox="3 3 18 18"
                component={FacebookIcon}
              />
            </IconButton>
          </div>
        </div>
        <div id="mission-statement-container">
          {/* Presenting the Solution */}
          <p id="mission-statment">{'"Presenting the Solution"'}</p>
          <SvgIcon id="main-logo-icon" inheritViewBox viewBox="0 13 10 10">
            <g>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="-1 3 45 40"
                height="130"
                width="130"
              >
                <g>
                  <path
                    fill="#231F20"
                    stroke="#231F20"
                    strokeMiterlimit="10"
                    d="M19.57,5.94L5.77,33.66l17.34,0.02
        c-4.03-3.25-4.67-9.46-1.95-14.49L28.6,5.94H19.57z"
                  />
                  <path
                    fill="none"
                    stroke="#231F20"
                    strokeMiterlimit="10"
                    d="M30.56,34.06"
                  />
                  <path
                    fill="none"
                    stroke="#231F20"
                    strokeMiterlimit="10"
                    d="M22.95,20.66"
                  />
                  <path
                    fill="none"
                    stroke="#231F20"
                    strokeMiterlimit="10"
                    d="M38.01,21"
                  />
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="35.4634"
                    y1="10.3434"
                    x2="39.8634"
                    y2="28.3434"
                  >
                    <stop offset="0%" stopColor="rgb(156, 230, 250)" />
                    <stop offset="25%" stopColor="rgb(111, 187, 211)" />
                    <stop offset="50%" stopColor="rgb(84, 151, 171)" />
                    <stop offset="75%" stopColor="rgb(63, 124, 144)" />
                    <stop offset="100%" stopColor="rgb(35, 97, 121)" />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_1_)"
                    d="M29.9,7.3l-7.44,13.36c-0.58,1.19-0.91,2.13-1.02,2.87c-0.3,1.58-0.18,3.11,0.25,4.62
        c0.54,1.57,1.34,2.76,2.38,3.61c1.79,1.65,3.81,2.3,6,2.3c1.45,0,2.87-0.36,4.25-1.23c0.97-0.59,1.82-1.37,2.53-2.37
        c0.76-1.02,1.26-2,1.51-3.3c0.24-1.25,0.26-2.25,0.12-3.32c-0.15-0.97-0.48-1.91-0.96-2.84L29.9,7.3z"
                  />
                </g>
              </svg>
            </g>
          </SvgIcon>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer open={openSidebar} onClose={() => setOpenSidebar(!openSidebar)}>
        <div>{list("left")}</div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
