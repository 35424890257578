import Button from "@mui/material/Button";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import { handleNext } from "./Helper/formbtnhelper";
import useSubmitForm from "./Slides/Hooks/setSubmitForm";
import useCreateRequest from "./Slides/Hooks/useCreateRequest";
import { FormContext } from "./context/FormProvider";
import "./styles/formbtns.css";

const FormButtons = () => {
  const {
    slide1,
    pageNumber,
    setPageNumber,
    isLoading,
    setIsLoading,
    requestStatus,
    activeUserId,
    setActiveUserId,
    setTimeStamp,
    setOpenCheckModal,
    isReady,
    setIsReady,
    slide10,
  } = useContext(FormContext);
  const { isAdmin, navigate, user, clientId, projectId } =
    useContext(DashboardContext);
  const { enqueueSnackbar } = useSnackbar();
  const submitButtonRef = useRef(null);

  // | CREATE REQUEST MUTATION |
  const createForm = useCreateRequest(
    clientId,
    setPageNumber,
    user,
    setActiveUserId,
    setTimeStamp,
    setIsLoading,
    enqueueSnackbar,
  );

  const submitForm = useSubmitForm(
    clientId,
    projectId,
    isReady,
    setIsReady,
    enqueueSnackbar,
    setIsLoading,
    slide10,
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isReady === true) {
      submitButtonRef.current.click();
      setIsReady(false);
    }
  }, [isReady]);

  // *BACK HANDLER
  const handleBack = async (e) => {
    setIsReady(false);
    setPageNumber(() => pageNumber - 1);
    navigate(`./form/${clientId}/${projectId}/${pageNumber - 1}`, {
      replace: true,
    });
  };

  // *SUBMIT HANDLER
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!projectId) {
      enqueueSnackbar("Error: Project ID not found. Please try again.", {
        variant: "error",
      });
      return;
    } else if (!isReady) {
      setOpenCheckModal(true);
    } else {
      try {
        await submitForm.mutateAsync();
      } catch (error) {
        console.log("SUBMIT ERROR: ", error);
        enqueueSnackbar("Error: There was an error submitting your form.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="form-btns">
        <Button
          id={
            isReady ||
            submitForm.isPending ||
            pageNumber === 0 ||
            (pageNumber === 1 && projectId !== undefined)
              ? "disabled"
              : "back-btn"
          }
          variant="contained"
          onClick={
            pageNumber === 0 || (pageNumber === 1 && projectId)
              ? () => {}
              : handleBack
          }
          disabled={
            isReady ||
            submitForm.isPending === true ||
            pageNumber === 0 ||
            (pageNumber === 1 && projectId)
              ? true
              : false
          }
        >
          Previous
        </Button>
        {pageNumber <= 9 ? (
          <Button
            id={
              (pageNumber === 0 && !clientId) ||
              (pageNumber >= 1 && slide1.projectName === "") ||
              (pageNumber === 1 && createForm.isPending === true)
                ? "disabled"
                : "next-btn"
            }
            variant="contained"
            onClick={(e) =>
              handleNext(
                e,
                clientId,
                projectId,
                slide1,
                pageNumber,
                setPageNumber,
                pageNumber + 1,
                createForm,
                queryClient,
              )
            }
            disabled={
              // If the client ID is not found, disable the button
              (pageNumber === 0 && !clientId) ||
              (pageNumber >= 1 && slide1.projectName === "") ||
              // If the form is being created, disable the button
              (pageNumber === 1 && createForm.isPending === true)
            }
          >
            Next
          </Button>
        ) : (
          <Button
            id={
              (requestStatus === "IP" && user.id === activeUserId) ||
              requestStatus === "I" ||
              requestStatus === "RV"
                ? "submit-btn"
                : "disabled"
            }
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            ref={submitButtonRef}
            disabled={
              (requestStatus === "IP" && user.id !== activeUserId) ||
              requestStatus === "P" ||
              submitForm.isPending === true ||
              isLoading
            }
          >
            Submit
          </Button>
        )}
      </div>
    </>
  );
};

export default FormButtons;
