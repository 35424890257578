import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { updateUser } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to check if a user exists
const checkUser = async (data, clientId) => {
  const res = await axios.post(`/api/client/${clientId}/new-user/`, data, {
    withCredentials: true,
  });

  return res;
};

// Custom hook to check if a user exists
const useGetUser = (dispatch, clientId, setOpenModal, enqueueSnackbar) => {
  const { queryClient } = useContext(DashboardContext);
  const getUser = useMutation({
    mutationFn: (data) => checkUser(data, clientId),
    onSuccess: async (res) => {
      if (res.status === 200) {
        dispatch(
          updateUser({
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
            title: res.data.title,
            phone: res.data.phone,
            admin: false,
            currentClient: res.data.client,
            savedProjects: res.data.favoriteProjects,
          }),
        );
        enqueueSnackbar(`Welcome, ${res.data.name}`, { variant: "success" });

        await queryClient.invalidateQueries({
          queryKey: ["clientRows", clientId],
          refetchType: "all",
        });

        setOpenModal(false);
      }
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("Sign-in attempt unsuccessful", { variant: "error" });
    },
  });

  return getUser;
};

export default useGetUser;
