import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React from "react";
import "./styles/invite-new-user.css";

// Component that displays a confirmation modal when a user is about to be deleted
/**
 * ConfirmDeleteUser Component
 * @description Component that displays a confirmation modal when a user is about to be deleted
 * @param {Object} props - Component props
 * @param {boolean} props.openConfirmDelete - State variable to control the modal's visibility
 * @param {Function} props.setOpenConfirmDelete - Function to set the modal's visibility
 * @param {Object} props.deleteUserMutation - Mutation function to delete the user
 * @param {string} props.userId - The ID of the user to be deleted
 * @returns {JSX.Element} The rendered ConfirmDeleteUser component
 */
const ConfirmDeleteUser = ({
  openConfirmDelete,
  setOpenConfirmDelete,
  deleteUserMutation,
  userId,
}) => {
  return (
    <Modal
      open={openConfirmDelete}
      onClose={() => setOpenConfirmDelete(false)}
      className="confirm-delete-modal"
    >
      <div className="confirm-delete-container">
        <h2 id="confirm-delete-heading">
          Are you sure you want to delete this user?
        </h2>
        <div className="confirm-delete-btns">
          <Button
            id="cancel-delete-btn"
            className="cancel-delete-btn"
            onClick={() => setOpenConfirmDelete(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            id="confirm-delete-btn"
            className="confirm-delete-btn"
            onClick={async () => {
              await deleteUserMutation.mutate(userId);
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteUser;
