import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";

const getPresignedUrl = async (data) => {
  const { clientId, projectId, idProp, fileName, fileType } = data;
  try {
    const res = await axios.post(
      `/api/get-slide9-file-url/${clientId}/${projectId}/${idProp}/`,
      {
        fileName,
        fileType,
      },
      { withCredentials: true },
    );

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useGetPresignedUrl = (setIsUploading) => {
  return useMutation({
    mutationFn: getPresignedUrl,
    onError: (err) => {
      console.error("PRESIGNED URL ERROR", err);
      setIsUploading(false);
    },
  });
};

const uploadToS3 = async (data) => {
  const { presignedPost, file, updateProgress } = data;

  const formData = new FormData();

  Object.keys(presignedPost.fields).forEach((key) => {
    formData.append(key, presignedPost.fields[key]);
  });

  formData.append("file", file);

  const res = await axios.post(presignedPost.url, formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      updateProgress(percentCompleted);
    },
  });

  return res;
};

export const useUploadToS3 = (setIsUploading) => {
  return useMutation({
    mutationFn: uploadToS3,
    onError: (err) => {
      console.error("UPLOAD TO S3 ERROR: ", err);
      setIsUploading(false);
    },
  });
};

const confirmFileUpload = async (data) => {
  const { clientId, projectId, idProp, filePath, fileName } = data;
  try {
    const res = await axios.post(
      `/api/confirm-file-upload/${clientId}/${projectId}/${idProp}/`,
      {
        filePath,
        fileName,
      },
      { withCredentials: true },
    );
    return res;
  } catch (err) {
    console.error("CONFIRM FILE UPLOAD ERROR", err);
  }
};

export const useConfirmFileUpload = (setIsLoading, setIsUploading) => {
  const { queryClient } = useContext(DashboardContext);
  return useMutation({
    mutationFn: confirmFileUpload,
    onError: (err) => {
      console.error("CONFIRM FILE UPLOAD ERROR: ", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["currentProject", data.clientId, data.projectId],
      });
      return data;
    },
    onSettled: () => {
      setIsLoading(false);
      setIsUploading(false);
    },
  });
};

const deleteAllFiles = async (data) => {
  const { projectId, fileName } = data;

  try {
    const res = await axios.delete(
      `/api/delete-all-files/${projectId}/${fileName}/`,
      { withCredentials: true },
    );

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useDeleteFiles = () => {
  return useMutation({
    mutationFn: deleteAllFiles,
    onError: (err) => {
      console.error("DELETE FILES ERROR: ", err);
    },
  });
};

const deleteFile = async (data) => {
  const { projectId, idProp, uploadId } = data;

  try {
    const res = await axios.delete(
      `/api/delete-form-file/${projectId}/${idProp}/${uploadId}/`,
      { withCredentials: true },
    );

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const useDeleteFile = (idProp, setState, enqueueSnackbar) => {
  return useMutation({
    mutationFn: (data) => deleteFile({ ...data, idProp }),
    onError: (err) => {
      console.error("DELETE FILES ERROR: ", err);
      enqueueSnackbar("Error deleting file", {
        variant: "error",
        autoHideDuration: 3000,
      });
    },
    onSuccess: (data) => {
      // Update the state with the returned files
      setState((prevState) => ({
        ...prevState,
        [idProp]: {
          ...prevState[idProp],
          files: data.files, // Update with the new list of files
        },
      }));
      enqueueSnackbar("File deleted successfully", {
        variant: "success",
        autoHideDuration: 2500,
      });
    },
  });
};
