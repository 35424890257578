/**
 * @fileoverview
 * Main application entry point. Initializes MUI Pro license key and renders
 * the top-level `App` component, which includes the Navbar and an `Outlet`
 * for nested routes.
 *
 * @see {@link https://mui.com/pricing/} for MUI Pro licensing details.
 */

import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import "./styles/app.css";

/**
 * MUI License Key Initialization
 *
 * Reads the MUI Pro license key from an environment variable and sets it
 * via MUI's LicenseInfo API.
 *
 * @description
 * The license key is loaded from `process.env.REACT_APP_MUI_LICENSE_KEY`.
 * Ensure it is defined in your .env file.
 */
const licenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(licenseKey);

/**
 * App Component
 *
 * @description
 * The root component of the application. Renders a global Navbar and provides an <Outlet /> for nested React Router routes.
 * @see {@linkcode Navbar}
 * @returns {JSX.Element} The rendered App component.
 */
export default function App() {
  return (
    <div className="app">
      <Navbar />
      <Outlet />
    </div>
  );
}
