import BookmarkIcon from "@mui/icons-material/Bookmark";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FolderIcon from "@mui/icons-material/Folder";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem, GridCheckIcon } from "@mui/x-data-grid-pro";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { fetchCurrentProject } from "../../DashboardComponents/Form/FormQueries/useCurrentProject.jsx";
import { fetchQuestionnaire } from "../../QueryHooks/questionnaire/useQuestionnaire.jsx";
import * as defaultStyles from "../../styles/main-datagrid.module.css";
import { RequestStatusCell } from "../../UniversalDataGridHelper/dataGridComponents.jsx";
import { fetchAdminClients } from "../AdminQueries/useAdminClients.jsx";
import "../styles/admin-data-grid.css";
import { ServicesPopover } from "./adminComponents.jsx";
dayjs.extend(customParseFormat);

// This function generates the columns for the Admin Data Grid
export function getColumns(
  apiRef,
  dispatch,
  navigate,
  setClient,
  setClientID,
  setProjectID,
  queryClient,
  updateQuestionnaire,
  emailUserProps,
  servicesPopoverProps,
  contactsPopoverProps,
  updateArchive,
  checkQuestionnaire,
) {
  return [
    {
      field: "projectName",
      headerName: "Project",
      flex: 1.8,
      minWidth: 145,
      hideable: true,
      pinnable: false,
      resizable: false,
      display: "flex",
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.projectNameHeader,
      cellClassName: defaultStyles.projectNameCells,
      disableColumnMenu: true,
      disableColumnReorder: true,
    },
    {
      field: "requestStatus",
      headerName: "Status",
      pinnable: false,
      sortable: true,
      hideable: true,
      flex: 1,
      minWidth: 110,
      headerClassName: defaultStyles.requestStatusHeader,
      align: "center",
      renderCell: (params) => {
        return <RequestStatusCell params={params} isAdmin={true} />;
      },

      cellClassName: (params) => {
        if (params.value === null) {
          return "";
        }
        let val = params.value;
        return clsx({
          [defaultStyles.requestStatusCells]: true,
          [defaultStyles.inProgress]: val === "IP",
          [defaultStyles.incomplete]: val === "I",
          [defaultStyles.pending]: val === "P",
          [defaultStyles.revise]: val === "RV",
          [defaultStyles.completed]: val === "C",
        });
      },
      disableColumnMenu: true,
      disableColumnReorder: true,
    },
    {
      field: "projectId",
      headerName: "Action",
      display: "flex",
      flex: 1,
      sortable: false,
      hideable: true,
      pinnable: true,
      resizable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.actionHeader,
      disableColumnMenu: true,
      disableColumnReorder: true,
      renderCell: (params) => {
        if (params.value && params.row.requestStatus === "P") {
          return (
            <Button
              variant="contained"
              id={"pendingButtonAdmin"}
              size="small"
              className={"pendingButton"}
              onMouseOver={async () => {
                try {
                  await queryClient.prefetchQuery({
                    queryKey: [
                      "currentProject",
                      params.row.clientId,
                      params.value,
                    ],
                    queryFn: () =>
                      fetchCurrentProject(params.row.clientId, params.value),
                    staleTime: 60000,
                  });
                } catch (error) {
                  console.log("Error prefetching current project: ", error);
                }
              }}
              onClick={async () => {
                let mutationProp = {
                  clientId: params.row.clientId,
                  projectId: params.value,
                };

                dispatch(setClientID(mutationProp.clientId));
                dispatch(setProjectID(mutationProp.projectId));

                let questionnaireExists = await checkQuestionnaire(
                  mutationProp.clientId,
                  mutationProp.projectId,
                );

                if (questionnaireExists === 204) {
                  console.log(
                    "Questionnaire Doesn't Exists: ",
                    questionnaireExists,
                  );
                  await updateQuestionnaire.mutateAsync(mutationProp);
                }

                navigate(
                  `/dashboard/admin/validate-questionnaire/${mutationProp.clientId}/${mutationProp.projectId}`,
                );
              }}
            >
              Review
            </Button>
          );
        } else if (params.value && params.row.requestStatus === "IP") {
          return (
            <Button
              variant="contained"
              id={"inProgressButtonAdmin"}
              size="small"
              className={"inProgressButton"}
              onMouseOver={async () => {
                try {
                  await queryClient.prefetchQuery({
                    queryKey: [
                      "currentProject",
                      params.row.clientId,
                      params.value,
                    ],
                    queryFn: () =>
                      fetchCurrentProject(params.row.clientId, params.value),
                    staleTime: 60000,
                  });
                } catch (error) {
                  console.log("Error prefetching current project: ", error);
                }
              }}
              onClick={async () => {
                dispatch(
                  setClient({
                    id: params.row.clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(params.row.clientId));
                dispatch(setProjectID(params.value));
                let cId = params.row.clientId;
                let pId = params.value;
                let currentProject = queryClient.getQueryData([
                  "currentProject",
                  cId,
                  pId,
                ]);

                if (!currentProject) {
                  await queryClient.prefetchQuery({
                    queryKey: ["currentProject", cId, pId],
                    queryFn: () => fetchCurrentProject(cId, pId),
                  });
                }

                let slideNumber = +params.row.recentSlide;

                navigate(`/dashboard/form/${cId}/${pId}/${slideNumber}`, {
                  replace: true,
                });
              }}
            >
              View
            </Button>
          );
        } else if (params.value && params.row.requestStatus === "I") {
          return (
            <Button
              variant="contained"
              id={"editButtonAdmin"}
              size="small"
              className={"editButton"}
              onMouseOver={async () => {
                try {
                  await queryClient.prefetchQuery({
                    queryKey: [
                      "currentProject",
                      params.row.clientId,
                      params.value,
                    ],
                    queryFn: () =>
                      fetchCurrentProject(params.row.clientId, params.value),
                    staleTime: 60000,
                  });
                } catch (error) {
                  console.log("Error prefetching current project: ", error);
                }
              }}
              onClick={async () => {
                dispatch(
                  setClient({
                    id: params.row.clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(params.row.clientId));
                dispatch(setProjectID(params.value));
                let cId = params.row.clientId;
                let pId = params.value;
                let currentProject = queryClient.getQueryData([
                  "currentProject",
                  cId,
                  pId,
                ]);

                if (!currentProject) {
                  await queryClient.prefetchQuery({
                    queryKey: ["currentProject", cId, pId],
                    queryFn: () => fetchCurrentProject(cId, pId),
                  });
                }

                let slideNumber = +params.row.recentSlide;

                navigate(`/dashboard/form/${cId}/${pId}/${slideNumber}`, {
                  replace: true,
                });
              }}
            >
              Edit
            </Button>
          );
        } else if (params.row.requestStatus === "RV") {
          return (
            <Button
              variant="contained"
              id={"reviseButtonAdmin"}
              size="small"
              className={"reviseButton"}
              onMouseOver={async () => {
                try {
                  await queryClient.prefetchQuery({
                    queryKey: [
                      "currentProject",
                      params.row.clientId,
                      params.value,
                    ],
                    queryFn: () =>
                      fetchCurrentProject(params.row.clientId, params.value),
                    staleTime: 60000,
                  });
                } catch (error) {
                  console.log("Error prefetching current project: ", error);
                }
              }}
              onClick={async () => {
                dispatch(
                  setClient({
                    id: params.row.clientId,
                    name: params.row.clientName,
                    shortName: params.row.clientData.shortName,
                    address: params.row.clientData.address,
                    city: params.row.clientData.city,
                    zipCode: params.row.clientData.zipCode,
                    phone: params.row.clientData.phone,
                  }),
                );
                dispatch(setClientID(params.row.clientId));
                dispatch(setProjectID(params.value));
                let cId = params.row.clientId;
                let pId = params.value;
                let currentProject = queryClient.getQueryData([
                  "currentProject",
                  cId,
                  pId,
                ]);

                if (!currentProject) {
                  await queryClient.prefetchQuery({
                    queryKey: ["currentProject", cId, pId],
                    queryFn: () => fetchCurrentProject(cId, pId),
                  });
                }

                let slideNumber = +params.row.recentSlide;

                navigate(`/dashboard/form/${cId}/${pId}/${slideNumber}`, {
                  replace: true,
                });
              }}
            >
              Revise
            </Button>
          );
        } else if (params.row.requestStatus === "C") {
          return (
            <Button
              variant="contained"
              size="small"
              id={"downloadButtonAdmin"}
              className={"downloadButton"}
              onClick={async () => {
                setProjectID(params.value);
                setClientID(params.row.clientId);
                let questionnaire = await queryClient.fetchQuery({
                  queryKey: [
                    "questionnaire",
                    params.row.clientId,
                    params.value,
                  ],
                  queryFn: () =>
                    fetchQuestionnaire(params.row.clientId, params.value),
                });

                if (questionnaire) {
                  window.open(questionnaire.url, "_blank");
                }
              }}
            >
              Download
            </Button>
          );
        }
      },
    },
    {
      field: "servicesRequest",
      headerName: "Services",
      flex: 0.65,
      hideable: true,
      pinnable: false,
      sortable: false,
      headerClassName: defaultStyles.servicesHeader,
      align: "center",
      type: "string",
      valueGetter: (params) => {
        if (params === undefined) return;
        return params === true ? "Requested" : "Not Requested";
      },
      renderCell: (params) => {
        const { servicesAnchor, currServicesRow, openServicesPopoverHandler } =
          servicesPopoverProps;

        if (!params.value) return;

        return params.value === "Requested" ? (
          <>
            <Tooltip
              title="Click to view requested services"
              id="services-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                aria-label="services-expand"
                key={`${params.id}-services-expand}`}
                className={defaultStyles.servicesButton}
                id={params.id}
                onClick={openServicesPopoverHandler}
              >
                <GridCheckIcon className={defaultStyles.boolIcon} />
              </IconButton>
            </Tooltip>
            {servicesAnchor && params.row.id === currServicesRow ? (
              <ServicesPopover params={params} />
            ) : null}
          </>
        ) : (
          <Tooltip
            title="No services have been selected"
            id="services-tooltip"
            placement="top"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <HorizontalRuleIcon
              className={defaultStyles.boolIcon}
              id={defaultStyles.hrIcon}
            />
          </Tooltip>
        );
      },
      cellClassName: (params) => {
        return clsx({
          [defaultStyles.boolCol]: true,
          [defaultStyles.requested]: params.value === "Requested",
          [defaultStyles.notRequested]: params.value === "Not Requested",
        });
      },
      disableColumnMenu: true,
    },
    // {
    //   field: "contacts",
    //   headerName: "Contacts",
    //   flex: 0.3,
    //   hideable: true,
    //   pinnable: false,
    //   resizable: false,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: defaultStyles.contactsHeader,
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     if (!params.value) return;

    //     const { contactsAnchor, currContactsRow, openContactsPopoverHandler } =
    //       contactsPopoverProps;
    //     return (
    //       <>
    //         <Tooltip
    //           title="Click to view contacts"
    //           id="contacts-tooltip"
    //           placement="top"
    //           classes={{
    //             tooltip: "dashboard-tooltip",
    //             popper: "dashboard-tooltip-popper",
    //             arrow: "dashboard-arrow",
    //           }}
    //           enterDelay={350}
    //         >
    //           <IconButton
    //             aria-label="contacts-expand"
    //             key={`${params.id}-contacts-expand}`}
    //             className={"contactsButton"}
    //             id={params.row.id}
    //             onClick={openContactsPopoverHandler}
    //             classes={{
    //               root: "contactsButtonRoot",
    //             }}
    //           >
    //             <GroupRoundedIcon
    //               id={"contactsIcon"}
    //               className={"contactsIcon"}
    //             />
    //           </IconButton>
    //         </Tooltip>
    //         {contactsAnchor && params.row.id === currContactsRow ? (
    //           <ContactsPopover params={params} />
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      flex: 0.3,
      minWidth: 60,
      maxWidth: 80,
      display: "flex",
      sortable: false,
      hideable: false,
      disableColumnReorder: true,
      cellClassName: (params) => {
        if (params.id.startsWith("auto-")) {
          return "";
        }

        return `${defaultStyles.actionsCellContainer}`;
      },
      getActions: (params) => {
        if (params.id.startsWith("auto-")) return [];
        const projectId = params.row.projectId;
        const projectName = params.row.projectName;
        const requestStatus = params.row.requestStatus;
        const clientId = params.row.clientId;
        const archived = params.row.archived;

        return [
          <GridActionsCellItem
            icon={
              <BookmarkIcon
                className={`${defaultStyles.archiveProjectIcon} ${
                  archived ? defaultStyles["filled"] : defaultStyles["outlined"]
                }`}
                id={defaultStyles["archiveProjectIcon"]}
              />
            }
            label="Archive Project"
            id={defaultStyles["archiveBtn"]}
            className={
              requestStatus === "C"
                ? defaultStyles["archiveBtn"]
                : defaultStyles["archiveBtnDisabled"]
            }
            onClick={() => {
              updateArchive.mutate({
                projectId: projectId,
                archiveValue: !archived,
                projectName: projectName,
              });
            }}
            key={"archive-action"}
          />,
          <GridActionsCellItem
            icon={<FolderIcon />}
            label="View Required Files"
            onMouseOver={async () => {
              try {
                await queryClient.prefetchQuery({
                  queryKey: ["required-project-files", projectId, "admin"],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/required-project-files/${projectId}/admin/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                  staleTime: 120000,
                });
              } catch (error) {
                console.log("Error prefetching uploaded files: ", error);
              }
            }}
            onClick={() => {
              dispatch(setProjectID(projectId));
              dispatch(setClientID());
              navigate(`/dashboard/files/${clientId}/${projectId}/required`);
            }}
            showInMenu
            key={"files-action"}
          />,
          <GridActionsCellItem
            icon={<EmailRoundedIcon />}
            label="Invite User"
            onMouseOver={async () => {
              await queryClient.prefetchQuery({
                queryKey: ["adminClients"],
                queryFn: () => fetchAdminClients(true),
                staleTime: 120000,
              });

              await queryClient.prefetchQuery({
                queryKey: ["existing-users", clientId],
                queryFn: async () => {
                  const res = await axios.get(
                    `/api/client/${clientId}/users/`,
                    {
                      withCredentials: true,
                    },
                  );

                  return res.data;
                },
                staleTime: 120000,
              });
            }}
            onClick={() => {
              dispatch(setClientID(clientId));
              navigate("/dashboard/admin/create-invite-user");
            }}
            showInMenu
            key={"contacts-action"}
          />,
        ];
      },
    },
    {
      field: "savedAt",
      type: "dateTime",
      pinned: false,
      hideable: true,
      width: 0,
      minWidth: 0,
      resizable: false,
      flex: 0,
      sortable: false,
      disableColumnMenu: true,
      disableColumnReorder: true,
    },
  ];
}

/*
{
      field: "fileDetails",
      headerName: "Files",
      flex: 0.75,
      minWidth: 82,
      hideable: true,
      pinnable: false,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.fileDetailsHeader,
      cellClassName: defaultStyles.fileDetailsCells,
      disableColumnMenu: true,
      disableColumnReorder: true,
      valueGetter: (params) => {
        if (!params || params.length === 0) {
          return undefined;
        }
        return params;
      },
      renderCell: (params) => {
        if (!params || !params?.value) return;
        const [client, project] = params.value;
        if (client && project) {
          return (
            <Tooltip
              title={
                params.row.requiredFilesCount > 0
                  ? `${params.row.requiredFilesCount} file(s) have been marked as required. Click to navigate to this projects file section`
                  : "Click to navigate to this projects files section"
              }
              id="files-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                variant="contained"
                size="small"
                id={"fileDetailsButton"}
                className={"fileDetailsButton"}
                onMouseOver={async () => {
                  try {
                    await queryClient.prefetchQuery({
                      queryKey: ["required-project-files", project, "admin"],
                      queryFn: async () => {
                        const res = await axios.get(
                          `/api/required-project-files/${project}/admin/`,
                          {
                            withCredentials: true,
                          },
                        );
                        return res.data;
                      },
                      staleTime: 120000,
                    });
                  } catch (error) {
                    console.log("Error prefetching uploaded files: ", error);
                  }
                }}
                onClick={() => {
                  dispatch(setProjectID(project));
                  dispatch(setClientID(client));
                  navigate(`/dashboard/files/${client}/${project}/required`);
                }}
              >
                <Badge
                  badgeContent={params.row.requiredFilesCount}
                  color="rgb(198, 42, 42)"
                  variant={"dot"}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={defaultStyles.filesBadgeCount}
                  classes={{
                    root: defaultStyles.filesBadgeRoot,
                    badge: defaultStyles.filesBadge,
                    dot: defaultStyles.filesBadgeDot,
                  }}
                >
                  <FolderIcon id={"fileDetailsIcon"} />
                </Badge>
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "archived",
      headerName: "Archive",
      flex: 0.24,
      minWidth: 85,
      type: "boolean",
      hideable: true,
      pinnable: false,
      resizable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.archiveHeader,
      cellClassName: defaultStyles.archiveCells,
      disableColumnMenu: true,
      renderCell: (params) => {
        const projectId = params.row.projectId;
        const projectName = params.row.projectName;
        const requestStatus = params.row.requestStatus;
        const archived = params.value;
        if (archived === undefined) return "";
        return (
          <Tooltip
            title={
              archived
                ? "This request has been archived for all users. Click to unarchive."
                : "Click to archive this request for all users"
            }
            id="archive-tooltip"
            placement="top"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <IconButton
              aria-label="archive-icon-button"
              variant="contained"
              id={"archiveBtn"}
              className={
                requestStatus === "C" ? "archiveBtn" : "archiveBtnDisabled"
              }
              disableRipple
              disabled={
                requestStatus !== "C" || updateArchive.isPending ? true : false
              }
              onClick={() =>
                updateArchive.mutate({
                  projectId,
                  archiveValue: !archived,
                  projectName,
                })
              }
            >
              <BookmarkIcon
                className={`archiveProjectIcon ${
                  archived ? "filled" : "outlined"
                }`}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
        {
      field: "emailId",
      headerName: "Invite",
      flex: 0.8,
      minWidth: 85,
      hideable: true,
      pinnable: false,
      resizable: true,
      sortable: false,
      disableColumnMenu: true,
      disableColumnReorder: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.value) {
          return;
        }
        if (params.row.users && params.row.users.length === 0) {
          return <p>No Users</p>;
        }

        const {
          emailAnchor,
          currEmailRow,
          openEmailPopoverHandler,
          asyncSendEmail,
        } = emailUserProps;

        return (
          <>
            <Tooltip
              title={
                params.row.requestStatus !== "A" &&
                params.row.requestStatus !== "C" &&
                params.row.users.length > 0
                  ? "Click to select user to email with a link to access this project"
                  : params.row.requestStatus === "A" ||
                    (params.row.requestStatus === "C" &&
                      params.row.users.length > 0)
                  ? "This request is either completed or archived"
                  : "No Users"
              }
              id="services-tooltip"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
              placement="top"
              enterDelay={350}
            >
              <IconButton
                aria-label="email-user-expand"
                key={`${params.id}-email-user-expand}`}
                className={`${"emailUserButton"} ${
                  params.row.requestStatus === "C" ||
                  params.row.users.length === 0
                    ? "disabledEmailBtn"
                    : ""
                }`}
                classes={{
                  root: "emailUserButtonRoot",
                }}
                id={params.id}
                onClick={
                  params.row.requestStatus !== "C" &&
                  params.row.users.length > 0
                    ? openEmailPopoverHandler
                    : () => {}
                }
              >
                <EmailRoundedIcon
                  id={"emailUserIcon"}
                  className={"emailUserIcon"}
                />
              </IconButton>
            </Tooltip>
            {emailAnchor && params.row.id === currEmailRow ? (
              <EmailUsersPopover
                params={params}
                asyncSendEmail={asyncSendEmail}
              />
            ) : null}
          </>
        );
      },
    },
*/
