import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider.jsx";
import * as Messages from "../messages/messages.js";
import "../styles/form-container.css";
import "./styles/select-field.css";

/**
 * SelectField Component
 * @description A component that renders a mui select made selecting a state via a drop down.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the input.
 * @param {string} props.label - The label for the input.
 * @param {string} props.val - The value of the input.
 * @param {Object} props.state - The slide state object.
 * @param {Function} props.setState - The slide state setter function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @returns {JSX.Element} The rendered SelectField component.
 */
const SelectField = ({
  idProp,
  label,
  val,
  state,
  setState,
  req,
  debouncedSave,
  setIsLoading,
  disabledInput,
  disabledInputMessage,
  invalidData,
}) => {
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [adjustMargin, setAdjustMargin] = useState(false);
  const { requestStatus, handleInputDisable } = useContext(FormContext);
  const { userId } = useContext(DashboardContext);

  // States for the select field
  const [states, setStates] = useState({
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  });

  // Adjust the margins of the select field based on the helper text visibility of the tooltip
  useEffect(() => {
    if (req && openHelper) {
      setAdjustMargin(true);
    } else {
      setAdjustMargin(false);
    }
  }, [req, openHelper]);

  // Handles the change of the select field
  const handleSelectChange = (e) => {
    // Set the value of the select field
    const { value } = e.target;
    const newState = {
      ...state,
      [idProp]: value,
    };
    // Update slide state
    setState(newState);
    // Save the new state
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <div
      className="input-select-container"
      id={idProp === "dustPermitNumber" ? "input-dust-container" : ""}
      style={
        requestStatus === "RV" &&
        disabledInput === false &&
        invalidData &&
        invalidData["initialValue"] === val &&
        disabledInputMessage === ""
          ? { marginBottom: "15px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            ((invalidData["initialValue"] === val &&
              disabledInputMessage !== "") ||
              val !== "")
          ? { marginBottom: "35px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            val !== invalidData["initialValue"] &&
            val !== ""
          ? { marginBottom: "15px" }
          : adjustMargin && openHelper
          ? { marginBottom: "35px" }
          : { marginBottom: "15px" }
      }
    >
      <label
        id={`${idProp}-label`}
        className={"select-form-label"}
        htmlFor={"select-form"}
      >
        {label}:{" "}
      </label>
      <div className="select-info-container">
        <FormControl
          variant="outlined"
          id="select-form"
          classes={{ root: "form-select-root" }}
          className={`${idProp}-form-control ${
            requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            (val === invalidData["initialValue"] || val === "")
              ? "revise-input"
              : requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== ""
              ? "revised-input"
              : handleInputDisable(idProp, state, disabledInput, userId) ===
                true
              ? "disable-text"
              : req
              ? "required"
              : ""
          }`}
        >
          <InputLabel
            id="select-field-label"
            htmlFor="select-input"
            classes={{ shrink: "select-field-label-shrink" }}
            className={`${idProp}-select-field-label`}
          >
            {`Add ${label}`}
          </InputLabel>
          <Select
            id="select-field"
            labelId="select-field-label"
            className={`${idProp}-input`}
            ref={textRef}
            label={`Add ${label}`}
            value={val}
            onChange={handleSelectChange}
            MenuProps={{
              id: "select-field-menu",
              className: "select-field-menu",
              classes: { paper: "select-field-paper" },
            }}
            inputProps={{
              id: "select-input",
              name: "select-input",
            }}
            disabled={
              handleInputDisable(idProp, state, disabledInput, userId) === true
                ? true
                : false
            }
            autoComplete="off"
            autoCapitalize="words"
            classes={{
              root: "select-field-root",
              input: "select-field-input",
            }}
            endAdornment={
              <Tooltip
                id="clear-btn-tooltip"
                classes={{
                  tooltip: "tooltip-clear-btn",
                  popper: "popper-clear-btn",
                  tooltipPlacementTop: "tooltip-top",
                }}
                title={val === "" ? "" : "Clear Field"}
                placement="top"
              ></Tooltip>
            }
          >
            <MenuItem
              value=""
              id="select-field-placeholder"
              className="select-placeholder"
            >
              Clear Field
            </MenuItem>
            {Object.keys(states).map((state) => (
              <MenuItem
                key={states[state]}
                value={states[state]}
                className="select-menu-item"
              >
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip
          id="tf-tooltip"
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
            tooltipPlacementLeft: "text-field-right",
          }}
          title={Messages[`${idProp}Message`] || ""}
          placement={"top"}
          arrow
          open={open}
          sx={{
            backgroundColor: "rgb(0, 0, 0, 0.8) !important",
          }}
        >
          <InfoCircle
            className="text-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default SelectField;
