import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CalculatorFill, InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import AcreageCalculator from "../Slides/AcreageCalculator.jsx";
import { FormContext } from "../context/FormProvider.jsx";
import { handleKeyDown, updateNumber } from "../main-helper.js";
import * as Messages from "../messages/messages.js";
import "../styles/form-container.css";
import "./styles/text-field.css";

/**
 * TextField Component
 * @description A component that renders a mui outlined input made for name/company inputs.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the text field.
 * @param {string} props.label - The label for the text field.
 * @param {string} props.val - The value of the text field.
 * @param {Object} props.state - The slide state object.
 * @param {Function} props.setState - The slide state setter function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {Array} props.autoData - The array of names or companies.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @returns {JSX.Element} The rendered TextField component.
 */
const TextField = ({
  idProp,
  label,
  val,
  state,
  setState,
  req,
  debouncedSave,
  setIsLoading,
  autoData,
  type,
  disabledInput,
  disabledInputMessage,
  invalidData,
}) => {
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [openCalculator, setOpenCalculator] = useState({
    totAcr: false,
    distAcr: false,
  });
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [adjustMargin, setAdjustMargin] = useState(false);
  const { requestStatus, handleInputFocus, handleInputDisable } =
    useContext(FormContext);
  const { capitalizeNameAfterSpace, userId } = useContext(DashboardContext);

  useEffect(() => {
    if (req && openHelper) {
      setAdjustMargin(true);
    } else {
      setAdjustMargin(false);
    }
  }, [req, openHelper]);

  const handleUpdateDustPermitNumber = (e) => {
    const updatedValue = e.target.value;
    let newState = { ...state, dustPermitNumber: updatedValue };
    setState(newState);
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      const uploadState = Object.keys(newState).map((key) => {
        const payload = {
          name: key,
          status: newState[key].status,
        };

        if (key === "dustPermitNumber") {
          payload["dustPermitNumber"] = updatedValue;
        }

        return payload;
      });

      debouncedSave.mutate(uploadState);
    }
  };

  // Function to handle text input change
  const handleTextChange = (e) => {
    let newState;

    if (idProp === "jobNumber") {
      newState = { ...state, [idProp]: e.target.value };
      setState(newState);
      if (debouncedSave && setIsLoading) {
        setIsLoading(() => true);
        debouncedSave.mutate(newState);
      }
      return;
    }

    if (
      idProp === "totAcr" ||
      idProp === "distAcr" ||
      idProp === "portableTankCapacity"
    ) {
      let value = e.target.value;
      updateNumber(idProp, value, state, setState, debouncedSave, setIsLoading);

      return;
    }

    let value;
    if (type === "name") {
      value = capitalizeNameAfterSpace(e.target.value);
    } else {
      value = e.target.value;
    }
    let formattedValue = value.replace(/[^a-zA-Z0-9- ,'.& #]/g, "");
    newState = { ...state, [idProp]: formattedValue };
    setState(newState);

    if (
      showPlaceholder &&
      formattedValue !== "" &&
      !placeholder.toLowerCase().startsWith(formattedValue.toLowerCase())
    ) {
      if (autoData && autoData.length > 0) {
        let match = autoData.find((val) => {
          if (!val) return false;
          return val.toLowerCase().startsWith(formattedValue.toLowerCase());
        });

        if (match) {
          match = formattedValue + match.slice(formattedValue.length);
        }

        if (match && formattedValue !== "") {
          setPlaceholder(match);
          setShowPlaceholder(true);
        } else {
          setPlaceholder("");
          setShowPlaceholder(false);
        }
      }
    } else if (
      value !== "" &&
      placeholder.toLowerCase().startsWith(value.toLowerCase())
    ) {
      let existingMatch = value + placeholder.slice(value.length);
      setPlaceholder(existingMatch);
      setShowPlaceholder(true);
    } else if (value === "") {
      setPlaceholder("");
      setShowPlaceholder(false);
    }

    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <div
      className="input-text-container"
      id={idProp === "dustPermitNumber" ? "input-dust-container" : ""}
      style={
        requestStatus === "RV" &&
        disabledInput === false &&
        invalidData &&
        invalidData["initialValue"] === val &&
        disabledInputMessage === ""
          ? { marginBottom: "15px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            ((invalidData["initialValue"] === val &&
              disabledInputMessage !== "") ||
              val !== "")
          ? { marginBottom: "35px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            val !== invalidData["initialValue"] &&
            val !== ""
          ? { marginBottom: "15px" }
          : adjustMargin && openHelper
          ? { marginBottom: "35px" }
          : { marginBottom: "15px" }
      }
    >
      <label
        id={`${idProp}-label`}
        className={
          idProp !== "dustPermitNumber" ? "text-form-label" : "file-form-label"
        }
        htmlFor={"text-form"}
      >
        {label}:{" "}
      </label>
      <div
        className="text-info-container"
        id={
          idProp === "dustPermitNumber"
            ? "dust-info-container"
            : idProp === "totAcr" || idProp === "distAcr"
            ? "acreage-info-container"
            : ""
        }
      >
        {(idProp === "totAcr" || idProp === "distAcr") &&
          !handleInputDisable(idProp, state, disabledInput, userId) && (
            <Tooltip
              id="calculator-tooltip"
              classes={{
                tooltip: "calculator-tooltip",
                popper: "calculator-popper",
                arrow: "calculator-arrow",
                tooltipPlacementTop: "calculator-top",
              }}
              title={`Open calculator to convert square feet (ft²) to acres`}
              placement="top"
              arrow
            >
              <IconButton
                id="calculator-icon-btn"
                onClick={() => {
                  setOpenCalculator({
                    ...openCalculator,
                    [idProp]: !openCalculator[idProp],
                  });
                }}
                aria-label="open acreage calculator"
              >
                <CalculatorFill className="calculator-icon" />
              </IconButton>
            </Tooltip>
          )}
        <FormControl
          variant="outlined"
          id="text-form"
          classes={{ root: "form-text-root" }}
          className={`${idProp}-form-control ${
            idProp === "portableTankCapacity" && +val >= 1320
              ? "spccAlert"
              : requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                (val === invalidData["initialValue"] || val === "")
              ? "revise-input"
              : requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== ""
              ? "revised-input"
              : handleInputDisable(idProp, state, disabledInput, userId) ===
                true
              ? "disable-text"
              : req
              ? "required"
              : ""
          }`}
        >
          <InputLabel
            id="text-field-label"
            htmlFor="text-input"
            classes={{ shrink: "text-field-label-shrink" }}
            className={`${idProp}-text-field-label`}
          >
            {`Add ${label}`}
          </InputLabel>
          <OutlinedInput
            type="text"
            id="text-field"
            className={`${idProp}-input`}
            ref={textRef}
            label={`Add ${label}`}
            value={val}
            onChange={
              idProp !== "dustPermitNumber"
                ? handleTextChange
                : handleUpdateDustPermitNumber
            }
            onFocus={() => handleInputFocus(textRef, setOpenHelper)}
            onSelect={() => {
              if (state[`${idProp}`] === "") {
                setOpen(true);
              } else {
                setOpen(false);
              }
              setOpenHelper(true);
              setShowPlaceholder(true);
            }}
            onBlur={() => {
              setOpen(false);
              setShowPlaceholder(false);
              setPlaceholder("");
              setOpenHelper(false);
              if (idProp === "totAcr") {
                setOpenCalculator({
                  ...openCalculator,
                  totAcr: false,
                });
              } else if (idProp === "distAcr") {
                setOpenCalculator({
                  ...openCalculator,
                  distAcr: false,
                });
              }
            }}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                idProp,
                state,
                setState,
                autoData,
                placeholder,
                debouncedSave,
                setIsLoading,
                setPlaceholder,
                setShowPlaceholder,
                setOpen,
                setOpenHelper,
              )
            }
            disabled={
              handleInputDisable(idProp, state, disabledInput, userId) === true
                ? true
                : false
            }
            autoComplete="off"
            autoCapitalize="words"
            classes={{
              root: "text-field-root",
              notchedOutline: "text-notchedOutline",
              input: "text-field-input",
            }}
            endAdornment={
              <Tooltip
                id="clear-btn-tooltip"
                classes={{
                  tooltip: "tooltip-clear-btn",
                  popper: "popper-clear-btn",
                  tooltipPlacementTop: "tooltip-top",
                }}
                title={val === "" ? "" : "Clear Field"}
                placement="top"
              >
                <InputAdornment
                  className="text-field-clear-adornment"
                  id="text-adornment"
                  position="end"
                  style={
                    (idProp === "submitter" && state["submitter"] !== "") ||
                    val === "" ||
                    handleInputDisable(idProp, state, disabledInput, userId) ===
                      true
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                >
                  <IconButton
                    style={
                      (idProp === "submitter" && state["submitter"] !== "") ||
                      val === ""
                        ? { pointerEvents: "none", cursor: "default" }
                        : { pointerEvents: "auto", cursor: "pointer" }
                    }
                    aria-label="clear input field"
                    onClick={() => {
                      if (debouncedSave) {
                        let newState = { ...state, [idProp]: "" };
                        setState(newState);
                        setIsLoading(true);
                        setPlaceholder("");
                        setShowPlaceholder(false);

                        if (idProp !== "dustPermitNumber") {
                          debouncedSave.mutate(newState);
                        } else {
                          handleUpdateDustPermitNumber({
                            target: { value: "" },
                          });
                        }
                      } else {
                        let newState = { ...state, [idProp]: "" };
                        setState(newState);
                        setPlaceholder("");
                        setShowPlaceholder(false);
                      }
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    id="clear-icon-button"
                    disabled={
                      (idProp === "submitter" && state["submitter"] !== "") ||
                      val === ""
                        ? true
                        : false
                    }
                  >
                    <CloseIcon className="close-icon" />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            }
          />
          {openHelper === true && (
            <FormHelperText
              className={`helper-text ${
                requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== ""
                  ? "revised-input-message"
                  : requestStatus === "RV" &&
                    disabledInput === false &&
                    invalidData &&
                    (val === invalidData["initialValue"] || val === "")
                  ? "invalid-input-message"
                  : requestStatus === "RV" &&
                    disabledInput === false &&
                    !disabledInputMessage &&
                    invalidData &&
                    val === ""
                  ? "revise-input-message"
                  : ""
              }`}
              classes={{ root: "text-field-helper" }}
            >
              {(requestStatus === "IP" || requestStatus === "I") &&
                placeholder !== "" && (
                  <span className="fill-text-info">
                    Press <strong id="apple-tab">tab</strong> or the right arrow
                    key to autofill field with suggested value
                  </span>
                )}
              {requestStatus === "RV" &&
                disabledInput === false &&
                disabledInputMessage &&
                invalidData &&
                val === invalidData["initialValue"] &&
                `Note: ${disabledInputMessage}`}
              {requestStatus === "RV" &&
                disabledInput === false &&
                !disabledInputMessage &&
                invalidData &&
                val === "" &&
                "This field was marked as invalid. Please provide an updated value."}
              {requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== "" &&
                `**Revised: Value changed from ${invalidData["initialValue"]} to ${val}**`}
            </FormHelperText>
          )}
          {idProp === "portableTankCapacity" &&
            +state["portableTankCapacity"] >= 1320 && (
              <FormHelperText
                classes={{ root: "text-field-helper" }}
                className="helper-text spcc-alert-text"
              >
                {
                  "**Alert: A SPCC Plan may be required for this project (capacity >= 1320 gallons)**"
                }
              </FormHelperText>
            )}
        </FormControl>
        <input
          key={`${idProp}-placeholder`}
          className={`${
            req ? "predictive-placeholder shift" : "predictive-placeholder"
          }`}
          type="text"
          autoComplete="off"
          id="predictive-placeholder"
          value={placeholder}
          disabled={true}
        />
        <Tooltip
          id="tf-tooltip"
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
            tooltipPlacementLeft: "text-field-right",
          }}
          title={Messages[`${idProp}Message`]}
          placement={"top"}
          arrow
          open={open}
          sx={{
            backgroundColor: "rgb(0, 0, 0, 0.8) !important",
          }}
        >
          <InfoCircle
            className="text-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
        {idProp === "totAcr" && openCalculator["totAcr"] && (
          <AcreageCalculator
            idProp={"totAcr"}
            state={state}
            setState={setState}
          />
        )}
        {idProp === "distAcr" && openCalculator["distAcr"] && (
          <AcreageCalculator
            idProp={"distAcr"}
            state={state}
            setState={setState}
          />
        )}
      </div>
    </div>
  );
};
export default TextField;
