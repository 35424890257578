import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

// Function to delete an invalid input
export const deleteInvalidInputs = async (clientId, projectId, name) => {
  const response = await axios.delete(
    `/api/invalid-inputs/${clientId}/${projectId}/${name}/`,
    {
      withCredentials: true,
    },
  );

  return response.data;
};

// Custom hook to delete an invalid input
export default function useDeleteInvalidInput(
  clientId,
  projectId,
  enqueueSnackbar,
) {
  const queryClient = useQueryClient();
  const removeInvalidInput = useMutation({
    mutationFn: (name) => deleteInvalidInputs(clientId, projectId, name),
    onSettled: () => {
      queryClient.invalidateQueries(["invalid-inputs", clientId, projectId]);
    },
    onError: (error) => {
      console.log("DELETE INVALID INPUT ERROR: ", error);
      enqueueSnackbar("Error validating a field. Please try again.", {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar("Successfully validated field", {
        variant: "success",
      });
    },
  });

  return removeInvalidInput;
}
