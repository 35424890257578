import Container from "@mui/material/Container";
import React, { useContext, useEffect } from "react";
import { DashboardContext } from "../DashboardContext/DashboardContextProvider";
import ClientRequests from "./ClientRequests";
import "./styles/client-portal.css";

/**
 * ClientPortal Component
 *
 * @description Acts as the outer container for the ClientRequests component.
 * @see {@linkcode ClientRequests}
 * @returns {JSX.Element} The rendered ClientPortal component.
 */
const ClientPortal = () => {
  const { client, setHeading } = useContext(DashboardContext);
  const clientName = client.name;

  // Set the heading based on the client name
  useEffect(() => {
    if (clientName) {
      setHeading(`${clientName}'s Dashboard`);
    } else {
      setHeading("Home Dashboard");
    }
  }, [client]);

  return (
    <Container
      id="client-portal-container"
      sx={{
        width: "100%",
      }}
    >
      <ClientRequests />
    </Container>
  );
};

export default ClientPortal;
