import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setClientID, setProjectID } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "./styles/project-file-structure.css";

// Component to display a specific client's folder
const ClientFolder = () => {
  const { clientId, isAdmin, navigate, setHeading, queryClient } =
    useContext(DashboardContext);

  const dispatch = useDispatch();

  const [projectList, setProjectList] = useState([]);

  // Clients projects for file structure
  const { data: clientData, isFetched } = useQuery({
    queryKey: ["client-structure", clientId],
    queryFn: async () => {
      const res = await axios.get(`/api/client-structure/${clientId}/`, {
        withCredentials: true,
      });
      return res.data;
    },
    enabled: !!clientId,
  });

  useEffect(() => {
    if (isFetched && clientData) {
      setHeading(`${clientData.clientName} Folder`);
      setProjectList(
        [...clientData.projects].sort((a, b) =>
          a.projectName.localeCompare(b.projectName),
        ),
      );
    } else if (!isFetched || !clientData) {
      setHeading("Client Folder");
    }
  }, [isFetched]);

  return (
    <div className="project-files-container">
      {isAdmin && (
        <div className="breadcrumbs-container">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            classes={{
              root: "breadcrumbs-root",
              separator: "breadcrumbs-separator",
            }}
          >
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              href="/dashboard/files"
              onMouseOver={async (e) => {
                await queryClient.prefetchQuery({
                  queryKey: ["all-clients-structure"],
                  queryFn: async () => {
                    const res = await axios.get(`/api/all-clients-structure/`, {
                      withCredentials: true,
                    });
                    return res.data;
                  },
                  staleTime: 120000,
                });
              }}
              onClick={() => {
                dispatch(setClientID(null));
                navigate("/dashboard/files");
              }}
              startIcon={<HomeIcon id="root-folder-icon" />}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Clients Folder
              </Typography>
            </Button>
            <Typography
              variant="body1"
              className="breadcrumbs-value current-text"
            >
              Client Folder
            </Typography>
          </Breadcrumbs>
        </div>
      )}
      <div className="folders-container" id="admin-clients-folder-container">
        {isFetched &&
          projectList.map((project) => (
            <div
              className="folder-container"
              key={project.id}
              onMouseOver={async (e) => {
                const userType = isAdmin ? "admin" : "client";

                await queryClient.prefetchQuery({
                  queryKey: ["all-project-structure", project.id, userType],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/all-project-structure/${project.id}/${userType}/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                  staleTime: 120000,
                });
              }}
              onClick={async (e) => {
                e.preventDefault();
                dispatch(setProjectID(project.id));

                navigate(`/dashboard/files/${clientId}/${project.id}`);
              }}
            >
              <SvgIcon
                component={FolderRoundedIcon}
                className="folder default-folder"
                viewBox="3.6 3.6 16.8 16.8"
              />
              <p className="folder-name">{project.projectName}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClientFolder;
