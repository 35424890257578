import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import HomeIcon from "@mui/icons-material/Home";
import Badge from "@mui/material/Badge";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "./styles/project-file-structure.css";

// Component to display a specific project's folder
const ProjectFolder = () => {
  const {
    client,
    clientId,
    projectId,
    isAdmin,
    navigate,
    setHeading,
    queryClient,
  } = useContext(DashboardContext);

  const [userType, setUserType] = useState(isAdmin ? "admin" : "client");
  // Query to get all files
  const {
    data: files,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: ["all-project-structure", projectId, userType],
    queryFn: async () => {
      const res = await axios.get(
        `/api/all-project-structure/${projectId}/${userType}/`,
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    enabled: !!projectId,
  });

  useEffect(
    () => setHeading(files && isFetched ? files.projectName : "Project Files"),
    [],
  );

  // Count the number of required files for breadcrumb
  // useEffect(() => {
  //   if (isFetched) {
  //     files?.files.forEach((file) => {
  //       file.required && setRequiredFilesCount((prev) => prev + 1);
  //     });
  //   }
  // }, [isFetched]);

  return (
    <div className="project-files-container">
      <div className="folders-container">
        <div className="breadcrumbs-container">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            classes={{
              root: "breadcrumbs-root",
              separator: "breadcrumbs-separator",
            }}
          >
            {isAdmin && (
              <Button
                className="breadcrumbs-btn root-link-btn"
                variant="text"
                onClick={() => navigate("/dashboard/files")}
                startIcon={<HomeIcon id="root-folder-icon" />}
              >
                <Typography
                  variant="body1"
                  className="breadcrumbs-value root-text"
                >
                  Clients Folder
                </Typography>
              </Button>
            )}
            {!isAdmin && (
              <Button
                className="breadcrumbs-btn root-link-btn"
                variant="text"
                onMouseOver={async (e) => {
                  e.preventDefault();

                  await queryClient.prefetchQuery({
                    queryKey: ["client-structure", clientId],
                    queryFn: async () => {
                      const res = await axios.get(
                        `/api/client-structure/${clientId}/`,
                        {
                          withCredentials: true,
                        },
                      );
                      return res.data;
                    },
                    staleTime: 120000,
                  });
                }}
                onClick={() => navigate(`/dashboard/files/${clientId}`)}
                startIcon={<HomeIcon id="root-folder-icon" />}
              >
                <Typography
                  variant="body1"
                  className="breadcrumbs-value root-text"
                >
                  {`${client.shortName} Folder`}
                </Typography>
              </Button>
            )}
            {isAdmin && (
              <Button
                className="breadcrumbs-btn root-link-btn"
                variant="text"
                onMouseOver={async (e) => {
                  e.preventDefault();

                  await queryClient.prefetchQuery({
                    queryKey: ["client-structure", clientId],
                    queryFn: async () => {
                      const res = await axios.get(
                        `/api/client-structure/${clientId}/`,
                        {
                          withCredentials: true,
                        },
                      );
                      return res.data;
                    },
                    staleTime: 120000,
                  });
                }}
                onClick={() => navigate(`/dashboard/files/${clientId}`)}
              >
                <Typography
                  variant="body1"
                  className="breadcrumbs-value root-text"
                >
                  {`Client Folder`}
                </Typography>
              </Button>
            )}
            <Typography
              variant="body1"
              className="breadcrumbs-value current-text"
            >
              Project Folder
            </Typography>
          </Breadcrumbs>
        </div>
        <div
          className={`folder-container ${
            files?.uploadedFiles === 0 ? "disabled-folder" : ""
          }`}
          onMouseOver={async (e) => {
            await queryClient.prefetchQuery({
              queryKey: ["uploaded-project-files", projectId, userType],
              queryFn: async () => {
                const res = await axios.get(
                  `/api/uploaded-project-files/${projectId}/${userType}/`,
                  {
                    withCredentials: true,
                  },
                );
                return res.data;
              },
              staleTime: 120000,
            });
          }}
          onClick={(e) => {
            e.preventDefault();
            if (files?.uploadedFiles === 0) return;

            navigate(`/dashboard/files/${clientId}/${projectId}/uploaded`);
          }}
        >
          <Badge
            id="uploaded-badge"
            badgeContent={files?.uploadedFiles}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            showZero
            classes={{
              badge: "folder-badge",
            }}
          >
            <SvgIcon
              className="folder uploaded-folder"
              component={FolderRoundedIcon}
              viewBox="3.6 3.6 16.8 16.8"
            />
          </Badge>
          <p className="folder-name">Uploaded Files</p>
        </div>
        <div
          className={`folder-container ${
            files?.requiredFiles === 0 ? "disabled-folder" : ""
          }`}
          id="missing-folder"
          onMouseOver={async (e) => {
            await queryClient.prefetchQuery({
              queryKey: ["required-project-files", projectId, userType],
              queryFn: async () => {
                const res = await axios.get(
                  `/api/required-project-files/${projectId}/${userType}/`,
                  {
                    withCredentials: true,
                  },
                );
                return res.data;
              },
              staleTime: 120000,
            });
          }}
          onClick={async (e) => {
            e.preventDefault();
            if (files?.requiredFiles === 0) return;

            navigate(`/dashboard/files/${clientId}/${projectId}/required`);
          }}
        >
          <Badge
            badgeContent={files?.requiredFiles}
            showZero
            classes={{
              badge: "folder-badge",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <SvgIcon
              className="folder missing-folder"
              component={FolderRoundedIcon}
              viewBox="3.6 3.6 16.8 16.8"
            />
          </Badge>
          <p className="folder-name">
            {!isAdmin ? "Required Files" : "Form Files"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectFolder;
