// Initial State
// Add saved projects to the user state
const initialState = {
  id: null,
  companyCode: "", // encoded version of their companies login code
  name: "", // name of the user
  email: "", // email of the user
  phone: "", // phone number of the user
  title: "", // title of the user (optional)
  admin: false, // if the user is an admin
  currentClient: null, // client id of the users company or for admin user's the current client being viewed/edited
  currentProject: null, // project id that is currently being viewed/edited
  archivedProjects: [], // projects that admin users have marked as archived
  hideArchived: false, // when enabled, the datagrid will not show archived projects for the client users
  savedProjects: [],
};

// Current User Information Reducer
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...action.payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_ARCHIVED_PROJECT":
      return {
        ...state,
        archivedProjects: [...state.archivedProjects, action.payload],
      };
    case "REMOVE_ARCHIVED_PROJECT":
      return {
        ...state,
        archivedProjects: state.archivedProjects.filter(
          (project) => project.id !== action.payload,
        ),
      };

    case "ADD_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "CLEAR_USER":
      return {
        ...state,
        id: null,
        name: "",
        email: "",
        phone: "",
        title: "",
        admin: false,
        currentClient: null,
        currentProject: null,
        hideArchived: false,
        companyCode: null,
      };
    case "SET_ADMIN":
      return {
        ...state,
        admin: action.payload,
      };
    case "SET_ACTIVE_CLIENT":
      return {
        ...state,
        currentClient: action.payload,
      };
    case "SET_ACTIVE_PROJECT":
      return {
        ...state,
        currentProject: action.payload,
      };
    case "SET_ARCHIVED":
      return {
        ...state,
        hideArchived: action.payload,
      };
    case "SET_COMPANY_CODE":
      return {
        ...state,
        companyCode: action.payload,
      };
    case "CLEAR_COMPANY_CODE":
      return {
        ...state,
        companyCode: "",
      };
    default:
      return state;
  }
};
