// Key Map of letters and numbers to a 3 character string
const parseKey = {
  0: [".x", "rq", "^d"],
  1: ["@a", "uo", "??"],
  2: ["!p", "vp", "##"],
  3: ["$w", "wq", "%%"],
  4: ["%v", "xr", "&&"],
  5: ["^l", "ys", "**"],
  6: ["&c", "zt", "(("],
  7: ["*k", "au", "))"],
  8: ["(m", "bv", "_/"],
  9: [")n", "cw", "++"],
  a: ["!2", "mn", "]x"],
  A: ["!2", "MN", "]X"],
  b: ["@3", "op", "[c"],
  B: ["@3", "OP", "[C"],
  c: ["#4", "pq", "{v"],
  C: ["#4", "PQ", "{V"],
  d: ["$5", "qr", "}b"],
  D: ["$5", "QR", "}B"],
  e: ["%6", "rs", "|n"],
  E: ["%6", "RS", "|N"],
  f: ["^7", "st", ":m"],
  F: ["^7", "ST", ":M"],
  g: ["&8", "tu", "<,"],
  G: ["&8", "TU", "<,"],
  h: ["*9", "uv", ">."],
  H: ["*9", "UV", ">."],
  i: ["(0", "vw", "?/"],
  I: ["(0", "VW", "?/"],
  j: [")1", "wx", "a~"],
  J: [")1", "WX", "A~"],
  k: ["!2", "xy", "b_"],
  K: ["!2", "XY", "B_"],
  l: ["@3", "yz", "c="],
  L: ["@3", "YZ", "C="],
  m: ["#4", "za", "d+"],
  M: ["#4", "ZA", "D+"],
  n: ["$5", "ab", "e+"],
  N: ["$5", "AB", "E+"],
  o: ["%6", "bc", "f~"],
  O: ["%6", "BC", "F~"],
  p: ["^7", "cd", "g!"],
  P: ["^7", "CD", "G!"],
  q: ["&8", "de", "h@"],
  Q: ["&8", "DE", "H@"],
  r: ["*9", "ef", "i#"],
  R: ["*9", "EF", "I#"],
  s: ["(0", "fg", "j$"],
  S: ["(0", "FG", "J$"],
  t: [")1", "gh", "k%"],
  T: [")1", "GH", "K%"],
  u: ["!2", "hi", "l^"],
  U: ["!2", "HI", "L^"],
  v: ["@5", "ij", "m&"],
  V: ["@5", "IJ", "M&"],
  w: ["#4", "jk", "n*"],
  W: ["#4", "JK", "N*"],
  x: ["$5", "kl", "o("],
  X: ["$5", "KL", "O("],
  y: ["%6", "lm", "p)"],
  Y: ["%6", "LM", "P)"],
  z: ["^7", "mn", "q_"],
  Z: ["^7", "MN", "Q_"],
};

// Function to encode a company code
/**
 *
 * @param {string} code
 * @returns {string} parsedCode
 *
 * @deprecated This function is deprecated and will be removed in a future release.
 */
export function encodeCompanyCode(code) {
  let parsedCode = "";

  // Split the code into an array of characters
  const codeArray = code.split("");
  // Loop through the array and add the joined value of parseKey[char] to parsedCode, and separate each value with a "-"
  codeArray.forEach((char) => {
    parsedCode += parseKey[char].join("") + "-";
  });

  // Remove the last "-" from parsedCode
  parsedCode = parsedCode.slice(0, -1);
  // Return the parsedCode
  return parsedCode;
}

/**
 * Function to decode an encoded company code
 * @param {string} encodedCode
 * @returns {string} decodedCode - The decoded company code
 */
export function decodeCompanyCode(encodedCode) {
  let decodedCode = "";
  // Split the encodedCode into an array of characters
  const codeArray = encodedCode.split("-");
  // Loop through the array and add the joined value of parseKey[char] to decodedCode
  for (const codedChar of codeArray) {
    for (const key in parseKey) {
      let currVal = parseKey[key].join("");
      if (currVal === codedChar) {
        decodedCode += key;
      }
    }
  }

  // Return the decodedCode
  return decodedCode;
}
