import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import {
  useConfirmFileUpload,
  useGetPresignedUrl,
  useUploadToS3,
} from "../FileQueries/uploadFileQueries";
import "./styles/upload-files-modal.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";

const UploadFilesModal = ({
  open,
  setOpen,
  projectId,
  selectedFile,
  setSelectedFile,
  setOpenSettings,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { clientId } = useContext(DashboardContext);
  const getPresignedUrl = useGetPresignedUrl();
  const uploadToS3 = useUploadToS3();
  const confirmFileUpload = useConfirmFileUpload();

  const handleUploadFile = async (file) => {
    try {
      const fileName = file.name;
      const fileType = file.type;
      const category = selectedFile.name;

      const presignedUrl = await getPresignedUrl.mutateAsync({
        clientId,
        projectId,
        category,
        fileName,
        fileType,
      });

      const { presignedPost, filePath } = presignedUrl.data;

      await uploadToS3.mutateAsync({
        presignedPost,
        file,
      });

      await confirmFileUpload.mutateAsync({
        clientId,
        projectId,
        category,
        filePath,
        fileName,
      });

      enqueueSnackbar(`${fileName} uploaded successfully`, {
        variant: "success",
      });
    } catch (error) {
      console.log("UPLOAD FILE ERROR: ", error);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = Array.from(e.dataTransfer.files);
    if (uploadedFiles.length === 0) return;

    for (const file of uploadedFiles) {
      enqueueSnackbar(`Uploading ${file.name}`, {
        variant: "info",
        autoHideDuration: 1500,
      });
      await handleUploadFile(file);
    }

    setSelectedFile({
      id: "",
      name: "",
      url: "",
    });
    setOpen(false);
    setOpenSettings(false);
  };

  const handleFileSelect = async (e) => {
    const uploadedFiles = Array.from(e.target.files);

    if (uploadedFiles.length === 0) return;

    for (const file of uploadedFiles) {
      enqueueSnackbar(`Uploading ${file.name}`, {
        variant: "info",
        autoHideDuration: 1500,
      });
      await handleUploadFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrageEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setOpenSettings(false);
        setSelectedFile({
          id: "",
          name: "",
          url: "",
        });
      }}
      className="upload-files-modal"
    >
      <div className="upload-missing-files">
        <Box
          className="drag-files-box"
          id="drag-files-box"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDrageEnter}
          onDragLeave={handleDragLeave}
          onClick={() => document.getElementById("hidden-upload-input").click()}
        >
          <UploadIcon
            className="upload-missing-icon"
            id="upload-missing-icon"
          />
          <span className="drag-drop-text" id="drag-drop-text">
            <u>Click to select</u> or Drag & Drop files here
          </span>
          <input
            id="hidden-upload-input"
            multiple
            type="file"
            hidden
            onChange={handleFileSelect}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
