import { useMutation } from "@tanstack/react-query";
import axios from "axios";

// Function to email user
export const emailUserToProject = async (
  clientId,
  projectId,
  user,
  location,
) => {
  try {
    const res = await axios.post(
      `/api/invite-user/${clientId}/${projectId}/`,
      {
        userId: user.id,
        userEmail: user.email,
        location: location,
      },
      {
        withCredentials: true,
      },
    );

    return res.data;
  } catch (err) {
    console.log("SEND USER EMAIL ERR: ", err);
  }
};

// Custom hook to email user
export default function useInviteUserToProject(enqueueSnackbar) {
  return useMutation({
    mutationFn: (data) =>
      emailUserToProject(
        data.clientId,
        data.projectId,
        data.user,
        data.location,
      ),
    onError: (err) => {
      enqueueSnackbar("There was an error inviting this user", {
        variant: "error",
      });
    },
    onSuccess: (data, variables) => {
      if (variables.user) {
        enqueueSnackbar(`Successfully emailed ${variables.user.name}`, {
          variant: "success",
        });
      } else if (variables.submittingUser) {
        enqueueSnackbar(
          `Successfully emailed ${variables.submittingUser.name}`,
          {
            variant: "success",
          },
        );
      }
    },
  });
}
