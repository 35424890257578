// import "pdfjs-dist/web/pdf_viewer.css";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { checkValidity } from "./helper.js";
import "./styles/pdf-container.css";

// PDF setup (script postinstall in package.json changes the file from .mjs to .js)
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();
const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

// Container for the PDF viewer
/**
 * PdfContainer Component
 * @description Container that displays the questionnaire PDF
 * @returns {JSX.Element} The rendered PdfContainer component
 */
const PdfContainer = ({
  pageNumber,
  setNumPages,
  hoveredChipName,
  pdfType,
  invalidData,
  invalidFetched,
  questionnaire,
  questionnaireFetched,
}) => {
  const [file, setFile] = useState(null);
  const overlayRef = useRef(null);

  // Set the number of pages in the PDF
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Set the PDF file to display once the questionnaire is fetched
  useEffect(() => {
    if (questionnaireFetched && questionnaire && questionnaire.url) {
      setFile(() => questionnaire.url);
    }
  }, [questionnaire, questionnaireFetched]);

  return (
    <div id="main-pdf-container">
      <div id="pdf-container" key="pdf-container">
        <Document
          className="questionnaire"
          options={options}
          file={file ? file : null}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div id="pdf-loading">
              <p id="pdf-loading-p">Loading Questionnaire...</p>
            </div>
          }
        >
          <Page
            className="page"
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={1.4}
            size="A4"
          />
        </Document>
        <div id="pdf-value-overlay">
          <div
            id={
              invalidFetched &&
              invalidData &&
              checkValidity(hoveredChipName, invalidData) === true
                ? "invalid-overlay"
                : "valid-overlay"
            }
            key="pdf-overlay"
            className={`pdf-value ${
              hoveredChipName ? `${hoveredChipName}-hover${pdfType}` : ""
            }`}
            style={!hoveredChipName ? { opacity: 0 } : { opacity: 1 }}
            ref={hoveredChipName ? overlayRef : null}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PdfContainer;
