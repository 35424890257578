import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearClient,
  clearUser,
  setClientID,
  setProjectID,
} from "../../../../Redux/Actions/actions";
import "../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import useCreateUser from "../../QueryHooks/users/useCreateUser";
import useGetUser from "../../QueryHooks/users/useGetUser";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Form/Helper/formbtnhelper.js";
import "./styles/user-modal.css";

// Modal to Sign in or Sign up a user
const UserModal = () => {
  const dispatch = useDispatch();
  const {
    navigate,
    client,
    openModal,
    setOpenModal,
    handleFormatPhone,
    capitalizeNameAfterSpace,
  } = useContext(DashboardContext);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userTitle, setUserTitle] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [existingUser, setExistingUser] = useState(false);
  const clientId = client.id;
  const clientName = client.shortName ? client.shortName : client.name;
  const { enqueueSnackbar } = useSnackbar();

  // custom mutation hook for creating a new user for the logged in client
  const createNewUser = useCreateUser(
    dispatch,
    clientId,
    setOpenModal,
    enqueueSnackbar,
  );
  // custom hook for checking if a user exists for the logged in client, and returning that user if they do
  const getUser = useGetUser(dispatch, clientId, setOpenModal, enqueueSnackbar);

  const titles = [
    "Architect",
    "Area Manager",
    "Contractor",
    "Dust Control Coordinator",
    "Dust Control Manager",
    "Dust Control Supervisor",
    "Dust Monitor",
    "Engineer",
    "Estimator",
    "Foreman",
    "President",
    "Project Administrator",
    "Project Coordinator",
    "Property Developer",
    "Project Director",
    "Project Engineer",
    "Project Executive",
    "Project Manager",
    "Project Supervisor",
    "Regional Director",
    "Regional Manager",
    "Senior Vice President",
    "Site Manager",
    "Superintendent",
    "Vertical Construction Manager",
    "Vice President",
    "Vice President, Building",
    "Vice President, Land",
  ];

  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="new-user-modal"
        aria-describedby="new-user-modal"
        className="new-user-modal"
      >
        <div className="new-user-modal-container">
          <h1 id="new-user-modal-header">
            {`Welcome to ${clientName ? clientName + "'s" : ""} Request Portal`}{" "}
          </h1>
          <hr id="new-user-modal-hr" />
          <p id="new-user-modal-info">
            {
              "If you are a new user please fill out the following information to continue. If you have filled out this form before, check the 'Existing User' checkbox and complete the form to restore your information. This information will be securely stored in our database and will only be used for the purposes of your requests and differentiating you from your colleagues."
            }
          </p>
          {/* If checked, the user has signed in before, if not then the user is new */}
          <div className="new-user-form">
            <div className="existing-user-container">
              <label
                id="existing-user-label"
                className="form-label"
                htmlFor="existing-user-checkbox"
              >
                Existing User?
              </label>
              <Checkbox
                className="existing-user-checkbox"
                checked={existingUser}
                onChange={() => setExistingUser(!existingUser)}
                sx={{
                  color: "white",
                }}
              />
            </div>
            <div className="input-user-container">
              <label className="form-label">Full Name:</label>
              <FormControl
                variant="outlined"
                id="new-user-formControl"
                className={isValidName(userName, false) ? "" : "required"}
              >
                <InputLabel
                  id="user-name-label"
                  htmlFor="user-name-input"
                  classes={{ shrink: "user-label-shrink" }}
                >
                  First and Last Name:
                </InputLabel>
                <OutlinedInput
                  id="user-name-input"
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    let name = e.target.value;
                    // Ensure that the first letter of each word is capitalized, and all other letters are lowercase before setting the state
                    let formattedName = capitalizeNameAfterSpace(name);
                    // Remove any non-alphabetical characters from the input
                    formattedName = formattedName.replace(/[^a-zA-Z ]/g, "");
                    setUserName(formattedName);
                  }}
                  autoComplete="off"
                  classes={{
                    root: "user-name-root",
                    notchedOutline: "user-notchedOutline",
                    input: "user-name-root-input",
                  }}
                  endAdornment={
                    <Tooltip
                      id="clear-btn-tooltip"
                      classes={{
                        tooltip: "tooltip-clear-btn",
                        popper: "popper-clear-btn",
                        tooltipPlacementTop: "tooltip-top",
                      }}
                      title={userName === "" ? "" : "Clear Field"}
                      placement="top"
                    >
                      <InputAdornment
                        className="dashboard-user-form-adornment"
                        position="end"
                        id="input-adornment"
                        style={
                          userName === ""
                            ? { visibility: "hidden" }
                            : { visibility: "visible" }
                        }
                      >
                        <IconButton
                          style={
                            userName === ""
                              ? { pointerEvents: "none", cursor: "default" }
                              : { pointerEvents: "auto", cursor: "pointer" }
                          }
                          aria-label="clear-user-name"
                          onClick={() => setUserName("")}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          id="clear-icon-button"
                          disabled={userName === "" ? true : false}
                        >
                          <CloseIcon className="clear-icon" />
                        </IconButton>
                      </InputAdornment>
                    </Tooltip>
                  }
                />
              </FormControl>
            </div>
            <div className="input-user-container">
              <label className="form-label">Email:</label>
              <FormControl
                variant="outlined"
                id="new-user-formControl"
                className={isValidEmail(userEmail, false) ? "" : "required"}
              >
                <InputLabel
                  id="user-email-label"
                  htmlFor="user-email-input"
                  classes={{ shrink: "user-label-shrink" }}
                >
                  Email Address:
                </InputLabel>
                <OutlinedInput
                  id="user-email-input"
                  type="text"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value.toLowerCase())}
                  classes={{
                    root: "user-email-root",
                    notchedOutline: "user-email-notchedOutline",
                    input: "user-email-root-input",
                  }}
                  autoComplete="off"
                  endAdornment={
                    <Tooltip
                      id="clear-btn-tooltip"
                      classes={{
                        tooltip: "tooltip-clear-btn",
                        popper: "popper-clear-btn",
                        tooltipPlacementTop: "tooltip-top",
                      }}
                      title={userName === "" ? "" : "Clear Field"}
                      placement="top"
                    >
                      <InputAdornment
                        className="dashboard-user-form-adornment"
                        position="end"
                        id="input-adornment"
                        style={
                          userName === ""
                            ? { visibility: "hidden" }
                            : { visibility: "visible" }
                        }
                      >
                        <IconButton
                          style={
                            userName === ""
                              ? { pointerEvents: "none", cursor: "default" }
                              : { pointerEvents: "auto", cursor: "pointer" }
                          }
                          aria-label="clear-user-email"
                          onClick={() => setUserEmail("")}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          id="clear-icon-button"
                          disabled={userName === "" ? true : false}
                        >
                          <CloseIcon className="clear-icon" />
                        </IconButton>
                      </InputAdornment>
                    </Tooltip>
                  }
                />
              </FormControl>
            </div>
            {!existingUser && (
              <>
                <div className="input-user-container">
                  <label className="form-label">Phone:</label>
                  <FormControl
                    variant="outlined"
                    id="new-user-formControl"
                    className={
                      isValidPhoneNumber(userPhone, false) ? "" : "required"
                    }
                  >
                    <InputLabel
                      id="user-phone-label"
                      htmlFor="user-phone-input"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      Phone Number:
                    </InputLabel>
                    <OutlinedInput
                      id="user-phone-input"
                      type="text"
                      value={userPhone}
                      onChange={(e) => {
                        const formattedPhone = handleFormatPhone(
                          e.target.value,
                        );
                        setUserPhone(formattedPhone);
                      }}
                      maxLength={12}
                      autoComplete="off"
                      classes={{
                        root: "user-phone-root",
                        notchedOutline: "user-phone-notchedOutline",
                        input: "user-phone-root-input",
                      }}
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={userPhone === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="dashboard-user-form-adornment"
                            position="end"
                            id="input-adornment"
                            style={
                              userPhone === ""
                                ? { visibility: "hidden" }
                                : { visibility: "visible" }
                            }
                          >
                            <IconButton
                              style={
                                userPhone === ""
                                  ? {
                                      pointerEvents: "none",
                                      cursor: "default",
                                    }
                                  : {
                                      pointerEvents: "auto",
                                      cursor: "pointer",
                                    }
                              }
                              aria-label="clear-user-phone"
                              onClick={() => setUserPhone("")}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={userPhone === "" ? true : false}
                            >
                              <CloseIcon className="clear-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div className="input-user-container">
                  <label className="form-label">Title:</label>
                  <div className="title-placeholder-container">
                    <FormControl
                      variant="outlined"
                      id="new-user-formControl"
                      className={
                        !userTitle || userTitle.length < 3 ? "required" : ""
                      }
                    >
                      <InputLabel
                        id="user-title-label"
                        htmlFor="user-title-input"
                        classes={{ shrink: "user-label-shrink" }}
                      >
                        Title:
                      </InputLabel>
                      <OutlinedInput
                        id="user-title-input"
                        type="text"
                        value={userTitle}
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowRight") {
                            e.preventDefault();
                            setUserTitle(placeholder);
                            setPlaceholder("");
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setPlaceholder("");
                          }
                          setUserTitle(e.target.value);
                          let lowercase = e.target.value.toLowerCase();
                          let match = titles.find((title) => {
                            if (!lowercase) return false;
                            return title.toLowerCase().startsWith(lowercase);
                          });

                          if (match) {
                            match =
                              e.target.value + match.slice(lowercase.length);
                            setPlaceholder(match);
                          } else {
                            setPlaceholder("");
                          }
                        }}
                        autoComplete="off"
                        classes={{
                          root: "user-title-root",
                          notchedOutline: "user-title-notchedOutline",
                          input: "user-title-root-input",
                        }}
                        endAdornment={
                          <Tooltip
                            id="clear-btn-tooltip"
                            classes={{
                              tooltip: "tooltip-clear-btn",
                              popper: "popper-clear-btn",
                              tooltipPlacementTop: "tooltip-top",
                            }}
                            title={userTitle === "" ? "" : "Clear Field"}
                            placement="top"
                          >
                            <InputAdornment
                              className="dashboard-user-form-adornment"
                              position="end"
                              id="input-adornment"
                              style={
                                userTitle === ""
                                  ? { visibility: "hidden" }
                                  : { visibility: "visible" }
                              }
                            >
                              <IconButton
                                style={
                                  userTitle === ""
                                    ? {
                                        pointerEvents: "none",
                                        cursor: "default",
                                      }
                                    : {
                                        pointerEvents: "auto",
                                        cursor: "pointer",
                                      }
                                }
                                aria-label="clear-user-title"
                                onClick={() => {
                                  setUserTitle("");
                                  setPlaceholder("");
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                                id="clear-icon-button"
                                disabled={userTitle === "" ? true : false}
                              >
                                <CloseIcon className="clear-icon" />
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        }
                      />
                    </FormControl>
                    <input
                      type="text"
                      id="user-title-placeholder"
                      value={placeholder}
                      disabled
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="user-button-container">
            <Button
              variant="contained"
              id="user-logout-btn"
              onClick={() => {
                dispatch(clearClient());
                dispatch(setClientID(null));
                dispatch(setProjectID(null));
                dispatch(clearUser());
                navigate("/", { replace: true });
              }}
            >
              Log out
            </Button>
            <Button
              variant="contained"
              id={existingUser ? "user-restore-btn" : "new-user-submit"}
              disabled={
                (!existingUser &&
                  (userName.length === 0 ||
                    userEmail.length === 0 ||
                    userPhone.length !== 12 ||
                    createNewUser.isLoading)) ||
                (existingUser &&
                  (userName.length === 0 ||
                    userEmail.length === 0 ||
                    getUser.isLoading))
              }
              onClick={() => {
                if (!existingUser) {
                  let data = {
                    name: userName,
                    email: userEmail,
                    phone: userPhone,
                    title: userTitle,
                  };
                  createNewUser.mutate(data);
                } else {
                  let data = {
                    name: userName,
                    email: userEmail,
                  };
                  getUser.mutate(data);
                }
              }}
            >
              {`${existingUser ? "Restore" : "Create"}`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserModal;
