import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";

const getPresignedUrl = async (data) => {
  const { clientId, projectId, category, fileName, fileType } = data;

  try {
    const res = await axios.post(
      `/api/get-presigned-url/${clientId}/${projectId}/${category}/`,
      {
        fileName,
        fileType,
      },
      { withCredentials: true },
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useGetPresignedUrl = () => {
  return useMutation({
    mutationFn: getPresignedUrl,
    onError: (err) => console.error("PRESIGNED URL ERROR", err),
  });
};

const uploadToS3 = async (data) => {
  const { presignedPost, file } = data;

  const formData = new FormData();

  Object.keys(presignedPost.fields).forEach((key) => {
    formData.append(key, presignedPost.fields[key]);
  });

  formData.append("file", file);

  const res = await axios.post(presignedPost.url, formData);

  return res;
};

export const useUploadToS3 = () => {
  return useMutation({
    mutationFn: uploadToS3,
    onError: (err) => console.error("useUploadToS3 ERROR", err),
  });
};

const confirmFileUpload = async (data) => {
  const { clientId, projectId, category, filePath, fileName } = data;

  try {
    const res = await axios.post(
      `/api/confirm-new-file/${clientId}/${projectId}/${category}/`,
      {
        filePath,
        fileName,
      },
      { withCredentials: true },
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useConfirmFileUpload = () => {
  const { queryClient, projectId } = useContext(DashboardContext);
  return useMutation({
    mutationFn: confirmFileUpload,
    onError: (err) => console.error("useConfirmFileUpload ERROR", err),
    onSettled: () => {
      queryClient.invalidateQueries("uploaded-project-files", projectId);
      queryClient.invalidateQueries("required-project-files", projectId);
    },
  });
};
