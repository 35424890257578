import { StyledEngineProvider } from "@mui/material/styles";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { compress, decompress } from "lz-string";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import CreateClient from "./Components/Dashboard/Admin/AdminComponents/CreateClient/CreateClient.jsx";
import InviteNewUser from "./Components/Dashboard/Admin/AdminComponents/InviteNewUser/InviteNewUser.jsx";
import ValidateRequest from "./Components/Dashboard/Admin/AdminComponents/ValidateForm/ValidateRequest.jsx";
import AdminPortal from "./Components/Dashboard/Admin/AdminPortal.jsx";
import ClientPortal from "./Components/Dashboard/Client/ClientPortal.jsx";
import Dashboard from "./Components/Dashboard/Dashboard.jsx";
import FormContainer from "./Components/Dashboard/DashboardComponents/Form/FormContainer.jsx";
import ClientFolder from "./Components/Dashboard/DashboardComponents/ProjectFiles/ClientFolder.jsx";
import ClientsFolder from "./Components/Dashboard/DashboardComponents/ProjectFiles/ClientsFolder.jsx";
import FilesFolder from "./Components/Dashboard/DashboardComponents/ProjectFiles/FilesFolder.jsx";
import ProjectFolder from "./Components/Dashboard/DashboardComponents/ProjectFiles/ProjectFolder.jsx";
import UserSettings from "./Components/Dashboard/DashboardComponents/UserSettings/UserSettings.jsx";
import Login from "./Components/Login/Login.jsx";
// import DragAndDropFile from "./Components/Dashboard/DashboardComponents/FileTest/FilesTest.jsx";
import { SnackbarProvider } from "notistack";
import { FormContextProvider } from "./Components/Dashboard/DashboardComponents/Form/context/FormProvider.jsx";
import { DashboardContextProvider } from "./Components/Dashboard/DashboardContext/DashboardContextProvider.jsx";
import { persistedStored, store } from "./Redux/store.js";
import "./styles/index.css";

// Query Client instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // cached for 1 hour
      gcTime: 1000 * 60 * 60 * 24, // garbage collection after 1 hour
      staleTime: 120000, // data becomes stale after 2 minutes
    },
  },
});

// persist the query client to local storage
const localStoragePersister = createSyncStoragePersister({
  key: "persistedQueryClient",
  storage: window.localStorage,
  serialize: (data) => compress(JSON.stringify(data)),
  deserialize: (string) => JSON.parse(decompress(string)),
});
// persisted query client instance
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

// Router setup
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SnackbarProvider
        style={{
          fontFamily: "var(--default-font)",
          fontWeight: "var(--default-font-weight)",
        }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <App />
      </SnackbarProvider>
    ),
    children: [
      {
        index: true,
        loader: () => redirect("/login"),
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "dashboard",
        element: (
          <DashboardContextProvider>
            <Dashboard />
          </DashboardContextProvider>
        ),
        children: [
          {
            path: "client/:clientId",
            element: <ClientPortal />,
          },
          {
            path: "admin",
            element: <AdminPortal />,
          },
          {
            path: "admin/validate-questionnaire/:clientId/:requestId",
            element: <ValidateRequest />,
          },
          {
            path: "admin/create-client",
            element: <CreateClient />,
          },
          {
            path: "admin/create-invite-user",
            element: <InviteNewUser />,
          },
          {
            path: "form/",
            element: (
              <FormContextProvider>
                <FormContainer />
              </FormContextProvider>
            ),
          },
          {
            path: "form/:clientID/",
            element: (
              <FormContextProvider>
                <FormContainer />
              </FormContextProvider>
            ),
          },
          {
            path: "form/:clientID/:projectID/:slideID/",
            element: (
              <FormContextProvider>
                <FormContainer />
              </FormContextProvider>
            ),
          },
          {
            path: "files",
            element: <ClientsFolder />,
          },
          {
            path: "files/:clientID",
            element: <ClientFolder />,
          },
          {
            path: "files/:clientID/:projectID",
            element: <ProjectFolder />,
          },
          {
            path: "files/:clientID/:projectID/uploaded",
            element: <FilesFolder queryType={"uploaded"} />,
          },
          {
            path: "files/:clientID/:projectID/required",
            element: <FilesFolder queryType={"required"} />,
          },
          {
            path: "user-settings/:userID",
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
]);

// initialize the root element
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render application/component function
const render = (Component) => {
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router}>
          <PersistGate loading={null} persistor={persistedStored}>
            <StyledEngineProvider injectFirst>
              <Component />
            </StyledEngineProvider>
          </PersistGate>
        </RouterProvider>
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </Provider>,
  );
};

render(App);

// Enable Hot Module Replacement (HMR) when in development
if (module.hot) {
  module.hot.accept("./App", () => {
    const NextComponent = require("./App").default;
    render(NextComponent);
  });
}
