import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const fetchUploadedProjectFiles = async (projectId, userType) => {
  const res = await axios.get(
    `/api/uploaded-project-files/${projectId}/${userType}/`,
    {
      withCredentials: true,
    },
  );
  return res.data;
};

const useUploadedProjectFiles = (projectId, userType) => {
  return useQuery({
    queryKey: ["uploaded-project-files", projectId, userType],
    queryFn: () => fetchUploadedProjectFiles(projectId, userType),
    enabled: !!projectId,
  });
};

export default useUploadedProjectFiles;
