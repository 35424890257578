import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to toggle saved project
const toggleSavedProject = async (userId, projectId) => {
  const res = await axios.patch(
    `/api/toggle-saved-project/${userId}/`,
    {
      project_id: projectId,
    },
    {
      withCredentials: true,
    },
  );

  return res.data;
};
// Custom hook to toggle saved project
export const useToggleSavedProject = (
  enqueueSnackbar,
  clientId,
  userId,
  rows,
  setRows,
) => {
  const { queryClient } = useContext(DashboardContext);
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (data) => toggleSavedProject(userId, data.projectId),
    onMutate: async (variables) => {
      const { projectId } = variables;

      const previousRows = rows;

      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === projectId
            ? { ...row, savedProject: !row.savedProject }
            : row,
        ),
      );

      return { previousRows: previousRows };
    },
    onSuccess: (data) => {
      const { savedProjects, projectName, action } = data;
      dispatch(
        updateUser({
          savedProjects: savedProjects,
        }),
      );
      enqueueSnackbar(
        action === "added"
          ? `Successfully added ${projectName} to "My Projects"`
          : `Successfully removed ${projectName} from "My Projects"`,
        {
          variant: "success",
          autoHideDuration: 2500,
        },
      );
    },
    onError: (error, variables, context) => {
      console.log("TOGGLE SAVED PROJECT ERROR: ", error);

      setRows(() => [...context.previousRows]);
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
        autoHideDuration: 2500,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["clientRows", clientId] });
    },
  });
};
