import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import StarIcon from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
// import tooltipStyles from "../Inputs/styles/tooltip.module.css";
import SvgIcon from "@mui/icons-material/CheckCircle";
import "../../../../Tooltip/styles/tooltip.css";
import "./styles/color-legend.css";

const ColorsLegend = ({ requestStatus }) => {
  const baseColors = {
    Completed: "rgb(7, 244, 31)",
    Incomplete: "rgb(224, 12, 12)",
    Current: "rgb(36, 182, 255)",
  };

  const baseIcons = {
    Completed: CheckCircleIcon,
    Incomplete: DangerousIcon,
    Current: StarIcon,
  };

  const revisionColors = {
    Completed: "rgb(7, 244, 31)",
    Revisions: "rgb(224, 12, 12)",
    Revised: "rgb(228, 144, 7)",
    Current: "rgb(36, 182, 255)",
  };

  const revisionIcons = {
    Completed: CheckCircleIcon,
    Revisions: AssignmentLateIcon,
    Revised: AssignmentLateIcon,
    Current: StarIcon,
  };

  const baseColorIds = {
    Completed: "completed-dot",
    Incomplete: "incomplete-dot",
    Current: "current-slide-dot",
  };

  const revisionColorIds = {
    Revisions: "revisions-dot",
    Revised: "revised-dot",
    Completed: "completed-dot",
    Current: "current-slide-dot",
  };

  const colorsMessage = {
    Completed: "All required fields have been filled out for this slide",
    Incomplete: "Required fields have not been filled out for this slide",
    Revisions:
      "The incomplete/invalid fields for this slide have not been updated",
    Revised: "The incomplete/invalid fields for this slide have been updated",
    Current: "This is the current slide",
  };
  return (
    <div className="colors-legend-container">
      {/*
      If requestStatus !== "RV" then use base colors to create a legend of colors
      - The legend should have a name which is the key of the color object, and a circle with the color of the value
      */}
      {requestStatus !== "RV" ? (
        <div className="colors-legend">
          {Object.keys(baseColors).map((key, index) => {
            return (
              <Tooltip
                key={`${key}-tooltip`}
                id="color-legend-tooltip"
                title={colorsMessage[key]}
                placement={
                  key === "Incomplete" || key === "Completed" ? "top" : "bottom"
                }
                arrow
                classes={{
                  tooltip: "color-legend-tooltip",
                  popper: "color-legend-popper",
                  arrow: "color-legend-arrow",
                  tooltipPlacementTop: "tooltip-top",
                  tooltipPlacementBottom: "tooltip-bottom",
                }}
              >
                <div id={baseColorIds[key]} className="colors" key={index}>
                  <span
                    className={"color-label"}
                    id={`label-${key}`}
                    // style={{
                    //   color: baseColors[key],
                    // }}
                  >{`${key === "Current" ? "Current Slide" : key}: `}</span>
                  <SvgIcon
                    className="legend-icon"
                    id={`icon-${key}`}
                    // style={{
                    //   fill: baseColors[key],
                    //   filter: "drop-shadow(0px 1px 1px rgba(50, 50, 50, 0.5))",
                    // }}
                    component={baseIcons[key]}
                  />
                </div>
              </Tooltip>
            );
          })}
        </div>
      ) : (
        <div className="colors-legend revise">
          {Object.keys(revisionColors).map((key, index) => {
            return (
              <Tooltip
                key={`${key}-tooltip`}
                title={colorsMessage[key]}
                placement={key !== "Current Slide" ? "top" : "bottom"}
                arrow
                id="color-legend-tooltip"
                classes={{
                  tooltip: "color-legend-tooltip",
                  popper: "color-legend-popper",
                  arrow: "color-legend-arrow",
                  tooltipPlacementTop: "tooltip-top",
                  tooltipPlacementBottom: "tooltip-bottom",
                }}
              >
                <div
                  id={revisionColorIds[key]}
                  className={"colors"}
                  key={index}
                >
                  <span className={"color-label"} id={`label-${key}`}>{`${
                    key === "Current" ? "Current Slide" : key
                  }: `}</span>
                  <SvgIcon
                    className="legend-icon"
                    id={`icon-${key}`}
                    component={revisionIcons[key]}
                  />
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ColorsLegend;
