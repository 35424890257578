import Box from "@mui/material/Box";
import {
  DataGridPro,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setProjectID } from "../../../Redux/Actions/actions";
import { DashboardContext } from "../DashboardContext/DashboardContextProvider";
import * as defaultStyles from "../styles/main-datagrid.module.css";
import { CustomGridPagination } from "../UniversalDataGridHelper/dataGridComponents";
import useClientRows from "./ClientQueries/useClientRows";
import { useToggleSavedProject } from "./ClientQueries/useToggleSavedProject";
import {
  CustomToolbar,
  NoRowsOverlay,
} from "./DataGridCustomComponents/clientComponents";
import { getColumns } from "./Helper/clientDataGridHelper";
import "./styles/client-requests.css";

dayjs.extend(customParseFormat);

/**
 * ClientRequests Component
 *
 * @description
 * The ClientRequests component renders an mui Datagrid which displays
 * every request/project for the users specific company.
 *
 */
const ClientRequests = () => {
  const {
    clientId,
    userId,
    user,
    navigate,
    queryClient,
    servicesAnchor,
    setOpenServices,
    currServicesRow,
    openServicesPopoverHandler,
  } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  let initialRows = useRef([]);
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const filterButtonRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  // Client rows custom query hook
  const {
    data: rowData,
    isLoading,
    isFetched,
  } = useClientRows(clientId, userId);

  // Set initial rows to the fetched data
  useEffect(() => {
    if (isFetched && rowData) {
      initialRows.current = [];
      setRows(() => []);

      initialRows.current.push(...rowData.filter((row) => !row.archived));
      // set rows to initialRows.current without the rows with "archived" === true
      setRows(() => [...initialRows.current]);
    }
  }, [clientId, isFetched, rowData]);

  // Set the services popover anchor to open or close
  useEffect(() => {
    setOpenServices(Boolean(servicesAnchor));
  }, [servicesAnchor]);

  // Props for popover components in the datagrid
  const servicesPopoverProps = {
    servicesAnchor,
    currServicesRow,
    openServicesPopoverHandler,
  };

  // Search bar logic
  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchValue(value);

    // Filter out archived projects
    let dataToFilter = initialRows.current.filter((row) => !row.archived);

    // Request status conversion object
    const statusConversion = {
      P: "Pending",
      IP: "In Progress",
      I: "Editable",
      RV: "Revisions",
      C: "Completed",
    };

    // Filter rows based on selected column values
    let currentFilteredRows = dataToFilter.filter((row) => {
      let rowValues = [
        row.projectName,
        statusConversion[row.requestStatus],
        row.projectType,
      ];
      // Add services to rowValues
      if (row.services["swppp"].length > 0) {
        rowValues = [...rowValues, ...row.services["swppp"]];
      }

      if (row.services["dust"].length > 0) {
        rowValues = [...rowValues, ...row.services["dust"]];
      }

      // Convert rowValues to string and check if it includes the search value
      let rowValuesString = rowValues.join(" ").toLowerCase();
      // Return true if the rowValuesString includes the search value
      return rowValuesString.includes(value.toLowerCase());
    });
    // Set the rows to the filtered rows
    setRows([...currentFilteredRows]);
  };

  // Clear search input
  const handleClearInput = () => {
    setSearchValue("");
    setRows([...initialRows.current.filter((row) => !row.archived)]);
  };
  // Toggle archived project
  const toggleSavedProjects = useToggleSavedProject(
    enqueueSnackbar,
    clientId,
    userId,
    rows,
    setRows,
  );

  return (
    <Box
      id="client-grid-container"
      component={"div"}
      className={rows.length > 0 ? "rows" : "no-rows"}
    >
      <h1 id="data-grid-header">Projects Table</h1>
      <DataGridPro
        rows={rows}
        columns={getColumns(
          dispatch,
          navigate,
          setProjectID,
          servicesPopoverProps,
          toggleSavedProjects,
          queryClient,
          // apiRef,
        )}
        apiRef={apiRef}
        editMode="row"
        getRowHeight={() => "auto"}
        pagination
        pinnedColumns={{ left: ["projectName"] }}
        logLevel="warn"
        loading={isLoading}
        pageSizeOptions={[5, 8, 10, 12, 15]}
        slots={{
          toolbar: CustomToolbar,
          gridToolbarFilterButton: GridToolbarFilterButton,
          pagination: CustomGridPagination,
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            norowsvariant: "skeleton",
            sx: {
              backgroundColor: "transparent",
              "& .MuiCircularProgress-svg": {
                color: "var(--argus-white)",
                fill: "var(--argus-white)",
              },
            },
          },
          toolbar: {
            value: searchValue,
            handleSearch: handleSearch,
            filterButtonRef: filterButtonRef,
            rows: rows,
            setRows: setRows,
            setSearchValue: setSearchValue,
            handleClearInput: handleClearInput,
            initialRows: initialRows,
            savedProjects: user.savedProjects || [],
          },
          pagination: {
            className: defaultStyles["main-grid-pagination"],
            id: defaultStyles["client-grid-pagination"],
            labelRowsPerPage: "Projects per page:",
            labelDisplayedRows: ({ from, to, count }) =>
              `Projects ${from}-${to} of ${
                count !== -1 ? count : `more than ${to}`
              }`,
            classes: {
              root: defaultStyles["main-grid-pagination-root"],
              displayedRows: defaultStyles["pagination-displayedRows"],
              selectRoot: defaultStyles["pagination-select-root"],
              select: defaultStyles["pagination-select"],
              selectLabel: defaultStyles["pagination-selectLabel"],
              input: defaultStyles["pagination-select-input"],
              toolbar: defaultStyles["grid-pagination-toolbar"],
            },
          },
          filterPanel: {
            className: defaultStyles["filter-panel"],
            filterFormProps: {
              className: defaultStyles["filter-form"],
              columnInputProps: {
                className: defaultStyles["filter-column-inputs"],
                sx: {
                  "& .MuiFormLabel-root": {
                    color: "var(--label-color)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                  },
                  "& .MuiInputBase-root": {
                    color: "var(--argus-white)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                    borderBottom: "none !important",
                    "&::after": {
                      borderBottom: "none !important",
                    },
                    "&::before": {
                      borderBottom: "none !important",
                    },
                    "& .MuiNativeSelect-select": {
                      borderBottom: "2px solid var(--argus-white) !important",
                      "&::before": {
                        borderBottom: "none !important",
                      },
                      "&::after": {
                        borderBottom: "none !important",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "var(--argus-white)",
                    },
                  },
                },
              },
              operatorInputProps: {
                className: defaultStyles["operator-column-inputs"],
                sx: {
                  "& .MuiFormLabel-root": {
                    color: "var(--label-color)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                  },
                  "& .MuiInputBase-root": {
                    color: "var(--argus-white)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                    borderBottom: "none !important",
                    "&::after": {
                      borderBottom: "none !important",
                    },
                    "&::before": {
                      borderBottom: "none !important",
                    },
                    "& .MuiNativeSelect-select": {
                      borderBottom: "2px solid var(--argus-white) !important",
                      "&::before": {
                        borderBottom: "none !important",
                      },
                      "&::after": {
                        borderBottom: "none !important",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "var(--argus-white)",
                    },
                  },
                },
              },
              valueInputProps: {
                className: defaultStyles["value-column-inputs"],
                id: "value-column-inputs",
                InputComponentProps: {
                  className: defaultStyles["value-input"],
                  id: defaultStyles["value-input"],
                  style: {
                    color: "var(--argus-white)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                  },
                },
                sx: {
                  "& .MuiFormLabel-root": {
                    color: "var(--label-color)",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                  },
                  "& .MuiInputBase-root": {
                    color: "var(--argus-white) !important",
                    fontFamily: "var(--default-font)",
                    fontWeight: "var(--default-font-weight)",
                    "&::after": {
                      borderBottom: "none !important",
                    },
                    "&::before": {
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-input": {
                      borderBottom: "2px solid var(--argus-white) !important",
                      color: "var(--argus-white)",
                      "&::before": {
                        borderBottom: "none !important",
                      },
                      "&::after": {
                        borderBottom: "none !important",
                      },
                    },
                  },
                },
              },
              logicalOperatorInputProps: {
                color: "var(--argus-white)",
                fontFamily: "var(--default-font)",
                fontWeight: "var(--default-font-weight)",
              },
              deleteIconProps: {
                sx: {
                  "& .MuiSvgIcon-root": {
                    color: "var(--close-icon-color)",
                    stroke: "var(--close-icon-color)",
                  },
                },
              },
              panelFooterProps: {
                className: defaultStyles["filter-panel-footer"],
              },
            },
          },
        }}
        classes={{
          footerContainer: defaultStyles?.footerContainer,
          columnHeader: defaultStyles?.columnHeader,
          columnHeaders: defaultStyles?.columnHeaders,
          columnHeadersInner: defaultStyles?.columnHeadersInner,
          "columnHeader--sorted": defaultStyles?.columnHeaderSorted,
          "columnHeader--sortable": defaultStyles?.columnHeaderSortable,
          columnHeaderDraggableContainer:
            defaultStyles?.columnHeaderDraggableContainer,
          columnHeaderTitle: defaultStyles?.columnHeaderTitle,
          columnHeaderTitleContainer: defaultStyles?.columnHeaderTitleContainer,
          columnSeparator: defaultStyles?.columnSeparator,
          sortIcon: defaultStyles?.sortIcon,
          columnHeaderCheckbox: defaultStyles?.columnHeaderCheckbox,
          iconButtonContainer: defaultStyles?.iconButtonContainer,
          cell: defaultStyles?.cell,
          cellContent: defaultStyles?.cellContent,
          panelFooter: defaultStyles["filter-panel-footer"],
          pinnedColumns: defaultStyles?.pinnedColumns,
          pinnedColumnHeaders: defaultStyles?.pinnedColumnHeaders,
          treeDataGroupingCell: defaultStyles?.treeDataGroupingCell,
          treeDataGroupingCellToggle: defaultStyles?.treeDataGroupingCellToggle,
          "row--editing": defaultStyles?.editingRow,
          virtualScroller: defaultStyles?.virtualScroller,
          virtualScrollerRenderZone: defaultStyles?.renderZone,
          virtualScrollerContent: defaultStyles?.virtualScrollerContent,
          overlayWrapper: defaultStyles?.overlayWrapper,
          overlayWrapperInner: defaultStyles?.overlayWrapperInner,
          row: defaultStyles?.row,
        }}
        sx={[
          {
            boxShadow: 7,
            border: 3,
            borderColor: "var(--outline-color)",
            fontFamily: "var(--default-font)",
            backgroundColor: "var(--main-blue)",
            // backgroundColor: "rgb(57, 136, 168)",
            color: "var(--argus-white)",
            transition: "all 135ms ease 0s",
            width: "100%",
            "& .MuiDataGrid-row": {
              minHeight: "50px",
              maxHeight: "60px",
              transition: "all 60ms ease 0s",
            },
            "& .MuiDataGrid-container--top [role=row]": {
              background: "transparent !important",
            },
            "& .MuiDataGrid-row--borderBottom": {
              borderBottom: "2px solid var(--outline-color)",
              overflow: "hidden",
              background: "transparent !important",
            },
          },
        ]}
        showCellVerticalBorders={true}
        disableRowSelectionOnClick
        columnHeaderHeight={65}
        showColumnBorders={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: window.innerHeight >= 980 ? 10 : 5,
              page: 0,
            },
          },
        }}
        columnVisibilityModel={{
          savedAt: false,
        }}
        isCellEditable={(params) =>
          params.field === "archived" && params.row.requestStatus === "C"
        }
        rowThreshold={0}
      />
    </Box>
  );
};

export default ClientRequests;
