import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AttachFiles from "./AttachFiles";
import EmailBody from "./EmailBody";
import "./styles/invite-new-user.css";
import "./styles/send-custom-invite.css";

/**
 * SendCustomInvite Component
 * @description Component that handles sending a custom invite to a user
 * @param {Object} props - Component props
 * @param {boolean} props.openCustomInvite - Flag indicating if the custom invite dialog is open
 * @param {Function} props.setOpenCustomInvite - Function to set the open state of the custom invite dialog
 * @param {string} props.clientId - The ID of the selected client
 * @param {Object} props.selectedUser - The selected user to send the invite to
 * @param {Function} props.setSelectedUser - Function to set the selected user for the custom invite dialog
 * @param {string} props.clientName - The name of the selected client
 * @param {string} props.clientCode - The code of the selected client
 * @param {Function} props.setNewUser - Function to set the new user object
 * @param {Function} props.enqueueSnackbar - Function to display a snackbar notification
 * @returns {JSX.Element} SendCustomInvite component
 */
const SendCustomInvite = ({
  openCustomInvite,
  setOpenCustomInvite,
  clientId,
  selectedUser,
  setSelectedUser,
  clientName,
  clientCode,
  setNewUser,
  enqueueSnackbar,
}) => {
  const [message, setMessage] = useState("");
  const [defaultMessage, setDefaultMessage] = useState(
    `Argus is excited to invite you to access ${clientName}'s projects portal. Simply click the link below to instantly sign in, and click the 'New Project' button to beginning filling out our SWPPP services questionnaire. Alternatively, you can visit www.argusazswppp.com and sign in at a later time using the code provided below, along with your name and email address.`,
  );
  const [files, setFiles] = useState([]);

  // Update the default message when a new client is selected
  useEffect(() => {
    setDefaultMessage(
      `Argus is excited to invite you to access ${clientName}'s projects portal. Simply click the link below to instantly sign in, and click the 'New Project' button to beginning filling out our SWPPP services questionnaire. Alternatively, you can visit www.argusazswppp.com and sign in at a later time using the code provided below, along with your name and email address.`,
    );
  }, [selectedUser]);

  // Mutation to invite a new user
  const inviteNewUserMutation = useMutation({
    mutationFn: async (data) => {
      try {
        // Instantiate a new FormData object
        const formData = new FormData();
        // Append the files to the FormData object
        files.forEach((file) => {
          formData.append("files", file);
        });
        // Append the message to the FormData object
        formData.append("message", data.message);
        // Send a POST request to the server with the FormData object
        const res = await axios.post(
          `/api/admin-invite-dashboard/${data.clientId}/${data.userId}/`,
          formData,
          {
            "Content-Type": "multipart/form-data",
            withCredentials: true,
          },
        );
        // Return the response data
        return res;
      } catch (error) {
        console.log("INVITATION ERROR: ", error);
      }
    },
    onSuccess: (data) => {
      if (data.status !== 200) return;

      enqueueSnackbar("Invitation email has successfully been sent", {
        variant: "success",
      });

      setNewUser({
        name: "",
        email: "",
        phone: "",
        title: "",
      });

      setSelectedUser({
        id: "",
        name: "",
        email: "",
        title: "",
      });

      setMessage("");

      setFiles([]);

      setOpenCustomInvite(false);
    },
    onError: (error) => {
      enqueueSnackbar("Error sending user invitation", { variant: "error" });
    },
  });

  // Event handler to send a custom invite to the selected user
  const handleSendCustomInvite = async () => {
    await inviteNewUserMutation.mutateAsync({
      clientId: clientId,
      userId: selectedUser.id,
      message: message,
    });
  };

  return (
    <Modal
      open={openCustomInvite}
      className="custom-invite-modal"
      onClose={() => {
        setMessage("");
        setOpenCustomInvite(false);
      }}
    >
      <div id="attach-invite-container">
        <div className="invite-modal-container">
          <EmailBody
            selectedUser={selectedUser}
            message={message}
            setMessage={setMessage}
            defaultMessage={defaultMessage}
            clientCode={clientCode}
          />
          <div id="invite-user-btns">
            <Button
              id="default-message-btn"
              className="default-message-btn"
              onClick={() => {
                setMessage("");
              }}
              variant="contained"
              disabled={!message || inviteNewUserMutation.isPending}
            >
              Clear
            </Button>
            <Button
              id="send-message-btn"
              className="send-message-btn"
              onClick={handleSendCustomInvite}
              variant="contained"
              disabled={inviteNewUserMutation.isPending}
            >
              Send Invite
            </Button>
          </div>
        </div>
        <AttachFiles files={files} setFiles={setFiles} />
      </div>
    </Modal>
  );
};

export default SendCustomInvite;
