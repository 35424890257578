// Rewrite each message so that all letters are lowercase except for the first letter of the first word in each sentence.
// | SLIDE 1 |
export const projectNameMessage =
  "The official name of the project as shown on the civil plans";
export const pointOfContactMessage =
  "The Name of the individual who will be the Main Point of Contact for this Project";
export const pointOfContactTitleMessage =
  "The official working title of the main point of contact";
export const pointOfContactPhoneMessage =
  "The phone number of the main point of contact";
export const pointOfContactEmailMessage =
  "The email address of the main point of contact";
export const onNativeLandMessage =
  "Is the project located onTribal or Native land?";
export const proposalApprovedMessage =
  "Has the proposal from Argus been approved?";
export const jobNumberMessage =
  "The purchase order or job number for billing this project";
export const accountingEmailMessage =
  "The email address associated with billing/accounting for this Project";

// | Slide 2 |
export const swpppServicesRequestMessage =
  "These options include: SWPPP Preparation, NOI Preparation/Submission, Inspection Services, and SWPPP Signage";
export const sNumMessage =
  "The number of SWPPPs that will be prepared for this project. An Argus representative will contact you to discuss pricing options";
export const submittingToRegAgencyMessage =
  "Will the SWPPP be submitted to the regulatory agency? (EPA, AZDEQ, etc.)";
export const regAgencySubmitDateMessage =
  "Date the submittal to the regulatory agent must be made";
export const activeMyDeqMessage =
  "Is there an active AZDEQ 'myDEQ' account associated with this project for permitting?";
export const noiRequestMessage = "Will Argus prepare & submit the NOI?";
export const inspectionRequestMessage =
  "Will Argus be preforming inspections for this project. If not, please complete the following questions about the inspection company & inspector. If Argus will be performing the inspections, please add at least one individual to receive the inspection reports";
export const inspectionRecipient1Message =
  "The name of the individual who will be Receiving the SWPPP Inspection report";
export const inspectionRecipient1TitleMessage =
  "The official working title of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient1EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient2Message =
  "The name of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient2TitleMessage =
  "The official working title of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient2EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient3Message =
  "The name of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient3TitleMessage =
  "The official working title of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient3EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient4Message =
  "The name of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient4TitleMessage =
  "The official working title of the individual who will be receiving the SWPPP inspection report";
export const inspectionRecipient4EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection report";
export const inspectingCompanyMessage =
  "The name of the company that will be performing the routine SWPPP inspections for this project. If Argus is to perform the inspections, please check 'YES' on the previous question";
export const inspectorNameMessage =
  "The name of the individual who will be performing the routine SWPPP inspections";
export const inspectorPhoneMessage =
  "The phone number of the individual who will be performing the routine SWPPP inspections";
export const inspectorEmailMessage =
  "The email address of the individual who will be performing the routine SWPPP inspections";
export const signRequestMessage =
  "Will this project need SWPPP Signage? If so, Argus will contact you to discuss pricing options";
export const sign3x3Message = "Requesting 3'x3' SWPPP Sign";
export const signStandMessage = "Requesting SWPPP 3'x3' Sign & Sign Stand";
export const signBannerMessage = "Requesting SWPPP Sign Banner";

// | SLIDE 3 |
export const t1Message =
  "Projects related to the development, maintenance, and improvement of state transportation systems";
export const t2Message =
  "Involves the construction, expansion, or maintenance of airports and related facilities";
export const t3Message =
  "Pertains to the development of business properties, including retail centers, offices, and industrial complexes";
export const t4Message =
  "Focuses on the construction of systems and structures to manage and mitigate flood risks";
export const t5Message =
  "Involves the extraction and processing of sand and gravel resources, primarily for construction purposes";
export const t6Message =
  "Public sector projects including infrastructure, community facilities, and government buildings";
export const t7Message =
  "Covers the installation, maintenance, and repair of pipeline systems for transportation of water, gas, or oil";
export const t8Message =
  "Related to the development of leisure and recreational facilities like parks, sports complexes, and community centers";
export const t9Message =
  "Pertains to the construction or renovation of residential properties, including single-family homes and apartment complexes";
export const t10Message =
  "Focuses on the construction and maintenance of roads, highways, and related infrastructure";
export const t11Message =
  "Involves the construction or renovation of educational facilities, from primary schools to higher education campuses";
export const t12Message =
  "Relates to the division of land into plots for residential, commercial, or industrial development";
export const t13Message =
  "Covers projects related to essential services like electricity, water, sewage, and telecommunications infrastructure";
export const otherMessage = "Another type of project not listed";
export const otherTextMessage =
  "If this projects development type is not located in the list above, please specify the correct project type";
export const scopeOfWorkMessage =
  "Please select or add the scope of work to be performed on this project";

// | SLIDE 4 |
export const startDateMessage =
  "The date the project's construction activities are scheduled to start";
export const endDateMessage =
  "The estimated date this project is to complete construction activities";
export const emergencyContactNameMessage =
  "The name of the individual, who has access to the project at all times, to contact in case of an emergency.";
export const emergencyContactCompanyMessage =
  "The company of the emergency contact";
export const emergencyContactPhoneMessage =
  "The phone number of the emergency contact";
export const emergencyContactEmailMessage =
  "The email address of the emergency contact";
export const emergencyContactTitleMessage =
  "The official working title of the emergency contact";
export const totAcrMessage =
  "The total acreage of the project, including all parcels";
export const distAcrMessage = "The total acreage to be disturbed";
export const layDownYardMessage =
  "Is there a construction/laydown yard associated with this project? A laydown yard is a designated area for temporarily storing materials and equipment that are not in use";
export const layDownYardDistMessage =
  "Is the laydown Yard/Area included on the disturbed acreage listed above?";
export const borrowAreaMessage =
  "Is there an onsite plant or borrow area exclusively for this project?";
export const portableTanksOnSiteMessage =
  "Will there be portable fuel storage tanks onsite?";
export const portableTankCapacityMessage =
  "The total capacity of the portable fuel storage tanks. If more than 1,320 combined gallons, a SPCC Plan may be required";
export const bmpCompanyNameMessage =
  "The name of the company that will be installing and maintaining the environmental BMPs";
export const bmpContactNameMessage =
  "The main point of contact for the company installing and maintaining the environmental BMPs";
export const bmpContactPhoneMessage =
  "The phone number of the main point of contact";
export const bmpContactEmailMessage =
  "The email address of the main point of contact";

// | SLIDE 5 |
export const isLinearMessage =
  "Is the project linear (e.g public/private roadways, utilities, etc.)?";
export const pAddMessage =
  "Add the exact street address or the nearest cross streets for this project, if the map above did not accurately do so. (e.g SWC of 7th Ave. & Olive, 123 Main St., etc.)";
export const pCityMessage = "The name of city where the project is located";
export const pCountyMessage =
  "The name of the county where the project is located";
export const pZipMessage = "The zip code for this project";
export const latMessage =
  "The closest latitude point of the center of the project. You can find this value on Google Maps by right clicking on the map and then selecting the coordinates option. This will copy the coordinates to your clipboard. The latitude value is the first number listed";
export const lngMessage =
  "The closest longitude point of the center of the project. You can find this value on Google Maps by right clicking on the map and then selecting the coordinates option. This will copy the coordinates to your clipboard. The longitude value is the second number listed";

//| SLIDE 6 |
export const oNameMessage =
  "The name owning entity of the project. Should match the name exactly as registered with AZCC when forming this entity";
export const oAddMessage = "The owning entities office address";
export const oCityMessage =
  "The city where the owning entities office is located";
export const oZipMessage = "The Zip Code for the owning entities office";
export const oPhoneMessage = "The owning entities office phone number";
export const oContactNameMessage =
  "The name of the individual who has the authority to make legal decisions on behalf of the owning entity and responsible officer";
export const oContactTitleMessage =
  "The official working title of the individual who has the authority to make legal decisions on behalf of the owning entity";
export const oContactPhoneMessage = "The responsible officer's phone number";
export const oContactEmailMessage = "The responsible officer's email address";
export const ownerGCCheckMessage =
  "Is the owning entity also the primary operator/general contractor for this project?";
export const delegatedRepCheckMessage =
  "Is the responsible officer delegating responsibility to another individual to act on their behalf?";
export const odrCompanyMessage =
  "The name of the company that the delegated representative is associated with";
export const odrNameMessage =
  "The name of the individual who is delegated responsibility to act on behalf of the owning entity and responsible officer";
export const odrTitleMessage =
  "The official working title of the delegate representative";
export const odrPhoneMessage =
  "The phone number of the delegated representative";
export const odrEmailMessage =
  "The email address of the delegated representative";

// Rewrite the following messages exactly as they are written, but only capitalize the first word in each sentence.
// | SLIDE 7 |
export const gcNameMessage =
  "The name of the primary operator/general contractor for this project. Name should match exactly as registered with AZCC when forming this entity";
export const gcAddMessage = "The operator's office address";
export const gcCityMessage = "The city where the operator's office is located";
export const gcZipMessage = "The zip code for the operator's office address";
export const gcPhoneMessage = "Operator's office phone Number";
export const gcContactNameMessage =
  "The name of the individual who has the authority to make legal decisions on behalf of the company and will be the individual certifying the Notice of Intent (NOI)";
export const gcContactTitleMessage =
  "The official working title of the responsible officer";
export const gcContactPhoneMessage = "Responsible officer's phone number";
export const gcContactEmailMessage = "Responsible Officer's email address";
export const gcContact2NameMessage =
  "Person in charge of day-to-day operations for this project and has been delegated authority to act on behalf of the responsible officer";
export const gcContact2TitleMessage =
  "The official working title of the day-to-day operations contact";
export const gcContact2PhoneMessage =
  "The phone number of the day-to-day operations contact";
export const gcContact2EmailMessage =
  "The email address of the day-to-day operations contact";
export const gcContact3NameMessage =
  "The name of the individual who will be in charge of this project's field operations";
export const gcContact3TitleMessage =
  "The official working title of the field operator";
export const gcContact3PhoneMessage = "The phone number of the field operator";
export const gcContact3EmailMessage =
  " The email address of the field operator";

// | SLIDE 8 |
export const dustControlRequestMessage =
  "These options include: Dust Control Manual & Logs, Dust Control Audits, and Dust Control Signage (Projects Disturbing 5 or More Acres)";
export const manualLogsRequestMessage =
  "Requesting Dust Control Manual & Logs **MORE DETAILS**";
export const dccMessage =
  "The name of the individual who will be responsible for Dust Control on the project";
export const dccTitleMessage =
  "The official working title of the projects Dust Control Coordinator";
export const dccPhoneMessage =
  "The phone number of the Dust Control Coordinator";
export const dccEmailMessage =
  "The email address of the Dust Control Coordinator";
export const dustAuditRequestMessage =
  "Requesting Dust Control Audits **MORE DETAILS**";
export const dustAuditContactMessage =
  "The Name of the individual who will be receiving the Dust Control Audit Reports";
export const dustAuditContactEmailMessage =
  "The email address of the individual who will be receiving the Dust Control Audit Reports";
export const dustSignRequestMessage = "Argus to provide Dust Control Signage";
export const dcNameMessage =
  "The name of the individual whose name is to be included as the projects contact for the Dust Sign";
export const dcPhoneMessage =
  "The phone number of the individual whose name will be on the Dust Sign";
export const dcEmailMessage =
  "The email address of the individual whose name will be on the Dust Sign";
export const dustPermitNumberMessage =
  "This projects Dust Control Permit Number";

// | SLIDE 9 |
export const dustPermitMessage =
  "Upload a copy of the dust control permit if applicable";
export const inspectorQualificationsMessage =
  "If Argus will not be performing this projects routine SWPPP inspections, please upload a copy of the inspector's qualifications";
export const swmpPlanMessage =
  "Upload existing Stormwater Management Plan (SWMP) or Stormwater Pollution Prevention Plan (SWPPP)";
export const landscapePlanMessage =
  "Upload the landscape plan for this project";
export const gradingPlanMessage =
  "Upload the grading & drainage plans for this project";
export const drainageReportMessage =
  "Upload the drainage report for this project";
export const soilsReportMessage =
  "Upload the geotechnical/soils report for this project";
export const environmentalReportMessage =
  "Upload the Phase 1 or 2 environmental report for this project";
export const endangeredSpeciesReportMessage =
  "Upload the biological or endangered species report for this project";
export const archeologicalReportMessage =
  "Upload the archaeological report for this project";
export const corpOfEngineersReportMessage =
  "Upload the Army Corps of Engineers permit for this project";
export const constructionScheduleMessage =
  "Upload the construction schedule for this project";
export const projectSpecificationsMessage =
  "Upload the project specifications for this project";
export const listOfSubcontractorsMessage =
  "Upload a list of subcontractors for this project";

// | SLIDE 10 |
export const submitterMessage =
  "Your name will be used at the submitter/contact for this form, only if you successfully submit this form (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterTitleMessage =
  "Your official working title. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterPhoneMessage =
  "Your phone number. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterEmailMessage =
  "Your email address. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
