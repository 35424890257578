/**
 * @fileoverview
 * Provides a client-side sign-in function using Axios,
 * and a custom hook (`useManualLogin`) to manually sign in users
 * (admin or client) within the Argus application.
 *
 * Includes React Query mutation logic and Redux state management for:
 *  - dispatching user/client data
 *  - navigating the user to admin or client dashboards
 *  - displaying success/error notifications
 */

import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import {
  clearClient,
  setClient,
  setClientID,
  setCompanyCode,
  setIsAdmin,
  setProjectID,
  setUser,
} from "../../../Redux/Actions/actions.js";
import { fetchAdminRows } from "../../Dashboard/Admin/AdminQueries/useAdminRows.jsx";
import { fetchClientRows } from "../../Dashboard/Client/ClientQueries/useClientRows.jsx";

/**
 * Signs in the user based on a company code.
 *
 * @async
 * @function clientSignIn
 * @param {string} code - The company or admin code used to authenticate.
 * @returns {Object} Response data containing user/client details.
 * @throws {Error} Throws if sign-in fails or the server returns an error response.
 *
 * @example
 * const data = await clientSignIn("XYZ123");
 * // data => { is_admin: false, client: {...}, companyCode: "XYZ123", ... }
 */
export const clientSignIn = async (code) => {
  try {
    const res = await axios.get(`/api/client/sign-in/${code}`, {
      withCredentials: true,
    });
    return res.data;
  } catch (err) {
    console.log("CATCH ERROR: ", err);

    throw new Error(
      err.response?.data?.message || "Sign-in failed. Please try again.",
    );
  }
};

/**
 * Custom hook that manually signs in the user using React Query's `useMutation`.
 *
 * - Determines if user is an admin or a client based on `data.is_admin`.
 * - Fetches & caches rows for Admin or Client dashboards.
 * - Dispatches Redux actions to update user/client state and navigates accordingly.
 *
 * @function useManualLogin
 * @param {Function} dispatch - Redux useDispatch instance.
 * @param {Object} user - Current user object from User reducer.
 * @param {Function} enqueueSnackbar - notistack function.
 * @param {Function} navigate - React Router navigate
 * @param {QueryClient} queryClient - queryClient instance defined in Login.jsx.
 * @returns {Object} signIn - The mutation object returned by `useMutation`.
 */
export default function useManualLogin(
  dispatch,
  user,
  enqueueSnackbar,
  navigate,
  queryClient,
) {
  const signIn = useMutation({
    mutationFn: (code) => clientSignIn(code),
    onSuccess: async (data) => {
      if (data.is_admin === true) {
        await queryClient.prefetchQuery({
          queryKey: ["adminRows"],
          queryFn: () => fetchAdminRows(true),
        });
        dispatch(setIsAdmin(true));
        dispatch(clearClient());
        dispatch(
          setUser({
            id: "argus_admin",
            companyCode: data.companyCode,
            name: "Argus Admin",
            email: "info@arguscs.com",
            phone: "602-237-5967",
            title: "Argus Admin",
            admin: true,
            currentClient: null,
            currentProject: null,
            hideArchived: false,
            archivedProjects: [],
            savedProjects: [],
          }),
        );
        enqueueSnackbar("Login Successful - Welcome Argus Admin", {
          variant: "success",
          autoHideDuration: 2500,
        });
        navigate("/dashboard/admin", { replace: true });
        return;
      }
      // Handle client login
      const clientData = data.client;
      const clientId = clientData.id;
      const [address, city, zipCode] = clientData.address.split(", ");
      dispatch(setCompanyCode(data.companyCode));
      dispatch(setIsAdmin(false));
      dispatch(
        setClient({
          id: clientId,
          name: clientData.name,
          shortName: clientData.shortName,
          address,
          city,
          zip: zipCode,
          phone: clientData.phone,
        }),
      );
      dispatch(setClientID(clientId));
      dispatch(setProjectID(null));

      const isClientUser = user?.name && user.currentClient === clientData.id;
      const snackbarName = isClientUser ? user.name : clientData.name;
      enqueueSnackbar(`Login Successful - Welcome ${snackbarName}`, {
        variant: "success",
        autoHideDuration: 2500,
      });

      if (!isClientUser) {
        await queryClient.prefetchQuery({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId),
        });
        dispatch(
          setUser({
            id: null,
            companyCode: data.companyCode,
            name: "",
            email: "",
            phone: "",
            title: "",
            admin: false,
            currentClient: clientId,
            currentProject: null,
            savedProjects: [],
          }),
        );
      } else {
        await queryClient.prefetchQuery({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId, user.id),
        });
      }
      navigate(`/dashboard/client/${clientId}`, { replace: true });
    },
    onError: (err) => {
      console.log("ONERROR: ", err.message);
      enqueueSnackbar(`Unsuccessful sign in attempt`, {
        variant: "error",
      });
    },
  });

  return signIn;
}
