import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { updateUser } from "../../../../Redux/Actions/actions";
import { fetchClientRows } from "../../Client/ClientQueries/useClientRows";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to add a new user
const useAddNewUser = async (data, clientId) => {
  const res = await axios.post(`/api/client/${clientId}/new-user/`, data, {
    withCredentials: true,
  });

  return res;
};

// Custom hook to create a new user
const useCreateUser = (dispatch, clientId, setOpenModal, enqueueSnackbar) => {
  const { queryClient, navigate } = useContext(DashboardContext);
  const create = useMutation({
    mutationFn: (data) => useAddNewUser(data, clientId),
    onSuccess: async (res) => {
      if (res.status === 201) {
        dispatch(
          updateUser({
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
            title: res.data.title,
            phone: res.data.phone,
            admin: false,
            currentClient: res.data.client,
          }),
        );
        enqueueSnackbar("User created successfully", { variant: "success" });
        setOpenModal(() => false);
        await queryClient.ensureQueryData({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId, false, res.data.id),
        });
        navigate(`/dashboard/client/${clientId}/`, { replace: true });
      } else if (res.status === 200) {
        dispatch(
          updateUser({
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone,
          }),
        );
        enqueueSnackbar(`User already exists, welcome ${res.data.name}`, {
          variant: "success",
        });
        setOpenModal(() => false);
        await queryClient.ensureQueryData({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId, false, res.data.id),
        });

        navigate(`/dashboard/client/${clientId}/`, { replace: true });
      }
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("Error creating user", { variant: "error" });
    },
  });

  return create;
};

export default useCreateUser;
