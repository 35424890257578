import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
// import { FileIcon, defaultStyles } from "react-file-icon";
import { ArgusFileIcon, FileTypeIcon, customStyles } from "./ArgusCustomIcon";
import CreateFilePopover from "./CreateFilePopover/CreateFilePopover";
import useUploadedProjectFiles from "./FileQueries/useUploadedProjectFiles";
import "./styles/project-files.css";

// Component to display uploaded files
const UploadedFiles = ({ projectId, queryClient, userType }) => {
  const [openSettings, setOpenSettings] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    id: "",
    name: "",
    url: "",
    adminUpload: false,
  });
  const [files, setFiles] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  // Query to get the uploaded files
  const { data: uploadedData, isFetched: uploadedIsFetched } =
    useUploadedProjectFiles(projectId, userType);

  // Mutation to delete a file
  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: async (data) => {
      const { fileId, category } = data;
      const res = await axios.delete(
        `/api/delete-file/${projectId}/${category}/${fileId}/`,
        {
          withCredentials: true,
        },
      );
      return res;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["uploaded-project-files", projectId, userType],
      });
      queryClient.invalidateQueries({
        queryKey: ["required-project-files", projectId, userType],
      });
    },
    onError: (error) => {
      console.error("DELETE FILE ERROR: ", error);
      enqueueSnackbar(`Error deleting file: ${error.message}`, {
        variant: "error",
      });
    },
    onSuccess: () => {
      enqueueSnackbar("File deleted successfully", {
        variant: "success",
      });

      setSelectedFile(() => ({
        id: "",
        name: "",
        url: "",
        adminUpload: false,
      }));
      setOpenSettings(false);
    },
  });

  // Set the active file
  useEffect(() => {
    if (openSettings && selectedFile.id && selectedFile.name) {
      const currentFile = document.getElementById(
        `${selectedFile.name}-${selectedFile.id}`,
      );

      if (currentFile && !currentFile.classList.contains("active-file")) {
        // add active class to the selected file
        currentFile.classList.add("active-file");
      }
    } else {
      // find the active file and remove the active class
      const activeFiles = document.getElementsByClassName("active-file");
      if (activeFiles.length > 0) {
        for (let i = 0; i < activeFiles.length; i++) {
          activeFiles[i].classList.remove("active-file");
        }
      }
    }
  }, [openSettings, selectedFile]);

  // Filter out the swpppNarrative file if not admin
  useEffect(() => {
    if (uploadedIsFetched && uploadedData?.files.length > 0) {
      setFiles(() => uploadedData.files);
    }
  }, [uploadedIsFetched, uploadedData]);

  return (
    <div className="file-type-container">
      <div
        className="file-settings-container"
        style={{
          visibility: openSettings ? "visible" : "hidden",
        }}
      >
        <ButtonGroup
          className="uploaded-file-button-group"
          variant="contained"
          aria-label="Uploaded File Button Group"
          disabled={!openSettings}
          classes={{ root: "uploaded-file-group-root" }}
        >
          <Button
            className="file-settings-button view-file-button"
            id="view-file-button"
            onClick={(e) => {
              e.preventDefault();
              window.open(selectedFile.url, "_blank");
            }}
            disabled={!openSettings}
          >
            View
          </Button>
          <Button
            className="file-settings-button delete-file-button"
            id="delete-file-button"
            onClick={async (e) => {
              e.preventDefault();
              enqueueSnackbar("Deleting file...", {
                variant: "info",
                autoHideDuration: 2000,
              });
              await deleteFile({
                category: selectedFile.name,
                fileId: selectedFile.id,
              });
            }}
            disabled={!openSettings}
          >
            Delete
          </Button>
        </ButtonGroup>
      </div>
      <div className="files-container">
        {uploadedIsFetched && uploadedData?.files.length > 0
          ? files.map((file) => {
              let fileExtension = file.fileName.split(".").pop();
              return (
                <div
                  key={`${file.name}_${file.id}`}
                  className={`file-container uploaded`}
                  id={`${file.name}-${file.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const activeFiles =
                      document.getElementsByClassName("active-file");
                    if (activeFiles.length > 0) {
                      for (let i = 0; i < activeFiles.length; i++) {
                        activeFiles[i].classList.remove("active-file");
                      }
                    }
                    e.currentTarget.classList.add("active-file");
                    const fileInfo = {
                      id: file.id,
                      name: file.name,
                      url: file.url,
                      adminUpload: file.adminUpload,
                    };
                    setSelectedFile(() => fileInfo);
                    setOpenSettings(true);
                  }}
                >
                  {file.adminUpload ? (
                    <>
                      <p className="file-category">{file.displayName}</p>
                      <SvgIcon className="file-icon" inheritViewBox>
                        <ArgusFileIcon
                          extension={fileExtension}
                          fileName={file.fileName}
                          type="admin"
                          uniqueId={file.id}
                          {...customStyles["admin"]}
                        />
                      </SvgIcon>
                      <p className="file-name">{file.displayName}</p>
                    </>
                  ) : !file.adminUpload ? (
                    <>
                      <p className="file-category">{file.displayName}</p>
                      <SvgIcon className="file-icon" inheritViewBox>
                        <FileTypeIcon
                          extension={fileExtension}
                          className="styled-file-icon"
                          {...customStyles[`${fileExtension}`]}
                        />
                      </SvgIcon>
                      <p className="file-name">{file.fileName.split(".")[0]}</p>
                    </>
                  ) : null}
                </div>
              );
            })
          : null}
        <IconButton
          id="upload-file-icon-btn"
          onClick={() => setOpenAddFile(true)}
        >
          <AddIcon className="new-file-icon" id="add-icon" />
        </IconButton>
      </div>
      <CreateFilePopover
        open={openAddFile}
        setOpen={setOpenAddFile}
        projectId={projectId}
        queryClient={queryClient}
        userType={userType}
      />
      {selectedFile && openSettings && (
        <ClickAwayListener
          onClickAway={(e) => {
            e.preventDefault();
            if (
              e.target.className.includes("file-settings-container") ||
              e.target.className.includes("file-settings-button") ||
              e.target.className.includes("uploaded-file-button-group") ||
              openSettings === false
            ) {
              return;
            }
            if (!e.target.className.includes("active-file")) {
              setOpenSettings(() => false);
              setSelectedFile(() => ({
                id: "",
                name: "",
                url: "",
                adminUpload: false,
              }));
            }
          }}
        >
          <div className="click-away-area" />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default UploadedFiles;
