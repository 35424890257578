import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import FormButtons from "./FormBtns";
import SavedIndicator from "./SavedIndicator/SavedIndicator";
import AdditionalInformation from "./Slides/AdditionalInformation";
import DustServices from "./Slides/DustServices";
import FileSlide from "./Slides/FileSlide";
import GCInformation from "./Slides/GCInformation";
import GeneralInfo from "./Slides/GeneralInfo";
import IntroSlide from "./Slides/IntroSlide";
import OwnerInformation from "./Slides/OwnerInformation";
import ProjectInformation from "./Slides/ProjectInformation";
import ProjectLocation from "./Slides/ProjectLocation";
import ProjectType from "./Slides/ProjectType";
import SwpppServices from "./Slides/SwpppServices";
import { FormContext } from "./context/FormProvider";
import "./styles/form-container.css";

const Slides = ({
  slideStatus,
  setSlideStatus,
  formState,
  formStateLoaded,
}) => {
  const { pageNumber, isLoading, timeStamp } = useContext(FormContext);
  const { projectId } = useContext(DashboardContext);
  const slides = {
    0: <IntroSlide setSlideStatus={setSlideStatus} />,
    1: (
      <GeneralInfo
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide1"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    2: (
      <SwpppServices
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide2"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    3: (
      <ProjectType
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide3"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    4: (
      <ProjectInformation
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide4"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    5: (
      <ProjectLocation
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide5"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    6: (
      <OwnerInformation
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide6"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    7: (
      <GCInformation
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide7"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    8: (
      <DustServices
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide8"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    9: (
      <FileSlide
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide9"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
    10: (
      <AdditionalInformation
        setSlideStatus={setSlideStatus}
        slideState={formStateLoaded ? formState["slide10"] : null}
        formStateLoaded={formStateLoaded}
      />
    ),
  };

  const slideName = {
    0: "Argus Services Questionnaire",
    1: "Initial Information",
    2: "SWPPP Services",
    3: "Project Type",
    4: "Project Information",
    5: "Project Location",
    6: "Owning Entity Information",
    7: "Operator/General Contractor Information",
    8: "Dust Control Services",
    9: "Upload Files",
    10: "Additional Information",
    // 10: "Submitter Information",
  };

  const optionalParagraphs = {
    0: (
      <p className="form-container-p" id="intro-p">
        Please answer the following questions as thoroughly as possible. Some
        fields in this form are required for completion and will be surrounded
        by a red outline. When selecting certain options, some fields may become
        required and surrounded by a red outline. Optional fields will not have
        a surrounding outline. You will be unable to continue to the next page
        until all required fields are filled out.
      </p>
    ),
    1: (
      <p className="form-container-p" id="general-info-p">
        To begin filling out this request form, please enter in the name of the
        project and confirm Argus has sent a proposal for this project.{" "}
        <strong className="form-container-p" id="general-info-bold">
          In order to save this slide, you must click the next button. All other
          slides will save automatically when completing a question.
        </strong>
      </p>
    ),
    // 2: (
    //   <p className="important-text">
    //     **If you are not requesting any SWPPP services you may continue on to
    //     the next page**
    //   </p>
    // ),
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: (
      <p className="important-text">
        **If you are not requesting any Dust Control Services you may continue
        on to the next page**
      </p>
    ),
    9: null,
    10: null,
  };
  return (
    <Box
      component={"form"}
      id="form-container"
      onSubmit={(e) => e.preventDefault()}
    >
      <header className="form-container-header">
        <h1 className="form-container-heading">{slideName[pageNumber]}</h1>
      </header>
      {optionalParagraphs[pageNumber]}
      <div className={`form-slides`} id={`slide${pageNumber}`}>
        {slides[pageNumber]}
      </div>
      {pageNumber === 0 || !projectId ? null : (
        <SavedIndicator
          timeStamp={timeStamp ? timeStamp : null}
          isLoading={isLoading}
        />
      )}
      <FormButtons slideStatus={slideStatus} />
    </Box>
  );
};

export default Slides;
