import Container from "@mui/material/Container";
import React, { useContext, useEffect } from "react";
import { DashboardContext } from "../DashboardContext/DashboardContextProvider";
import AdminRequests from "./AdminRequests";
import "./styles/admin-portal.css";
/**
 * AdminPortal Component
 *
 * @description Acts as the outer container for the AdminRequests component.
 * @see {@linkcode AdminRequests}
 * @returns {JSX.Element} The rendered AdminPortal component.
 */
const AdminPortal = () => {
  const { setHeading } = useContext(DashboardContext);
  // Update Appbars heading to "Argus Dashboard" when the component mounts
  useEffect(() => {
    setHeading("Argus Dashboard");
  }, []);

  return (
    <Container id="admin-portal-container" sx={{ width: "100%" }}>
      <AdminRequests />
    </Container>
  );
};

export default AdminPortal;
