import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import FileField from "../Inputs/FileField.jsx";
import { FormContext } from "../context/FormProvider";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/fileslide.css";

// Slide 9: FileSlide
const FileSlide = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide2,
    slide4,
    slide9,
    setSlide9,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  const [isUploading, setIsUploading] = useState(false);

  const slide9Mutation = useSetSlide(
    clientId,
    projectId,
    9,
    setIsLoading,
    setSlideStatus,
  );

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "9",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <FileField
            idProp={"dustPermit"}
            label={"Dust Permit"}
            state={slide9}
            setState={setSlide9}
            disabledInput={disabledInputCheck("dustPermit", disabledInputs)}
            disabledDustPermitNumber={disabledInputCheck(
              "dustPermitNumber",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide9.dustPermit.status}
            debouncedSave={slide9Mutation}
            invalidData={getInvalidData(
              "dustPermitNumber",
              invalidInputsData,
              "9",
            )}
          />
          <FileField
            idProp={"gradingPlan"}
            label={"Grading Plans & Drainage Plans"}
            state={slide9}
            setState={setSlide9}
            disabledInput={disabledInputCheck("gradingPlan", disabledInputs)}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide9.gradingPlan.status}
            debouncedSave={slide9Mutation}
          />
          <FileField
            idProp={"swmpPlan"}
            label={"Existing SWMP/SWPPP Site Plan?"}
            state={slide9}
            setState={setSlide9}
            disabledInput={disabledInputCheck("swmpPlan", disabledInputs)}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide9.swmpPlan.status}
            debouncedSave={slide9Mutation}
          />
          <FileField
            idProp={"constructionSchedule"}
            label={"Construction Schedule"}
            state={slide9}
            setState={setSlide9}
            disabledInput={disabledInputCheck(
              "constructionSchedule",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide9.constructionSchedule.status}
            debouncedSave={slide9Mutation}
          />
          <FileField
            idProp={"projectSpecifications"}
            label={"Project Specifications"}
            state={slide9}
            setState={setSlide9}
            disabledInput={disabledInputCheck(
              "projectSpecifications",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide9.projectSpecifications.status}
            debouncedSave={slide9Mutation}
          />
          {slide4.distAcr && +slide4.distAcr > 1 && (
            <>
              <FileField
                idProp={"landscapePlan"}
                label={"Landscape Plans"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "landscapePlan",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.landscapePlan.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"drainageReport"}
                label={"Drainage Report"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "drainageReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.drainageReport.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"soilsReport"}
                label={"Geotechnical/Soils Report"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "soilsReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.soilsReport.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"environmentalReport"}
                label={"Phase I or II Environmental Report"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "environmentalReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.environmentalReport.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"endangeredSpeciesReport"}
                label={"Biological or Endangered Species Report"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "endangeredSpeciesReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.endangeredSpeciesReport.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"archeologicalReport"}
                label={"Archeological Report"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "archeologicalReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.archeologicalReport.status}
                debouncedSave={slide9Mutation}
              />
              <FileField
                idProp={"corpOfEngineersReport"}
                label={"Corp Of Engineers 404 Report and Permit"}
                state={slide9}
                setState={setSlide9}
                disabledInput={disabledInputCheck(
                  "corpOfEngineersReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide9.corpOfEngineersReport.status}
                debouncedSave={slide9Mutation}
              />
              {slide1.onNativeLand && (
                <FileField
                  idProp={"listOfSubcontractors"}
                  label={"List of Subcontractors"}
                  state={slide9}
                  setState={setSlide9}
                  disabledInput={disabledInputCheck(
                    "listOfSubcontractors",
                    disabledInputs,
                  )}
                  setIsLoading={setIsLoading}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  val={slide9.listOfSubcontractors.status}
                  debouncedSave={slide9Mutation}
                />
              )}
            </>
          )}
          {!slide2.inspectionRequest && (
            <FileField
              idProp={"inspectorQualifications"}
              label={"SWPPP Inspector Qualifications"}
              state={slide9}
              setState={setSlide9}
              disabledInput={disabledInputCheck(
                "inspectorQualifications",
                disabledInputs,
              )}
              setIsLoading={setIsLoading}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              val={slide9.inspectorQualifications.status}
              debouncedSave={slide9Mutation}
            />
          )}
        </>
      ) : (
        <p>Loading Page</p>
      )}
    </>
  );
};

export default FileSlide;
