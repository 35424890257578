import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  useConfirmFileUpload,
  useGetPresignedUrl,
  useUploadToS3,
} from "../FileQueries/uploadFileQueries";
import "./styles/create-file-popover.css";

// Create a new file popover
const CreateFilePopover = ({
  open,
  setOpen,
  projectId,
  queryClient,
  userType,
}) => {
  const { isAdmin, clientId } = useContext(DashboardContext);
  const [newFileState, setNewFileState] = useState({
    name: "",
    fileStatus: "AT",
    file: null,
  });
  const newFileRef = useRef(null);
  const existingFileRef = useRef(null);
  const [showNameInput, setShowNameInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const getPresignedUrl = useGetPresignedUrl();
  const uploadToS3 = useUploadToS3();
  const confirmFileUpload = useConfirmFileUpload();

  const { enqueueSnackbar } = useSnackbar();

  const { data: missingData, isFetched: missingDataFetched } = useQuery({
    queryKey: ["required-project-files", projectId, userType],
    queryFn: async () => {
      const res = await axios.get(
        `/api/required-project-files/${projectId}/${userType}/`,
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    enabled: !!projectId,
  });

  const handleUploadFile = async (file) => {
    try {
      const fileName = file.name;
      const fileType = file.type;
      const category = newFileState.name;

      const presignedUrl = await getPresignedUrl.mutateAsync({
        clientId,
        projectId,
        category,
        fileName,
        fileType,
      });

      const { presignedPost, filePath } = presignedUrl.data;

      await uploadToS3.mutateAsync({
        presignedPost,
        file,
      });

      await confirmFileUpload.mutateAsync({
        clientId,
        projectId,
        category,
        filePath,
        fileName,
      });

      enqueueSnackbar(`${fileName} uploaded successfully`, {
        variant: "success",
      });
    } catch (error) {
      console.log("UPLOAD FILE ERROR: ", error);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = Array.from(e.dataTransfer.files);
    if (uploadedFiles.length === 0) return;

    for (const file of uploadedFiles) {
      enqueueSnackbar(`Uploading ${file.name}`, {
        variant: "info",
        autoHideDuration: 1500,
      });
      await handleUploadFile(file);
    }
  };

  const handleFileSelect = async (e) => {
    const uploadedFiles = Array.from(e.target.files);

    if (uploadedFiles.length === 0) return;

    for (const file of uploadedFiles) {
      enqueueSnackbar(`Uploading ${file.name}`, {
        variant: "info",
        autoHideDuration: 1500,
      });
      await handleUploadFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrageEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    setNewFileState({
      name: "",
      fileStatus: "AT",
      file: null,
    });
    setSelectedFile("");
    setShowNameInput(false);
    setOpen(false);
  };

  return (
    <Modal id="create-file-modal" open={open} onClose={handleClose}>
      <div
        id="create-file-form"
        style={
          showNameInput
            ? {
                height: "570px",
                maxHeight: "570px",
              }
            : {
                height: "500px",
                maxHeight: "500px",
              }
        }
      >
        <h1 id="create-file-heading">Upload New File</h1>
        <hr id="create-file-hr" />
        <p id="create-file-p">
          {
            "If the file you are looking for is not listed, please select 'Upload New File', and fill out the form below to upload the file."
          }
        </p>
        {missingDataFetched && missingData?.files.length > 0 && (
          <FormControl
            id="missing-files-form"
            classes={{ root: "missing-files-form-root" }}
          >
            <InputLabel
              id="select-missing-files-label"
              classes={{ shrink: "missing-files-label-shrink" }}
              htmlFor="missing-files-select"
            >
              Select File
            </InputLabel>
            <Select
              id="missing-files-select"
              className="missing-files-select"
              variant="standard"
              value={selectedFile}
              SelectDisplayProps={{ className: "select-display" }}
              MenuProps={{
                classes: { paper: "select-paper", list: "select-list" },
              }}
              classes={{
                root: "files-select-root",
                select: "files-select",
                icon: "files-select-icon",
                nativeInput: "files-select-native-input",
              }}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value === 0) {
                  setSelectedFile(() => "");
                  setShowNameInput(false);
                  setNewFileState({
                    name: "",
                    fileStatus: "AT",
                    file: null,
                  });
                } else if (e.target.value === -1) {
                  setShowNameInput(() => true);
                  setNewFileState({
                    name: "miscellaneous",
                    fileStatus: "AT",
                    file: null,
                  });
                  setSelectedFile(-1);
                } else {
                  setShowNameInput(() => false);
                  const file = missingData.files.find(
                    (f) => f.id === e.target.value,
                  );
                  setSelectedFile(file.id);
                  setNewFileState({
                    name: file.name,
                    fileStatus: "AT",
                    file: null,
                  });
                }
              }}
            >
              <MenuItem
                key="clear-files-select"
                className="files-select-item"
                id="clear-files-item"
                classes={{
                  root: "files-select-item-root",
                  selected: "files-select-item-selected",
                  divider: "files-select-item-divider",
                }}
                value={0}
                divider
              >
                Clear Selection
              </MenuItem>
              <MenuItem
                key="other-files-item"
                className="files-select-item"
                id="other-files-item"
                classes={{
                  root: "files-select-item-root",
                  selected: "files-select-item-selected",
                  divider: "files-select-item-divider",
                }}
                value={-1}
                divider
              >
                Other
              </MenuItem>
              {missingData.files.map((file) => (
                <MenuItem
                  key={file.id}
                  className="files-select-item"
                  classes={{
                    root: "files-select-item-root",
                    selected: "files-select-item-selected",
                    divider: "files-select-item-divider",
                  }}
                  value={file.id}
                  divider
                >
                  {file.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <div
          id="drag-files-box-container"
          className={!selectedFile ? "hide-container" : ""}
        >
          <div
            id="drag-files-box"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDrageEnter}
            onDragLeave={handleDragLeave}
            onClick={() => newFileRef.current.click()}
          >
            <UploadIcon id="upload-new-icon" className="upload-new-icon" />
            <span className="drag-files-text" id="drag-files-text">
              <u>Click to select</u> or Drag & Drop files here
            </span>
            <input
              type="file"
              id="new-file-input"
              hidden
              multiple
              key={selectedFile}
              onChange={handleFileSelect}
              ref={existingFileRef}
              disabled={showNameInput}
            />
          </div>
        </div>
        <div id="new-files-btn-container">
          <Button
            className="cancel-new-file-btn"
            id="cancel-new-file-btn"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFilePopover;
