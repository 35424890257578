import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper.js";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import SelectField from "../Inputs/SelectField.jsx";
import TextField from "../Inputs/TextField.jsx";
import TitleField from "../Inputs/TitleField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 6: Owner Information
const OwnerInformation = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide4,
    slide6,
    setSlide6,
    slide7,
    setSlide7,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    regulatoryAgencies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide6);
  }, []);

  // Update Slide State
  const slide6Mutation = useSetSlide(
    clientId,
    projectId,
    6,
    setIsLoading,
    setSlideStatus,
  );
  // GET INVALID INPUTS
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Autofill Regulatory Agency Information if applicable
  useEffect(() => {
    if (disableAll) return;
    if (slide6.oName === "" || slide6.oName.length < 4) return;
    for (const agency in regulatoryAgencies) {
      if (
        agency === slide6.oName &&
        slide6.oPhone === "" &&
        slide6.oAdd === "" &&
        slide6.oCity === "" &&
        slide6.oZip === ""
      ) {
        let newState = {
          ...slide6,
          oPhone: regulatoryAgencies[agency].phone,
          oAdd: regulatoryAgencies[agency].address,
          oCity: regulatoryAgencies[agency].city,
          oZip: regulatoryAgencies[agency].zip,
        };
        setSlide6(newState);
        slide6Mutation.mutate(newState);
      }
    }
  }, [slide6.oName]);

  // Populate Invalid Inputs

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "6",
    );
  }, [invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <p className="general-info">
            Please provide the following information about the projects owning
            entity. All fields should match exactly as registered with the AZCC
            when forming the entity
          </p>
          <TextField
            idProp="oName"
            label="Company Name"
            state={slide6}
            setState={setSlide6}
            val={slide6.oName}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? companies : []}
            // req={slide6.oName === "" || slide6.oName.length < 3}
            disabledInput={disabledInputCheck("oName", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oName", disabledInputs) === false
                ? getInputMessage("oName", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oName", invalidInputsData, "6")}
          />
          <TextField
            idProp="oAdd"
            label="Street Address"
            state={slide6}
            setState={setSlide6}
            val={slide6.oAdd}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            // req={slide6.oAdd === "" || slide6.oAdd.length < 6}
            disabledInput={disabledInputCheck("oAdd", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oAdd", disabledInputs) === false
                ? getInputMessage("oAdd", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oAdd", invalidInputsData, "6")}
          />
          <TextField
            idProp="oCity"
            label="City"
            state={slide6}
            setState={setSlide6}
            val={slide6.oCity}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            // req={slide6.oCity === "" || slide6.oCity.length < 3}
            disabledInput={disabledInputCheck("oCity", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oCity", disabledInputs) === false
                ? getInputMessage("oCity", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oCity", invalidInputsData, "6")}
          />
          <SelectField
            idProp="oState"
            label="State"
            state={slide6}
            setState={setSlide6}
            val={slide6.oState}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("oState", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oState", disabledInputs) === false
                ? getInputMessage("oState", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oState", invalidInputsData, "6")}
            type="state"
          />
          <TextField
            idProp="oZip"
            label="Zip"
            state={slide6}
            setState={setSlide6}
            val={slide6.oZip}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            // req={slide6.oZip === "" || slide6.oZip.length !== 5}
            disabledInput={disabledInputCheck("oZip", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oZip", disabledInputs) === false
                ? getInputMessage("oZip", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oZip", invalidInputsData, "6")}
          />
          <PhoneField
            idProp="oPhone"
            label="Office Phone"
            state={slide6}
            setState={setSlide6}
            val={slide6.oPhone}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            req={!isValidPhoneNumber(slide6.oPhone)}
            disabledInput={disabledInputCheck("oPhone", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oPhone", disabledInputs) === false
                ? getInputMessage("oPhone", invalidInputsData, "6")
                : ""
            }
            invalidData={getInvalidData("oPhone", invalidInputsData, "6")}
          />
          <RadioGroupField
            idProp="ownerGCCheck"
            label="Will the Owner also be the General Contractor?"
            state={slide6}
            setState={setSlide6}
            slide7={slide7}
            setSlide7={setSlide7}
            val={slide6.ownerGCCheck}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("ownerGCCheck", disabledInputs)}
          />
          {+slide4.distAcr >= 1 ? (
            <>
              <p className="general-info">
                {
                  "Please provide the contact information of the individual who has the authority to make legal decisions on behalf of the owning entity"
                }
              </p>
              <TextField
                idProp="oContactName"
                label="Owner's Responsible Officer"
                state={slide6}
                setState={setSlide6}
                val={slide6.oContactName}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? names : []}
                type="name"
                req={!isValidName(slide6.oContactName)}
                disabledInput={disabledInputCheck(
                  "oContactName",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactName", disabledInputs) === false
                    ? getInputMessage("oContactName", invalidInputsData, "6")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactName",
                  invalidInputsData,
                  "6",
                )}
              />
              <TitleField
                idProp="oContactTitle"
                label="Responsible Officer's Title"
                state={slide6}
                setState={setSlide6}
                val={slide6.oContactTitle}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? titles : []}
                type="title"
                // req={
                //   slide6.oContactTitle === "" || slide6.oContactTitle.length < 3
                // }
                disabledInput={disabledInputCheck(
                  "oContactTitle",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactTitle", disabledInputs) === false
                    ? getInputMessage("oContactTitle", invalidInputsData, "6")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactTitle",
                  invalidInputsData,
                  "6",
                )}
              />
              <PhoneField
                idProp="oContactPhone"
                label="Responsible Officer's Phone"
                state={slide6}
                setState={setSlide6}
                val={slide6.oContactPhone}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                req={!isValidPhoneNumber(slide6.oContactPhone)}
                disabledInput={disabledInputCheck(
                  "oContactPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactPhone", disabledInputs) === false
                    ? getInputMessage("oContactPhone", invalidInputsData, "6")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactPhone",
                  invalidInputsData,
                  "6",
                )}
              />
              <EmailField
                idProp="oContactEmail"
                label="Responsible Officer's Email"
                state={slide6}
                setState={setSlide6}
                val={slide6.oContactEmail}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? emails : []}
                req={!isValidEmail(slide6.oContactEmail)}
                disabledInput={disabledInputCheck(
                  "oContactEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactEmail", disabledInputs) === false
                    ? getInputMessage("oContactEmail", invalidInputsData, "6")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactEmail",
                  invalidInputsData,
                  "6",
                )}
              />
              <RadioGroupField
                idProp="delegatedRepCheck"
                label="Delegated Authority"
                state={slide6}
                setState={setSlide6}
                val={slide6.delegatedRepCheck}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "delegatedRepCheck",
                  disabledInputs,
                )}
              />
              {slide6.delegatedRepCheck ? (
                <>
                  <p className="general-info">
                    {
                      "Please provide the contact information of the individual who has been delegated the authority to make legal decisions and act on behalf of the responsible officer "
                    }
                  </p>
                  <TextField
                    idProp="odrCompany"
                    label="Delegated Company Name"
                    state={slide6}
                    setState={setSlide6}
                    val={slide6.odrCompany}
                    debouncedSave={slide6Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? companies : []}
                    type="company"
                    // req={
                    //   slide6.delegatedRepCheck &&
                    //   (slide6.odrCompany === "" || slide6.odrCompany.length < 3)
                    // }
                    disabledInput={disabledInputCheck(
                      "odrCompany",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrCompany", disabledInputs) === false
                        ? getInputMessage("odrCompany", invalidInputsData, "6")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrCompany",
                      invalidInputsData,
                      "6",
                    )}
                  />
                  <TextField
                    idProp="odrName"
                    label="Delegated Representative"
                    state={slide6}
                    setState={setSlide6}
                    val={slide6.odrName}
                    debouncedSave={slide6Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    type="name"
                    req={
                      slide6.delegatedRepCheck && !isValidName(slide6.odrName)
                    }
                    disabledInput={disabledInputCheck(
                      "odrName",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrName", disabledInputs) === false
                        ? getInputMessage("odrName", invalidInputsData, "6")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrName",
                      invalidInputsData,
                      "6",
                    )}
                  />
                  <TitleField
                    idProp="odrTitle"
                    label="Representative's Title"
                    state={slide6}
                    setState={setSlide6}
                    val={slide6.odrTitle}
                    debouncedSave={slide6Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? titles : []}
                    type="title"
                    // req={
                    //   slide6.delegatedRepCheck &&
                    //   (slide6.odrTitle === "" || slide6.odrTitle.length < 3)
                    // }
                    disabledInput={disabledInputCheck(
                      "odrTitle",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrTitle", disabledInputs) === false
                        ? getInputMessage("odrTitle", invalidInputsData, "6")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrTitle",
                      invalidInputsData,
                      "6",
                    )}
                  />
                  <PhoneField
                    idProp="odrPhone"
                    label="Representative's Phone"
                    state={slide6}
                    setState={setSlide6}
                    val={slide6.odrPhone}
                    debouncedSave={slide6Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? phoneNumbers : []}
                    req={
                      slide6.delegatedRepCheck &&
                      !isValidPhoneNumber(slide6.odrPhone)
                    }
                    disabledInput={disabledInputCheck(
                      "odrPhone",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrPhone", disabledInputs) === false
                        ? getInputMessage("odrPhone", invalidInputsData, "6")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrPhone",
                      invalidInputsData,
                      "6",
                    )}
                  />
                  <EmailField
                    idProp="odrEmail"
                    label="Representative's Email"
                    state={slide6}
                    setState={setSlide6}
                    val={slide6.odrEmail}
                    debouncedSave={slide6Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide6.delegatedRepCheck && !isValidEmail(slide6.odrEmail)
                    }
                    disabledInput={disabledInputCheck(
                      "odrEmail",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrEmail", disabledInputs) === false
                        ? getInputMessage("odrEmail", invalidInputsData, "6")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrEmail",
                      invalidInputsData,
                      "6",
                    )}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <TextField
                idProp="wsNumber"
                label="WS Number"
                state={slide6}
                setState={setSlide6}
                val={slide6.wsNumber}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck("wsNumber", disabledInputs)}
                disabledInputMessage={
                  disabledInputCheck("wsNumber", disabledInputs) === false
                    ? getInputMessage("wsNumber", invalidInputsData, "6")
                    : ""
                }
                invalidData={getInvalidData("wsNumber", invalidInputsData, "6")}
              />
              <TextField
                idProp="waterServicesContact"
                label="Water Services Contact Name"
                state={slide6}
                setState={setSlide6}
                val={slide6.waterServicesContact}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? names : []}
                type="name"
                disabledInput={disabledInputCheck(
                  "waterServicesContact",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesContact", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesContact",
                        invalidInputsData,
                        "6",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesContact",
                  invalidInputsData,
                  "6",
                )}
              />
              <PhoneField
                idProp="waterServicesPhone"
                label="Water Services Contact Phone"
                state={slide6}
                setState={setSlide6}
                val={slide6.waterServicesPhone}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "waterServicesPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesPhone", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesPhone",
                        invalidInputsData,
                        "6",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesPhone",
                  invalidInputsData,
                  "6",
                )}
              />
              <EmailField
                idProp="waterServicesEmail"
                label="Water Services Contact Email"
                state={slide6}
                setState={setSlide6}
                val={slide6.waterServicesEmail}
                debouncedSave={slide6Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "waterServicesEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesEmail", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesEmail",
                        invalidInputsData,
                        "6",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesEmail",
                  invalidInputsData,
                  "6",
                )}
              />
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default OwnerInformation;
