import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const fetchRequiredProjectFiles = async (projectId, userType) => {
  const res = await axios.get(
    `/api/required-project-files/${projectId}/${userType}/`,
    {
      withCredentials: true,
    },
  );
  return res.data;
};

const useRequiredProjectFiles = (projectId, userType) => {
  return useQuery({
    queryKey: ["required-project-files", projectId, userType],
    queryFn: () => fetchRequiredProjectFiles(projectId, userType),
    enabled: !!projectId,
  });
};

export default useRequiredProjectFiles;
