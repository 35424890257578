import React from "react";
import "./styles/invite-new-user.css";
import "./styles/send-custom-invite.css";

// Component that displays a visual representation of the email body that will be sent to the user
/**
 * EmailBody Component
 * @description Component that displays a visual representation of the email body that will be sent to the user
 * @param {Object} props - Component props
 * @param {string} props.message - The custom message to be sent to the user
 * @param {Function} props.setMessage - Function to set the custom message
 * @param {string} props.defaultMessage - The default message to be sent to the user
 * @param {string} props.clientCode - The client code to be sent to the user
 */
const EmailBody = ({ message, setMessage, defaultMessage, clientCode }) => {
  return (
    <div
      className="es-wrapper-color"
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        gap: "10px",
        margin: "0",
        padding: "0",
        backgroundColor: "rgb(245, 245, 245)",
      }}
    >
      <div
        className="es-wrapper"
        style={{
          maxWidth: "95%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          margin: "0",
        }}
      >
        <div
          className="es-header"
          style={{ width: "100%", margin: "0", padding: "5px 0" }}
        >
          <div
            className="es-header-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "transparent",
              margin: "0",
              padding: "0",
            }}
          >
            <a
              href="https://www.arguscsaz.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                height: "70px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://arguscs.com/wp-content/uploads/2023/08/argusfinal.png"
                alt="Argus Logo"
                title="Argus Logo"
                height="60"
                className="adapt-img"
                style={{ margin: "0", padding: "0" }}
              />
            </a>
          </div>
        </div>
        <div
          className="es-content"
          style={{
            width: "98%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0",
            padding: "0",
            gap: "10px",
          }}
        >
          <div
            className="es-m-txt-c es-text-2442"
            style={{ textAlign: "center" }}
          >
            <h2
              className="es-text-mobile-size-36"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                margin: "0",
                padding: "0",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
              }}
            >
              Access Granted
            </h2>
          </div>

          <div
            className="es-m-p0r es-m-p0l"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "95%",
              margin: "0",
              padding: "0 !important",
            }}
          >
            <p
              style={{
                fontSize: "14px",
                margin: "0",
                padding: "0",
                width: "95%",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                color: "var(--input-text-color)",
                fontWeight: "500",
              }}
            >
              {defaultMessage}
            </p>
          </div>

          <div
            className="es-m-txt-c es-text-2442"
            style={{ textAlign: "center" }}
          >
            <h2
              className="es-text-mobile-size-36"
              style={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                margin: "0",
                padding: "0",
              }}
            >
              Message from Argus
            </h2>
          </div>

          <div
            className="es-m-p0r es-m-p0l"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
            }}
          >
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter personalized message here, or leave blank to only send the access granted message above."
              style={{
                width: "95%",
                maxHeight: "200px",
                minHeight: "100px",
                padding: "8px",
                borderRadius: "5px",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "var(--default-font-weight)",
                color: "var(--input-text-color)",
                resize: "none",
              }}
              value={message}
            />
          </div>
          <div
            className="es-m-txt-c"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "93%",
              height: "fit-content",
              margin: "0",
              padding: "0",
              gap: "10px",
              border: "2px dashed #cccccc",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "22px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "bold",
                margin: "0",
                padding: "0",
              }}
            >
              Code
            </h2>
            <h1
              style={{
                fontSize: "36px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "bold",
                color: "#2d6d87",
                margin: "0",
                padding: "0",
              }}
            >
              {clientCode || "************"}
            </h1>
          </div>
          <div
            className="es-m-txt-c"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
              margin: "0",
              height: "fit-content",
            }}
          >
            <p
              rel="noopener noreferrer"
              className="es-button"
              style={{
                color: "var(--btn-white-text)",
                backgroundColor: "var(--btn-green)",
                padding: "5px 18px",
                margin: "0",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "none",
                display: "inline-block",
                cursor: "not-allowed",
              }}
            >
              Access
            </p>
          </div>
        </div>
      </div>
      <div
        className="es-footer"
        style={{
          width: "100%",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0",
          padding: "0",
        }}
      >
        <div
          className="es-footer-body"
          style={{ maxWidth: "500px", margin: "0", padding: "5px 0" }}
        >
          <div className="es-social" style={{ textAlign: "center" }}>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/facebook-circle-colored.png"
                alt="Facebook"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/linkedin-circle-colored.png"
                alt="LinkedIn"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/tiktok-circle-colored.png"
                alt="TikTok"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/other-icons/circle-colored/phone-circle-colored.png"
                alt="Phone"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="mailto:info@arguscs.com">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/other-icons/circle-colored/mail-circle-colored.png"
                alt="Email"
                width="30"
                height="30"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailBody;
