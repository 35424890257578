import Button from "@mui/material/Button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import ConfirmDeleteUser from "./ConfirmDeleteUser";
import "./styles/existing-users.css";

// Component that displays a list of existing users for the selected client if any exist
/**
 * ExistingUsers Component
 * @description Component that displays a list of existing users for the selected client if any exist
 * @param {Object} props - Component props
 * @param {string} props.clientId - The ID of the selected client
 * @param {string} props.clientName - The name of the selected client
 * @param {boolean} props.existingUsersFetched - Flag indicating if existing users have been fetched
 * @param {Object[]} props.existingUsers - List of existing users for the selected client
 * @param {Function} props.setOpenCustomInvite - Function to set the open state of the custom invite dialog
 * @param {Function} props.setSelectedUser - Function to set the selected user for the custom invite dialog
 * @param {Function} props.enqueueSnackbar - Function to display a snackbar notification
 * @returns {JSX.Element} ExistingUsers component
 */
const ExistingUsers = ({
  clientId,
  clientName,
  existingUsersFetched,
  existingUsers,
  setOpenCustomInvite,
  setSelectedUser,
  enqueueSnackbar,
}) => {
  // Return if no users exist for the selected client
  if (
    !existingUsersFetched ||
    (existingUsers && existingUsers.length === 0) ||
    !clientId
  ) {
    return null;
  }

  const { queryClient } = useContext(DashboardContext);

  const [sortedUsers, setSortedUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  // Sort the existing users by name
  useEffect(() => {
    if (existingUsers) {
      setSortedUsers(
        existingUsers.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [existingUsers]);

  // Delete user mutation
  const deleteUserMutation = useMutation({
    mutationFn: async (userId) => {
      try {
        const res = await axios.delete(
          `/api/client/${clientId}/user/${userId}/`,
          { withCredentials: true },
        );

        return res.data;
      } catch (error) {
        console.log("ERROR DELETING USER: ", error);
      }
    },
    onSuccess: (data) => {
      enqueueSnackbar("User has been successfully deleted", {
        variant: "success",
      });
      setOpenConfirmDelete(false);
      queryClient.invalidateQueries({
        queryKey: ["existing-users", clientId],
        refetchType: "active",
      });
    },
    onError: (error) => {
      console.log("ERROR DELETING USER: ", error);
      enqueueSnackbar("Error deleting user. Please try again.", {
        variant: "error",
      });
    },
  });

  return (
    <>
      <div className="existing-users-container">
        <header id="existing-users-header">
          <h2 id="existing-users-heading">{`${clientName} Users`}</h2>
        </header>
        <div className="existing-users-list">
          {existingUsers.map((user) => (
            <div key={user.id} className="existing-user">
              <p className="user-data" id="users-name">
                {user.name}
              </p>
              <p className="user-data">{user.phone}</p>
              <p className="user-data">{user.email}</p>
              <div className="existing-user-btns">
                <Button
                  id="invite-user-btn"
                  className="invite-user-btn"
                  variant="contained"
                  onClick={() => {
                    setSelectedUser(() => ({
                      id: user.id,
                      name: user.name,
                      email: user.email,
                      title: user.title,
                    }));
                    setOpenCustomInvite(true);
                  }}
                >
                  Invite
                </Button>
                <Button
                  id="delete-user-btn"
                  className="delete-user-btn"
                  variant="contained"
                  onClick={() => {
                    setDeleteUserId(user.id);
                    setOpenConfirmDelete(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ConfirmDeleteUser
        openConfirmDelete={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        deleteUserMutation={deleteUserMutation}
        userId={deleteUserId}
      />
    </>
  );
};

export default ExistingUsers;
