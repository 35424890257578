import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Popover from "@mui/material/Popover";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setProjectID } from "../../../../Redux/Actions/actions";
import "../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import * as defaultStyles from "../../styles/main-datagrid.module.css";
import "../styles/client-data-grid.css";

// | MY PROJECTS FILTER |
// Adds a filter button that returns only rows where row.id is included in savedProjects or all the rows
const GridToolbarMyProjects = ({ initialRows, setRows, savedProjects }) => {
  const [filterActive, setFilterActive] = useState(false);
  // Event handler to update rows based on filterActive state
  const handleFilterMyProjects = () => {
    if (!filterActive) {
      // filter out rows to only include rows where row.id is included in savedProjects
      let filteredRows = initialRows.current.filter((row) =>
        savedProjects.includes(row.id),
      );
      setRows(filteredRows);
    } else {
      setRows(initialRows.current);
    }
    // toggle filterActive state
    setFilterActive(!filterActive);
  };

  return (
    <Button
      id="my-projects-filter"
      onClick={handleFilterMyProjects}
      title={
        filterActive ? "Show All Projects" : "Filter to show only 'My Projects'"
      }
      classes={{ root: "myProjectsFilterRoot" }}
    >
      <FavoriteIcon className="myProjectsIcon" id="myProjectsIcon" />
      {filterActive ? "All Projects" : "My Projects"}
    </Button>
  );
};

// | TOOLBAR |
// Custom toolbar component that includes a search field, filter button, and My Projects filter button
export const CustomToolbar = ({
  value,
  handleSearch,
  filterButtonRef,
  handleClearInput,
  initialRows,
  setRows,
  savedProjects,
}) => {
  return (
    <div className={"mainToolbarContainer"}>
      <GridToolbarContainer className={"toolbarLeft"} id="toolbar-left-client">
        <GridToolbarFilterButton
          className={"toolbarFilter clientToolbarFilter"}
          id="client-toolbar-filter-button"
          ref={filterButtonRef}
          classes={{
            startIcon: "filterButtonStartIcon",
          }}
          slotProps={{
            button: {
              classes: {
                startIcon: "filterButtonStartIcon",
              },
            },
          }}
        />
        {savedProjects && savedProjects.length > 0 && (
          <GridToolbarMyProjects
            initialRows={initialRows}
            setRows={setRows}
            savedProjects={savedProjects}
          />
        )}
      </GridToolbarContainer>
      <div className="toolbarRight clientToolbarRight">
        <FormControl
          variant="outlined"
          id={"search-form-control"}
          classes={{ root: "search-form-control-root" }}
        >
          <OutlinedInput
            type="text"
            id="search-field"
            label="Search Table"
            value={value}
            onChange={handleSearch}
            classes={{
              root: "search-field-root",
              notchedOutline: "search-field-notchedOutline",
              input: "search-field-input",
            }}
            startAdornment={
              <SearchIcon className="search-icon" aria-hidden={true} />
            }
            endAdornment={
              <IconButton
                id="clear-search-btn"
                onClick={handleClearInput}
                classes={{ root: "clear-search-btn-root" }}
              >
                <CloseIcon className="clear-search-icon" />
              </IconButton>
            }
          />
        </FormControl>
      </div>
    </div>
  );
};

// | SERVICES POPOVER |
// Custom popover component that displays a list of services requested for a given project
export function ServicesPopover({ params }) {
  const {
    servicesAnchor,
    setServicesAnchor,
    openServices,
    setOpenServices,
    setCurrServicesRow,
  } = useContext(DashboardContext);
  return (
    <Popover
      id={"servicesPopover"}
      key={params.id}
      open={openServices}
      anchorEl={servicesAnchor}
      onClose={() => {
        setServicesAnchor(null);
        setCurrServicesRow(null);
        setOpenServices(false);
      }}
      slotProps={{
        paper: {
          className: "popoverPaper",
          elevation: 0,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: -55 }}
    >
      <div className={"servicesContainer"}>
        <>
          <h4 className={"servicesPopoverHeading"}>Requested SWPPP Services</h4>
          {params.row.services.swppp.length >= 1 ? (
            <ol className={"servicesList"}>
              {params.row.services.swppp.map((service, index) => {
                return (
                  <li key={index} className={"servicesListItem"}>
                    {service}
                  </li>
                );
              })}
            </ol>
          ) : (
            <p className={"soloListItem"}>{"- No Services Selected"}</p>
          )}
        </>
        <>
          <h4 className={"servicesPopoverHeading"}>
            Requested Dust Control Services
          </h4>
          {params.row.services.dust.length > 1 ? (
            <ol className={"servicesList"}>
              {params.row.services.dust.map((service, index) => {
                return (
                  <li key={index} className={"servicesListItem"}>
                    {service}
                  </li>
                );
              })}
            </ol>
          ) : (
            <p className={"soloListItem"}>{`- No Services Selected`}</p>
          )}
        </>
      </div>
    </Popover>
  );
}

// | NO ROWS OVERLAY |
// Custom overlay component that displays a message and buttons to create a new request or invite a new user
export function NoRowsOverlay() {
  const dispatch = useDispatch();
  const { navigate, clientId, isAdmin } = useContext(DashboardContext);

  // Event handler to navigate to the form page and reset the projectID in Redux store
  const handleCreateRequest = (e) => {
    dispatch(setProjectID(null));
    if (isAdmin) {
      navigate("dashboard/form");
    } else {
      navigate(`/dashboard/form/${clientId}/`);
    }
  };
  return (
    <div className={defaultStyles.noRowsOverlay}>
      <p className={defaultStyles.noRowsOverlayText}>
        {isAdmin
          ? "No requests have been made. Create & invite a user or start a request for a client by clicking the buttons below"
          : "No requests have been made. Click the button below to create your first request"}
      </p>
      <div className={defaultStyles.noRowsOverlayButtonContainer}>
        {isAdmin && (
          <Button
            variant="contained"
            className={defaultStyles.noRowsOverlayButton}
            id={defaultStyles.inviteUserButton}
            onClick={() => navigate("/dashboard/admin/create-invite-user")}
          >
            <SendIcon
              className={defaultStyles.noRowsOverlayButtonIcon}
              id={defaultStyles.sendIcon}
            />
            <span className={defaultStyles.noRowsOverlayButtonText}>
              Invite User
            </span>
          </Button>
        )}
        <Button
          variant="contained"
          className={defaultStyles.noRowsOverlayButton}
          id={defaultStyles.createRequestButton}
          onClick={handleCreateRequest}
        >
          <AddIcon className={defaultStyles.noRowsOverlayButtonIcon} />
          <span className={defaultStyles.noRowsOverlayButtonText}>
            New Request
          </span>
        </Button>
      </div>
    </div>
  );
}
