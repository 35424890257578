import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import useAdminClients from "../../AdminQueries/useAdminClients";
import ExistingUsers from "./ExistingUsers";
import SendCustomInvite from "./SendCustomInvite";
import "./styles/invite-new-user.css";

/**
 * InviteNewUser Component
 * @description Main component that handles creating and inviting new users
 * @returns {JSX.Element} InviteNewUser component
 * */
const InviteNewUser = () => {
  const {
    isAdmin,
    setHeading,
    clientId,
    handleFormatPhone,
    capitalizeNameAfterSpace,
    queryClient,
  } = useContext(DashboardContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => setHeading("Create & Invite User"), [setHeading]);
  const [selectedClient, setSelectedClient] = useState(clientId || "");
  const [clientCode, setClientCode] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phone: "",
    title: "",
  });
  const [openCustomInvite, setOpenCustomInvite] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    name: "",
    email: "",
    title: "",
  });
  const [sortedClients, setSortedClients] = useState([]);
  // Query Function to get all clients
  const {
    data: clientsData,
    isLoading: clientsIsLoading,
    isFetched: clientsIsFetched,
  } = useAdminClients(isAdmin);

  // Query Function to get all existing users for the selected client
  const { data: existingUsers, isFetched: existingUsersFetched } = useQuery({
    queryKey: ["existing-users", selectedClient],
    enabled: !!selectedClient,
    queryFn: async () => {
      try {
        const res = await axios.get(`/api/client/${selectedClient}/users/`, {
          withCredentials: true,
        });
        return res.data;
      } catch (error) {
        console.log("ERROR FETCHING EXISTING USERS: ", error);
      }
    },
  });

  // Mutation to create a new user
  const createNewUserMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const res = await axios.post(
          `/api/admin/new-user/${selectedClient}/`,
          data,
          {
            withCredentials: true,
          },
        );
        return res;
      } catch (error) {
        console.log("ERROR CREATING NEW USER: ", error);
      }
    },
    onSuccess: async (data) => {
      if (data.status === 201) {
        let userData = data.data;
        enqueueSnackbar("New User Created Successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["existing-users", selectedClient],
          refetchType: "active",
        });
        setSelectedUser({
          id: userData.id,
          name: userData.name,
          email: userData.email,
          title: userData.title,
        });
        setOpenCustomInvite(true);
      } else if (data.status === 200) {
        enqueueSnackbar(
          "User already exists. Please make sure the users name and email are unique for each user.",
          { variant: "warning" },
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar("Error Creating New User", { variant: "error" });
    },
  });

  // Event handler to create and email a new user
  const handleCreateAndEmailUser = async (e) => {
    e.preventDefault();

    // Check that the newUser.name field includes a first and last name
    // If not, display an error message and return
    if (newUser.name.split(" ").length < 2) {
      enqueueSnackbar("Please enter a first and last name", {
        variant: "warning",
      });
      return;
    }
    // Use a regex to ensure newUser.email is a valid email address
    // If not, display an error message and return
    if (
      !newUser.email.match(
        new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      )
    ) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "warning",
      });
      return;
    }

    if (newUser.phone.length < 12) {
      enqueueSnackbar("Please enter a valid phone number", {
        variant: "warning",
      });
      return;
    }

    try {
      await createNewUserMutation.mutateAsync(newUser);
    } catch (error) {
      console.log("ERROR CREATING USER: ", error);
    }
  };

  // List of possible titles to suggest for the user
  const titles = [
    "Architect",
    "Area Manager",
    "Contractor",
    "Dust Control Coordinator",
    "Dust Control Manager",
    "Dust Control Supervisor",
    "Dust Monitor",
    "Engineer",
    "Estimator",
    "Foreman",
    "President",
    "Project Administrator",
    "Project Coordinator",
    "Property Developer",
    "Project Director",
    "Project Engineer",
    "Project Executive",
    "Project Manager",
    "Project Supervisor",
    "Regional Director",
    "Regional Manager",
    "Senior Vice President",
    "Site Manager",
    "Superintendent",
    "Vertical Construction Manager",
    "Vice President",
    "Vice President, Building",
    "Vice President, Land",
  ];

  // Sort the clients by name
  useEffect(() => {
    if (clientsData) {
      setSortedClients(
        clientsData.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [clientsData]);

  return (
    <>
      <div className="email-client-container">
        <div id="email-client" className={!selectedClient ? "no-client" : ""}>
          <header id="user-header">
            <h1 id="email-client-heading">Create & Invite New User</h1>
          </header>
          <div className="create-user-container">
            <div className="client-input-container">
              <label className="client-select-label" htmlFor="clients-form">
                Client:
              </label>
              <FormControl
                id="clients-form"
                classes={{ root: "clients-form-root" }}
              >
                <InputLabel
                  id="clients-select-label"
                  classes={{ shrink: "labelShrink" }}
                >
                  Select a Client
                </InputLabel>
                <Select
                  id="clients-select"
                  className="clients-select"
                  variant="standard"
                  value={selectedClient || ""}
                  SelectDisplayProps={{ className: "clients-select-display" }}
                  MenuProps={{
                    className: "clients-select-menu",
                    classes: {
                      list: "clients-select-list",
                      paper: "clients-select-paper",
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value === 0) {
                      setSelectedClient("");
                      setClientCode("");
                    } else {
                      setSelectedClient(value);
                      setClientCode(
                        clientsData.find((client) => client.id === value)
                          .companyCode,
                      );
                    }
                  }}
                  classes={{
                    root: "clients-select-root",
                    select: "clients-select-root-select",
                    icon: "clients-select-root-icon",
                    nativeInput: "clients-select-root-nativeInput",
                  }}
                >
                  <MenuItem
                    key="clear-client"
                    className="clients-select-menu-item"
                    value={0}
                    id="clear-client-item"
                    divider={true}
                    classes={{
                      root: "clients-list-item",
                      selected: "clients-list-item-selected",
                      divider: "clear-client-item-divider",
                    }}
                  >
                    Clear Selection
                    <CloseIcon id="clear-client-icon" />
                  </MenuItem>
                  {clientsIsFetched &&
                    clientsData.map((client, idx) => (
                      <MenuItem
                        key={client.id}
                        className="clients-select-menu-item"
                        value={client.id}
                        id={"client-item"}
                        divider={idx === clientsData.length - 1 ? false : true}
                        classes={{
                          root: "clients-list-item",
                          selected: "clients-list-item-selected",
                          divider: "clients-list-item-divider",
                        }}
                        onMouseOver={async () => {
                          await queryClient.prefetchQuery({
                            queryKey: ["existing-users", client.id],
                            queryFn: async () => {
                              try {
                                const res = await axios.get(
                                  `/api/client/${client.id}/users/`,
                                  {
                                    withCredentials: true,
                                  },
                                );
                                return res.data;
                              } catch (error) {
                                console.log(
                                  "ERROR PREFETCHING EXISTING USERS: ",
                                  error,
                                );
                              }
                            },
                            staleTime: 60000,
                          });
                        }}
                      >
                        {client.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {selectedClient !== "" && (
              <>
                <div className="user-input-container">
                  <label
                    className="create-user-label"
                    htmlFor="new-user-name-form"
                  >
                    First & Last Name:
                  </label>
                  <FormControl
                    id="new-user-name-form"
                    className={
                      newUser.name.split(" ").length < 2 ? "required" : ""
                    }
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-name-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      Add First & Last Name
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-name"
                      className="new-user-name"
                      value={newUser.name}
                      onChange={(e) => {
                        let value = capitalizeNameAfterSpace(e.target.value);
                        setNewUser({ ...newUser, name: value });
                      }}
                      classes={{
                        root: "new-user-name-root",
                        input: "new-user-name-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      autoComplete="off"
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.name === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.name === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, name: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.name === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div
                  className="user-input-container"
                  htmlFor="new-user-email-form"
                >
                  <label className="create-user-label">Email Address:</label>
                  <FormControl
                    id="new-user-email-form"
                    className={
                      !newUser.email.match(
                        new RegExp(
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        ),
                      )
                        ? "required"
                        : ""
                    }
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-email-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      Add Email Address
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-email"
                      className="new-user-email"
                      value={newUser.email}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          email: e.target.value.toLowerCase(),
                        })
                      }
                      autoComplete="off"
                      classes={{
                        root: "new-user-email-root",
                        input: "new-user-email-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.email === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.email === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, email: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.email === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div className="user-input-container">
                  <label
                    className="create-user-label"
                    htmlFor="new-user-phone-form"
                  >
                    Phone Number:
                  </label>
                  <FormControl
                    id="new-user-phone-form"
                    className={newUser.phone.length < 12 ? "required" : ""}
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-phone-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      Add Phone Number
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-phone"
                      className="new-user-phone"
                      value={newUser.phone}
                      autoComplete="off"
                      onChange={(e) => {
                        let value = handleFormatPhone(e.target.value);
                        setNewUser({ ...newUser, phone: value });
                      }}
                      classes={{
                        root: "new-user-phone-root",
                        input: "new-user-phone-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.phone === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.phone === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, phone: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.phone === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div
                  className="user-input-container"
                  htmlFor="new-user-title-form"
                >
                  <label className="create-user-label">Title:</label>
                  <div className="predict-title-container">
                    <FormControl
                      id="new-user-title-form"
                      classes={{ root: "new-user-form-root" }}
                    >
                      <InputLabel
                        id="new-user-title-label"
                        classes={{ shrink: "user-label-shrink" }}
                      >
                        {"Add Title (optional)"}
                      </InputLabel>
                      <OutlinedInput
                        id="new-user-title"
                        className="new-user-title"
                        value={newUser.title}
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowRight") {
                            e.preventDefault();
                            setNewUser({
                              ...newUser,
                              title: placeholder,
                            });
                            setPlaceholder("");
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setPlaceholder("");
                          }
                          setNewUser({
                            ...newUser,
                            title: e.target.value,
                          });
                          let lowerCase = e.target.value.toLowerCase();
                          let match = titles.find((title) => {
                            if (!lowerCase) return false;
                            return title.toLowerCase().startsWith(lowerCase);
                          });

                          if (match) {
                            match =
                              e.target.value + match.slice(lowerCase.length);
                            setPlaceholder(match);
                          } else {
                            setPlaceholder("");
                          }
                        }}
                        classes={{
                          root: "new-user-title-root",
                          input: "new-user-title-input",
                          notchedOutline: "user-notchedOutline",
                        }}
                        endAdornment={
                          <Tooltip
                            id="clear-btn-tooltip"
                            classes={{
                              tooltip: "tooltip-clear-btn",
                              popper: "popper-clear-btn",
                              tooltipPlacementTop: "tooltip-top",
                            }}
                            title={newUser.title === "" ? "" : "Clear Field"}
                            placement="top"
                          >
                            <InputAdornment
                              className="user-field-clear-adornment"
                              id="text-adornment"
                              position="end"
                            >
                              <IconButton
                                style={
                                  newUser.title === ""
                                    ? {
                                        pointerEvents: "none",
                                        cursor: "default",
                                      }
                                    : {
                                        pointerEvents: "auto",
                                        cursor: "pointer",
                                      }
                                }
                                aria-label="clear input field"
                                onClick={() => {
                                  setNewUser({ ...newUser, title: "" });
                                  setPlaceholder("");
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                                id="clear-icon-button"
                                disabled={newUser.title === "" ? true : false}
                              >
                                <CloseIcon className="close-icon" />
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        }
                      />
                    </FormControl>
                    <input
                      key="title-placeholder"
                      className="title-placeholder"
                      type="text"
                      autoComplete="off"
                      id="title-placeholder"
                      value={placeholder}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
            <Button
              id="create-user-btn"
              className="create-user-btn"
              onClick={handleCreateAndEmailUser}
              disabled={
                !selectedClient ||
                newUser.name.split(" ").length < 2 ||
                !newUser.email.match(
                  new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
                ) ||
                newUser.phone.length < 12
              }
              disableElevation={false}
            >
              Create & Invite User
            </Button>
          </div>
        </div>
        <ExistingUsers
          clientId={selectedClient}
          clientName={
            clientsIsFetched && selectedClient && clientsData.length > 0
              ? clientsData.find((client) => client.id === selectedClient)
                  .shortName
              : ""
          }
          existingUsersFetched={existingUsersFetched}
          existingUsers={existingUsers}
          setOpenCustomInvite={setOpenCustomInvite}
          setSelectedUser={setSelectedUser}
          enqueueSnackbar={enqueueSnackbar}
        />
      </div>
      <SendCustomInvite
        openCustomInvite={openCustomInvite}
        setOpenCustomInvite={setOpenCustomInvite}
        selectedUser={selectedUser}
        clientId={selectedClient}
        clientName={
          clientsIsFetched && selectedClient && clientsData.length > 0
            ? clientsData.find((client) => client.id === selectedClient).name
            : ""
        }
        clientCode={clientCode}
        setNewUser={setNewUser}
        setSelectedUser={setSelectedUser}
        enqueueSnackbar={enqueueSnackbar}
      />
    </>
  );
};

export default InviteNewUser;
