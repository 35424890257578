import ClearIcon from "@mui/icons-material/Clear";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import React from "react";
import "./styles/attach-files.css";

/**
 * AttachFiles Component
 * @description
 * A component that allows admin users the ability to attach files to the email invitation
 * @param {Object} props - The component props.
 * @param {Array<File>} props.files - The list of files that have been uploaded.
 * @param {Function} props.setFiles - A function to update the list of uploaded files.
 * @returns {JSX.Element} The rendered AttachFiles component.
 */
const AttachFiles = ({ files, setFiles }) => {
  // Event handler for drag and drop file upload
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Get the list of files that were dropped
    const uploadedFiles = Array.from(e.dataTransfer.files);
    if (uploadedFiles.length === 0) return;
    // Update the list of files
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  // Event handler for selecting files via file input
  const handleFileSelect = async (e) => {
    // Get the list of files that were selected
    const uploadedFiles = Array.from(e.target.files);

    if (uploadedFiles.length === 0) return;

    // Update the list of files
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };
  // Event handlers for drag and drop events
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrageEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div id="attach-files-container">
      <Box
        className="drag-files-box"
        id="drag-files-box"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDrageEnter}
        onDragLeave={handleDragLeave}
        onClick={() => document.getElementById("hidden-upload").click()}
      >
        <UploadIcon className="upload-file-icon" id="upload-icon" />
        <Typography
          variant="body1"
          className="drag-files-text"
          id="drag-files-text"
        >
          Drag and drop files here
        </Typography>
        <input
          id="hidden-upload"
          type="file"
          hidden
          multiple
          onChange={handleFileSelect}
        />
      </Box>
      <div
        className={files && files.length > 0 ? "" : "hide-attached"}
        id="attached-files"
      >
        {files.map((file, index) => (
          <div key={index} className="attached-file">
            <Typography variant="body1" className="file-name" id="file-name">
              {file.name}
            </Typography>
            <SvgIcon
              component={ClearIcon}
              className="delete-attached-icon"
              id="delete-attached-icon"
              viewBox="3 3 18 18"
              onClick={() => {
                // Remove a specific file from the list
                setFiles((prevFiles) =>
                  prevFiles.filter((f) => f.name !== file.name),
                );
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttachFiles;
