import React, { useState } from "react";
import { List, XLg } from "react-bootstrap-icons";
import logo from "../../assets/full-argus-logo.png";
import NavbarLinks from "./NavbarLinks";
import "./styles/navbar.css";

/**
 * Navbar Component
 *
 * @description
 * A global navigation bar component that displays the Argus logo and links to
 * Argus' marketing website. Component is meant to provide a consistent feel
 * between the project client portal and the marketing website.
 *
 * @see {@linkcode NavbarLinks}
 *
 * @see https://arguscs.com/phoenix/ for the marketing website.
 *
 * @returns {JSX.Element} The rendered Navbar component.
 */
const Navbar = () => {
  // A state variable that keeps track of whether the modal is shown or not
  const [showModal, setShowModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Event listener to update the window width state
  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  /**
   * Updates the value of showModal
   * @param {Object} e - The event object
   * @returns {void}
   */
  const handleShowModal = (e) => {
    if (e.target.id === "hamburger") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  return (
    <div className="navbar-container">
      <div className="navbar-inner">
        <div id="logo-container">
          <a
            href="https://arguscs.com/phoenix/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} alt="Argus Logo" className="logo" />
          </a>
          <h3 id="division-name">Arizona Division</h3>
        </div>
        <div
          className="nav-links-container"
          id={window.innerWidth < 1620 ? "hide-links" : ""}
        >
          {/* Display the links if the windows width is equal to or above 1300 */}
          {window.innerWidth >= 1620 ? (
            <ul id="nav-links-ul">
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Home
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/about/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/who-we-are/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Who We Are
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/services/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Services
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/markets/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Markets
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/who-we-serve/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Who We Serve
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/faq/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/careers/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers
                </a>
              </li>
              <li className="nav-links-li">
                <a
                  href="https://arguscs.com/phoenix/contact/"
                  className="nav-links-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </li>
              <li className="nav-links-li active-listItem">
                <a href="/" className="nav-links-link">
                  Project Client Portal
                </a>
              </li>
            </ul>
          ) : window.innerWidth < 1620 && showModal === true ? (
            <>
              <XLg
                id="x-btn"
                size={30}
                color={"white"}
                onClick={handleShowModal}
              />
              <NavbarLinks />
            </>
          ) : window.innerWidth < 1620 ? (
            <List
              id="hamburger"
              size={35}
              color={"white"}
              onClick={handleShowModal}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
