import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setClientID,
  setProjectID,
} from "../../../../../Redux/Actions/actions";
import useCurrentProject from "../../../DashboardComponents/Form/FormQueries/useCurrentProject";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import useQuestionnaire from "../../../QueryHooks/questionnaire/useQuestionnaire";
import useUpdateQuestionnaire from "../../../QueryHooks/questionnaire/useUpdateQuestionnaire";
import useInvalidInputs from "../../../QueryHooks/validate/useInvalidInputs";
import PageValues from "./PageValues";
import PdfContainer from "./PdfContainer";
import "./styles/validate-request.css";

// Main Validate Request Component
/**
 * ValidateRequest Component
 * @description Main component for validating the questionnaire and container for the PageValues and PdfContainer components
 * @returns {JSX.Element} The rendered ValidateRequest component
 */
const ValidateRequest = () => {
  // Update Appbars heading
  useEffect(() => setHeading("Validate Questionnaire"), []);
  // State variables
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [hoveredChipName, setHoveredChipName] = useState(null);
  const [pdfType, setPdfType] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [clearInvalid, setClearInvalid] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Dashboard Context
  const { setHeading, navigate, clientId, projectId, isAdmin, queryClient } =
    useContext(DashboardContext);

  // Form Data Query
  const { data: formData, isFetched: formFetched } = useCurrentProject(
    clientId,
    projectId,
  );

  // Questionnaire Query
  const {
    data: questionnaire,
    isFetched: questionnaireFetched,
    isFetching: questionnaireFetching,
  } = useQuestionnaire(clientId, projectId);

  // Update Questionnaire Mutation
  const updateQuestionnaire = useUpdateQuestionnaire(clientId, projectId);

  // Invalid Inputs Query
  const { data: invalidData, isFetched: invalidFetched } = useInvalidInputs(
    clientId,
    projectId,
  );

  // Mutation that updates the forms request status, emails the request submitter, and if completed creates SWPPP Narrative
  const adminReview = useMutation({
    mutationFn: async (status) => {
      try {
        const res = await axios.patch(
          `/api/admin-review/${clientId}/${projectId}/`,
          { requestStatus: status },
          { withCredentials: true },
        );

        return res.data;
      } catch (err) {
        console.log("ADMIN REVIEW ERR: ", err);
      }
    },
    onError: (err) => {
      console.log("ADMIN REVIEW ERR: ", err);
      enqueueSnackbar("Error updating request", { variant: "error" });
    },
    onSuccess: (data) => {
      const status = data.requestStatus;
      enqueueSnackbar(`Request updated ${status === "C" ? "Complete" : "RV"}`, {
        variant: "success",
        autoHideDuration: 2500,
      });
      setConfirmOpen(false);
      dispatch(setClientID(null));
      dispatch(setProjectID(null));
      navigate(`/dashboard/admin`, { replace: true });
    },
  });

  // Determines the questionnaire type based on the form data to correctly overlay the PDF
  useEffect(() => {
    if (formData && formFetched) {
      if (formData.slide1.onNativeLand) {
        setPdfType(() => "-epa");
      } else if (
        formData.slide1.onNativeLand === false &&
        +formData.slide4.totAcr < 1
      ) {
        setPdfType(() => "-swmp");
      } else {
        setPdfType(() => "");
      }
    }
  }, [formData, formFetched]);

  // Function to determine the completion status of the questionnaire
  const getProjectStatus = async (e) => {
    e.preventDefault();
    let invalidInputsLength = invalidInputs.length;
    if (invalidInputsLength > 0) {
      // Mutate Request Status to "RV" if inputs are invalid
      try {
        await adminReview.mutateAsync("RV");
      } catch (error) {
        enqueueSnackbar("Error updating request status to 'Revisions Needed'", {
          variant: "error",
        });
      }
    } else {
      try {
        // Mutate Request Status to "C" if all inputs are valid
        await adminReview.mutateAsync("C");
      } catch (error) {
        enqueueSnackbar("Error updating request status to 'Complete'", {
          variant: "error",
        });
      }
    }
  };

  return formFetched && formData ? (
    <>
      <Modal
        id="submit-questionnaire-modal"
        classes={{
          root: "submit-questionnaire-modal",
          paperClasses: "submit-questionnaire-paper",
        }}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="submit-questionnaire-check-modal"
        aria-describedby="submit-questionnaire-check-modal"
      >
        <div className="submit-questionnaire-modal-content">
          <header id="check-submit-header">
            <h1 id="submit-check-header">Validate Questionnaire</h1>
            <hr id="check-submit-hr" />
          </header>
          <div id="check-submit-body">
            <p id="check-submit-message">
              {invalidInputs.length === 0
                ? "Are you sure you want to submit? This will set the status of the request to 'Complete', and the client will be able to download/view the questionnaire."
                : "Are you sure you want to submit? This will set the status of the request to 'Revise', and the client will be able to edit the form."}
            </p>
            <div id="check-submit-buttons">
              <Button
                id="cancel-submit-questionnaire-btn"
                variant="contained"
                color="primary"
                onClick={() => setConfirmOpen(false)}
              >
                Cancel
              </Button>
              <Button
                id="validate-submit-questionnaire-btn"
                variant="contained"
                color="primary"
                onClick={getProjectStatus}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div id="main-validation-container">
        <PageValues
          clientId={clientId}
          projectId={projectId}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
          formData={formData}
          setHoveredChipName={setHoveredChipName}
          updateQuestionnaire={updateQuestionnaire}
          pdfType={pdfType}
          enqueueSnackbar={enqueueSnackbar}
          invalidData={invalidData}
          invalidFetched={invalidFetched}
          setConfirmOpen={setConfirmOpen}
          invalidInputs={invalidInputs}
          setInvalidInputs={setInvalidInputs}
          clearInvalid={clearInvalid}
          setClearInvalid={setClearInvalid}
          queryClient={queryClient}
          questionnaireFetching={questionnaireFetching}
        />
        <PdfContainer
          isAdmin={isAdmin}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
          setNumPages={setNumPages}
          hoveredChipName={hoveredChipName}
          updateQuestionnaire={updateQuestionnaire}
          pdfType={pdfType}
          invalidData={invalidData}
          invalidFetched={invalidFetched}
          questionnaire={questionnaire}
          questionnaireFetched={questionnaireFetched}
        />
      </div>
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default ValidateRequest;
