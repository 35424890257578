import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Tooltip from "@mui/material/Tooltip";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers-pro";
import React, { Fragment, useEffect, useState } from "react";
import useDeleteInvalidInput from "../../../QueryHooks/validate/useDeleteInvalidInput";
import useValidateQuestionnaire from "../../../QueryHooks/validate/useValidateQuestionnaire";
import ChipModal from "./ChipModal";
import {
  clearInvalidPageInputs,
  confirmSubmit,
  createInputGroups,
  invalidPage,
} from "./helper";
import "./styles/page-values.css";

// Component that displays the values of the current page of the questionnaire and allows the user to edit them
/**
 * PageValues Component
 * @description Component that displays the values of the current page of the pdf questionnaire and allows the user to edit their value or validation status. If 1 or more inputs are invalid, and the user submits, the form status will be updated to 'RV', but if there are no invalid inputs, the form status will be updated to 'C'.
 */
const PageValues = ({
  clientId,
  projectId,
  pageNumber,
  formData,
  setPageNumber,
  numPages,
  pdfType,
  setHoveredChipName,
  updateQuestionnaire,
  enqueueSnackbar,
  invalidData,
  invalidFetched,
  setConfirmOpen,
  invalidInputs,
  setInvalidInputs,
  clearInvalid,
  setClearInvalid,
  queryClient,
  questionnaireFetching,
}) => {
  const [open, setOpen] = useState(false);
  const [openName, setOpenName] = useState("");
  const [page1, setPage1] = useState([]);
  const [page2, setPage2] = useState([]);
  const [page3, setPage3] = useState([]);
  const [page4, setPage4] = useState([]);

  // State to manage inputs that should be invalidated together when one of them is invalidated
  const [inputGroup, setInputGroup] = useState({
    p1Groups: {
      startDate: ["duration"],
      submittingToRegAgency: ["regAgencySubmitDate"],
      oName: ["oPhone", "oAdd", "oCity", "oZip"],
    },
    p2Groups: {
      projectAddress: ["projectCity", "projectZip"],
      emergencyContactName: [
        "emergencyContactPhone",
        "emergencyContactEmail",
        "emergencyContactTitle",
        "emergencyContactCompany",
      ],
      portableTanksOnsite: ["portableTankCapacity"],
      bmpContactName: ["bmpCompanyName", "bmpCompanyPhone", "bmpCompanyEmail"],
    },
    p3Groups: {},
    p4Groups: {},
  });

  // Create input groups for invalidation
  useEffect(() => {
    createInputGroups(pdfType, setInputGroup);
  }, []);

  // Validate Questionnaire Query
  const { data: validData, isFetched: validFetched } = useValidateQuestionnaire(
    clientId,
    projectId,
  );

  // Set the page values to the valid data fetched
  useEffect(() => {
    if (validData && validFetched) {
      setPage1(() => validData.page1);
      setPage2(() => validData.page2);
      setPage3(() => validData.page3);
      setPage4(() => validData.page4);
    }
  }, [validData, validFetched]);

  // Set the invalid inputs to the invalid data fetched
  useEffect(() => {
    if (invalidData && invalidFetched) {
      setInvalidInputs(() => invalidData);
    }
  }, [invalidData, invalidFetched]);

  // Delete invalid input mutation
  const deleteInvalidInput = useDeleteInvalidInput(
    clientId,
    projectId,
    enqueueSnackbar,
  );

  // Clear invalid inputs for the current page when the clearInvalid state is true
  useEffect(() => {
    if (clearInvalid === true) {
      clearInvalidPageInputs(
        [page1, page2, page3, page4],
        pageNumber,
        invalidInputs,
        deleteInvalidInput,
        enqueueSnackbar,
      );
      setClearInvalid(() => false);
    }
  }, [clearInvalid]);

  return (
    <div id="page-values-container">
      <h1 id="page-values-header">{`Page ${pageNumber} Values`}</h1>
      <div id="values-container">
        <hr className="page-values-hr" />
        <div id="page-values">
          {(pdfType === "swmp" && pageNumber === 2) ||
            (pdfType !== "swmp" && pageNumber === 3 && (
              <p className="file-info">
                Please go to the files section of the website to require, upload
                or delete a file for this project
              </p>
            ))}
          <div
            className={`page${pageNumber}`}
            id="chips-container"
            style={{
              pointerEvents: questionnaireFetching ? "none !important" : "auto",
            }}
          >
            {validFetched && validData && pageNumber === 1
              ? Object.keys(page1).map((name, index) => {
                  return (
                    <Fragment key={`${name}-fragment`}>
                      <Tooltip
                        key={`${name}-tooltip-${index}`}
                        title={
                          name === "duration"
                            ? // name === "duration"
                              `Start Date: ${
                                formData.slide5.startDate
                              }EST. End Date: ${
                                queryClient.getQueryData([
                                  "currentProject",
                                  clientId,
                                  projectId,
                                ])?.slide5?.endDate
                              }`
                            : ""
                        }
                        placement="top"
                        arrow
                        classes={{
                          tooltip: "durationTooltip",
                          arrow: "durationArrow",
                          popper: "durationPopper",
                        }}
                      >
                        <Chip
                          key={`${name}-chip-${index}`}
                          id={`${name}-chip`}
                          style={{
                            pointerEvents: questionnaireFetching
                              ? "none"
                              : "auto",
                          }}
                          className={`page-value-chip ${
                            invalidInputs.filter(
                              (input) => input?.name === name,
                            ).length > 0
                              ? "invalid-chip"
                              : "valid-chip"
                          }`}
                          label={page1[name][0]}
                          clickable={true}
                          onClick={() => {
                            setOpenName(() => name);
                            setOpen(() => true);
                          }}
                          onMouseEnter={() => {
                            questionnaireFetching
                              ? setHoveredChipName(() => null)
                              : setHoveredChipName(() => name);
                          }}
                          onMouseLeave={() => {
                            setHoveredChipName(() => null);
                          }}
                        />
                      </Tooltip>
                      <ChipModal
                        key={`${name}-modal-${index}`}
                        clientId={clientId}
                        projectId={projectId}
                        name={name}
                        open={open}
                        setOpen={setOpen}
                        openName={openName}
                        setOpenName={setOpenName}
                        pageState={page1}
                        setPageState={setPage1}
                        invalidInputs={invalidInputs}
                        setInvalidInputs={setInvalidInputs}
                        formData={formData}
                        updateQuestionnaire={updateQuestionnaire}
                        enqueueSnackbar={enqueueSnackbar}
                        inputGroup={inputGroup.p1Groups}
                      />
                    </Fragment>
                  );
                })
              : validFetched && validData && pageNumber === 2
              ? Object.keys(page2).map((name, index) => {
                  return (
                    <Fragment key={`${name}-fragment`}>
                      <Chip
                        key={`${name}-chip-${index}`}
                        id={`${name}-chip`}
                        className={`page-value-chip ${
                          invalidInputs.filter((input) => input?.name === name)
                            .length > 0
                            ? "invalid-chip"
                            : "valid-chip"
                        }`}
                        label={page2[name][0]}
                        style={
                          name === "emergencyContactCompany"
                            ? { display: "none" }
                            : {}
                        }
                        clickable={true}
                        onClick={() => {
                          setOpenName(() => name);
                          setOpen(() => true);
                        }}
                        onMouseEnter={() => {
                          setHoveredChipName(() => name);
                        }}
                        onMouseLeave={() => {
                          setHoveredChipName(() => null);
                        }}
                      />
                      <ChipModal
                        key={`${name}-modal-${index}`}
                        clientId={clientId}
                        projectId={projectId}
                        name={name}
                        open={open}
                        setOpen={setOpen}
                        openName={openName}
                        setOpenName={setOpenName}
                        pageState={page2}
                        setPageState={setPage2}
                        invalidInputs={invalidInputs}
                        setInvalidInputs={setInvalidInputs}
                        formData={formData}
                        updateQuestionnaire={updateQuestionnaire}
                        enqueueSnackbar={enqueueSnackbar}
                        inputGroup={inputGroup.p2Groups}
                      />
                    </Fragment>
                  );
                })
              : validFetched && validData && pageNumber === 3
              ? Object.keys(page3).map((name, index) => {
                  return (
                    <Fragment key={`${name}-fragment`}>
                      <Chip
                        key={`${name}-chip-${index}`}
                        id={`${name}-chip`}
                        className={`page-value-chip ${
                          invalidInputs.filter((input) => input?.name === name)
                            .length > 0
                            ? "invalid-chip"
                            : page3[name][1] === true
                            ? "required-chip"
                            : "valid-chip"
                        }`}
                        label={page3[name][0]}
                        clickable={page3[name][3] !== "file" ? true : false}
                        onClick={() => {
                          if (page3[name][3] !== "file") {
                            setOpenName(() => name);
                            setOpen(() => true);
                          }
                        }}
                        onMouseEnter={() => {
                          setHoveredChipName(() => name);
                        }}
                        onMouseLeave={() => {
                          setHoveredChipName(() => null);
                        }}
                      />
                      <ChipModal
                        key={`${name}-modal-${index}`}
                        clientId={clientId}
                        projectId={projectId}
                        name={name}
                        open={open}
                        setOpen={setOpen}
                        openName={openName}
                        setOpenName={setOpenName}
                        pageState={page3}
                        setPageState={setPage3}
                        invalidInputs={invalidInputs}
                        setInvalidInputs={setInvalidInputs}
                        formData={formData}
                        updateQuestionnaire={updateQuestionnaire}
                        enqueueSnackbar={enqueueSnackbar}
                        inputGroup={inputGroup.p3Groups}
                      />
                    </Fragment>
                  );
                })
              : validFetched && validData && pageNumber === 4
              ? Object.keys(page4).map((name, index) => {
                  return (
                    <Fragment key={`${name}-fragment`}>
                      <Chip
                        key={`${name}-chip-${index}`}
                        id={`${name}-chip`}
                        className={`page-value-chip ${
                          invalidInputs.filter((input) => input?.name === name)
                            .length > 0
                            ? "invalid-chip"
                            : "valid-chip"
                        }`}
                        label={page4[name][0]}
                        clickable={true}
                        onClick={() => {
                          setOpenName(() => name);
                          setOpen(() => true);
                        }}
                        onMouseEnter={() => {
                          setHoveredChipName(() => name);
                        }}
                        onMouseLeave={() => {
                          setHoveredChipName(() => null);
                        }}
                      />
                      <ChipModal
                        key={`${name}-modal-${index}`}
                        clientId={clientId}
                        projectId={projectId}
                        name={name}
                        open={open}
                        setOpen={setOpen}
                        openName={openName}
                        setOpenName={setOpenName}
                        pageState={page4}
                        setPageState={setPage4}
                        invalidInputs={invalidInputs}
                        setInvalidInputs={setInvalidInputs}
                        formData={formData}
                        updateQuestionnaire={updateQuestionnaire}
                        enqueueSnackbar={enqueueSnackbar}
                        inputGroup={inputGroup.p4Groups}
                      />
                    </Fragment>
                  );
                })
              : null}
          </div>
          <div className={`pdf-btns-container pdf-page${pageNumber}`}>
            <Button
              variant="contained"
              id="validate-page-button"
              disabled={
                !invalidInputs ||
                invalidInputs.length === 0 ||
                !invalidPage(pageNumber, invalidInputs, [
                  page1,
                  page2,
                  page3,
                  page4,
                ])
              }
              onClick={() => {
                clearInvalidPageInputs(
                  [page1, page2, page3, page4],
                  pageNumber,
                  invalidInputs,
                  deleteInvalidInput,
                  enqueueSnackbar,
                );
              }}
              sx={{
                display:
                  invalidInputs.length === 0 ||
                  !invalidPage(pageNumber, invalidInputs, [
                    page1,
                    page2,
                    page3,
                    page4,
                  ])
                    ? "none"
                    : "flex",
              }}
            >
              Validate Page
            </Button>
            <Button
              id="pdf-submit"
              variant="contained"
              onClick={(e) => confirmSubmit(e, setConfirmOpen)}
              disabled={
                updateQuestionnaire.isLoading || pageNumber !== numPages
              }
              sx={{
                display: pageNumber !== numPages ? "none" : "flex",
              }}
            >
              Submit
            </Button>
          </div>
          <Pagination
            id="pdf-pagination"
            className="pdf-pagination"
            count={numPages}
            page={pageNumber}
            onChange={(e, val) => {
              setPageNumber(() => val);
              window.scrollTo(0, 0);
            }}
            size="large"
            shape="circular"
            classes={{
              root: "pdf-pagination-root",
              ul: "pdf-pagination-ul",
            }}
            renderItem={(item) => (
              <PaginationItem
                className="pdf-pagination-item"
                id={"pagination-item"}
                slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                classes={{
                  root: "pdf-pagination-item-root",
                  page: "pdf-pagination-page",
                  previousNext: "pdf-pagination-icon",
                  text: "pdf-pagination-text",
                  rounded: "pdf-pagination-rounded",
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PageValues;
