import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper.js";
import DateField from "../Inputs/DateField.jsx";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField.jsx";
import TitleField from "../Inputs/TitleField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 4: Project Information
const ProjectInformation = ({
  setSlideStatus,
  slideState,
  formStateLoaded,
}) => {
  const [disabledInputs, setDisabledInputs] = useState([]);

  const {
    slide1,
    slide4,
    setSlide4,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide4);
  }, []);

  // Update Slide State
  const slide4Mutation = useSetSlide(
    clientId,
    projectId,
    4,
    setIsLoading,
    setSlideStatus,
  );

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "4",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <DateField
            idProp="startDate"
            label="Start Date"
            state={slide4}
            setState={setSlide4}
            val={slide4.startDate}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            req={
              slide4.startDate === "" ||
              (slide4.endDate !== "" &&
                dayjs(slide4.startDate).isAfter(slide4.endDate))
            }
            disabledInput={disabledInputCheck("startDate", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("startDate", disabledInputs) === false
                ? getInputMessage("startDate", invalidInputsData, "4")
                : ""
            }
            invalidData={getInvalidData("startDate", invalidInputsData, "4")}
          />
          <DateField
            idProp="endDate"
            label="Estimated End Date"
            state={slide4}
            setState={setSlide4}
            val={slide4.endDate}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            req={
              slide4.endDate === "" ||
              dayjs(slide4.endDate).isBefore(slide4.startDate)
            }
            disabledInput={disabledInputCheck("endDate", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("endDate", disabledInputs) === false
                ? getInputMessage("endDate", invalidInputsData, "4")
                : ""
            }
            invalidData={getInvalidData("endDate", invalidInputsData, "4")}
          />
          <TextField
            idProp="emergencyContactName"
            label="24 Hour Contact"
            val={slide4.emergencyContactName}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? names : []}
            req={!isValidName(slide4.emergencyContactName)}
            type="name"
            disabledInput={disabledInputCheck(
              "emergencyContactName",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactName", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactName",
                    invalidInputsData,
                    "4",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactName",
              invalidInputsData,
              "4",
            )}
          />
          <PhoneField
            idProp="emergencyContactPhone"
            label="Contact's Phone"
            val={slide4.emergencyContactPhone}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            req={!isValidPhoneNumber(slide4.emergencyContactPhone)}
            disabledInput={disabledInputCheck(
              "emergencyContactPhone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactPhone", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactPhone",
                    invalidInputsData,
                    "4",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactPhone",
              invalidInputsData,
              "4",
            )}
          />
          <EmailField
            idProp="emergencyContactEmail"
            label="Contact's Email"
            val={slide4.emergencyContactEmail}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? emails : []}
            req={!isValidEmail(slide4.emergencyContactEmail)}
            disabledInput={disabledInputCheck(
              "emergencyContactEmail",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactEmail", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactEmail",
                    invalidInputsData,
                    "4",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactEmail",
              invalidInputsData,
              "4",
            )}
          />
          <TitleField
            idProp="emergencyContactTitle"
            label="Contact's Title"
            val={slide4.emergencyContactTitle}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? titles : []}
            // req={
            //   slide4.emergencyContactTitle === "" ||
            //   slide4.emergencyContactTitle.length < 2
            // }
            disabledInput={disabledInputCheck(
              "emergencyContactTitle",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactTitle", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactTitle",
                    invalidInputsData,
                    "4",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactTitle",
              invalidInputsData,
              "4",
            )}
          />
          {slide1.onNativeLand ? (
            <>
              <RadioGroupField
                idProp="prevAgricultureLand"
                label="Land Previously Used for Agriculture"
                val={slide4.prevAgricultureLand}
                state={slide4}
                setState={setSlide4}
                debouncedSave={slide4Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "prevAgricultureLand",
                  disabledInputs,
                )}
              />
              <RadioGroupField
                idProp="demolitionActivities"
                label="Onsite Demolition Activities"
                val={slide4.demolitionActivities}
                state={slide4}
                setState={setSlide4}
                debouncedSave={slide4Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "demolitionActivities",
                  disabledInputs,
                )}
              />
              <RadioGroupField
                idProp="dewateringDischarge"
                label="Discharging Dewatering Water"
                val={slide4.dewateringDischarge}
                state={slide4}
                setState={setSlide4}
                debouncedSave={slide4Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "dewateringDischarge",
                  disabledInputs,
                )}
              />
            </>
          ) : null}
          <TextField
            idProp={"totAcr"}
            label="Total Acreage"
            state={slide4}
            setState={setSlide4}
            val={slide4.totAcr}
            setIsLoading={setIsLoading}
            req={slide4.totAcr === ""}
            type="acreage"
            debouncedSave={projectId ? slide4Mutation : undefined}
            disabledInput={disabledInputCheck("totAcr", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("totAcr", disabledInputs) === false
                ? getInputMessage("totAcr", invalidInputsData, "4")
                : ""
            }
            invalidData={getInvalidData("totAcr", invalidInputsData, "4")}
          />
          <TextField
            idProp={"distAcr"}
            label="Acreage to be Disturbed"
            state={slide4}
            setState={setSlide4}
            val={slide4.distAcr}
            setIsLoading={setIsLoading}
            req={slide4.distAcr === "" || +slide4.distAcr > +slide4.totAcr}
            type="acreage"
            debouncedSave={projectId ? slide4Mutation : undefined}
            disabledInput={disabledInputCheck("distAcr", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("distAcr", disabledInputs) === false
                ? getInputMessage("distAcr", invalidInputsData, "4")
                : ""
            }
            invalidData={getInvalidData("distAcr", invalidInputsData, "4")}
          />
          <RadioGroupField
            idProp="layDownYard"
            label="Laydown Yard"
            val={slide4.layDownYard}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("layDownYard", disabledInputs)}
          />
          {slide4.layDownYard ? (
            <RadioGroupField
              idProp="layDownYardDist"
              label="Included on Disturbed Acreage?"
              val={slide4.layDownYard === true ? slide4.layDownYardDist : false}
              state={slide4}
              setState={setSlide4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck(
                "layDownYardDist",
                disabledInputs,
              )}
            />
          ) : null}
          <RadioGroupField
            idProp="borrowArea"
            label="Onsite Plant/Borrow Area"
            val={slide4.borrowArea}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("borrowArea", disabledInputs)}
          />
          <RadioGroupField
            idProp="portableTanksOnSite"
            label="On-Site Portable Fuel Tanks"
            val={slide4.portableTanksOnSite}
            state={slide4}
            setState={setSlide4}
            debouncedSave={slide4Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck(
              "portableTanksOnSite",
              disabledInputs,
            )}
          />
          {slide4.portableTanksOnSite ? (
            <TextField
              idProp="portableTankCapacity"
              label="Tank(s) Capacity"
              state={slide4}
              setState={setSlide4}
              val={slide4.portableTankCapacity}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              // req={
              //   slide4.portableTanksOnSite && slide4.portableTankCapacity === ""
              // }
              disabledInput={disabledInputCheck(
                "portableTankCapacity",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("portableTankCapacity", disabledInputs) ===
                false
                  ? getInputMessage(
                      "portableTankCapacity",
                      invalidInputsData,
                      "4",
                    )
                  : ""
              }
              invalidData={getInvalidData(
                "portableTankCapacity",
                invalidInputsData,
                "4",
              )}
            />
          ) : null}
          <>
            <TextField
              idProp="bmpCompanyName"
              label="BMP Installer"
              val={slide4.bmpCompanyName}
              state={slide4}
              setState={setSlide4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? companies : []}
              // req={
              //   slide4.bmpCompanyName === "" || slide4.bmpCompanyName.length < 2
              // }
              type="company"
              disabledInput={disabledInputCheck(
                "bmpCompanyName",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpCompanyName", disabledInputs) === false
                  ? getInputMessage("bmpCompanyName", invalidInputsData, "4")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpCompanyName",
                invalidInputsData,
                "4",
              )}
            />
            <TextField
              idProp="bmpContactName"
              label="Contact Name"
              val={slide4.bmpContactName}
              state={slide4}
              setState={setSlide4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? names : []}
              req={!isValidName(slide4.bmpContactName)}
              type="name"
              disabledInput={disabledInputCheck(
                "bmpContactName",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactName", disabledInputs) === false
                  ? getInputMessage("bmpContactName", invalidInputsData, "4")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactName",
                invalidInputsData,
                "4",
              )}
            />
            <PhoneField
              idProp="bmpContactPhone"
              label="Contact's Phone"
              val={slide4.bmpContactPhone}
              state={slide4}
              setState={setSlide4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? phoneNumbers : []}
              req={!isValidPhoneNumber(slide4.bmpContactPhone)}
              disabledInput={disabledInputCheck(
                "bmpContactPhone",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactPhone", disabledInputs) === false
                  ? getInputMessage("bmpContactPhone", invalidInputsData, "4")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactPhone",
                invalidInputsData,
                "4",
              )}
            />
            <EmailField
              idProp="bmpContactEmail"
              label="Contact's Email"
              val={slide4.bmpContactEmail}
              state={slide4}
              setState={setSlide4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? emails : []}
              req={!isValidEmail(slide4.bmpContactEmail)}
              disabledInput={disabledInputCheck(
                "bmpContactEmail",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactEmail", disabledInputs) === false
                  ? getInputMessage("bmpContactEmail", invalidInputsData, "4")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactEmail",
                invalidInputsData,
                "4",
              )}
            />
          </>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProjectInformation;
