import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Main Application Context
export const DashboardContext = createContext();

// Main Application Context Provider
export const DashboardContextProvider = ({ children }) => {
  // Redux state values & hooks
  const queryClient = useQueryClient();
  const isAdmin = useSelector((state) => state.user.admin);
  const user = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const client = useSelector((state) => state.client);
  const clientId = useSelector((state) => state.user.currentClient);
  const projectId = useSelector((state) => state.user.currentProject);
  const archive = useSelector((state) => state.user.hideArchived);
  const navigate = useNavigate();

  // State values used across the dashboard
  const [heading, setHeading] = useState("Dashboard");
  const [hideArchived, setHideArchived] = useState(archive);
  const [openModal, setOpenModal] = useState(
    isAdmin === true ||
      (isAdmin === false && user.id !== "" && user.id !== null)
      ? false
      : true,
  );
  // Invite User
  const [emailAnchor, setEmailAnchor] = useState(null);
  const [openEmail, setOpenEmail] = useState(false);
  const [currEmailRow, setCurrEmailRow] = useState(null);
  // Contacts
  const [contactsAnchor, setContactsAnchor] = useState(null);
  const [openContacts, setOpenContacts] = useState(false);
  const [currContactsRow, setCurrContactsRow] = useState(null);
  // Services
  const [servicesAnchor, setServicesAnchor] = useState(null);
  const [openServices, setOpenServices] = useState(false);
  const [currServicesRow, setCurrServicesRow] = useState(null);
  const [swpppAnchor, setSwpppAnchor] = useState(null);
  const [openSwppp, setOpenSwppp] = useState(false);
  const [currSwpppRow, setCurrSwpppRow] = useState(null);
  const [dustAnchor, setDustAnchor] = useState(null);
  const [openDust, setOpenDust] = useState(false);
  const [currDustRow, setCurrDustRow] = useState(null);
  // Clients (Admin only) Select
  const [openSelect, setOpenSelect] = useState(false);
  // Sidebar
  const [openSidebar, setOpenSidebar] = useState(false);

  // Popover Handlers
  const openSwpppPopoverHandler = (e) => {
    let id = e.currentTarget.id;
    setCurrSwpppRow(id);
    setSwpppAnchor(e.currentTarget.parentElement);
  };

  const openDustPopoverHandler = (e) => {
    let id = e.currentTarget.id;
    setCurrDustRow(id);
    setDustAnchor(e.currentTarget.parentElement);
  };

  const openEmailPopoverHandler = (e) => {
    let id = e.currentTarget.id;
    setCurrEmailRow(id);
    setEmailAnchor(e.currentTarget.parentElement);
  };

  const openContactsPopoverHandler = (e) => {
    let id = e.currentTarget.id;
    setCurrContactsRow(id);
    setContactsAnchor(e.currentTarget.parentElement);
  };

  const openServicesPopoverHandler = (e) => {
    let id = e.currentTarget.id;
    setCurrServicesRow(id);
    setServicesAnchor(e.currentTarget.parentElement);
  };

  // Phone Number Input Formatter
  const handleFormatPhone = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  // Person Names Input Formatter
  const capitalizeNameAfterSpace = (value) => {
    return value
      .toLowerCase()
      .split(" ")
      .map(
        (word) =>
          word.charAt(0).toUpperCase() + word.substring(1).toLowerCase(),
      )
      .join(" ");
  };

  return (
    <DashboardContext.Provider
      value={{
        client,
        clientId,
        projectId,
        user,
        userId,
        isAdmin,
        heading,
        setHeading,
        hideArchived,
        setHideArchived,
        openModal,
        setOpenModal,
        navigate,
        queryClient,
        servicesAnchor,
        setServicesAnchor,
        openServices,
        setOpenServices,
        currServicesRow,
        setCurrServicesRow,
        openServicesPopoverHandler,
        swpppAnchor,
        setSwpppAnchor,
        openSwppp,
        setOpenSwppp,
        currSwpppRow,
        setCurrSwpppRow,
        openSwpppPopoverHandler,
        dustAnchor,
        setDustAnchor,
        openDust,
        setOpenDust,
        currDustRow,
        setCurrDustRow,
        openDustPopoverHandler,
        openSelect,
        setOpenSelect,
        openSidebar,
        setOpenSidebar,
        handleFormatPhone,
        capitalizeNameAfterSpace,
        openEmail,
        setOpenEmail,
        emailAnchor,
        setEmailAnchor,
        currEmailRow,
        setCurrEmailRow,
        openEmailPopoverHandler,
        contactsAnchor,
        setContactsAnchor,
        openContacts,
        setOpenContacts,
        currContactsRow,
        setCurrContactsRow,
        openContactsPopoverHandler,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
