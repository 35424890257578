import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import SvgIcon from "@mui/material/SvgIcon";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import { BlankFileIcon, customStyles } from "./ArgusCustomIcon";
import useRequiredProjectFiles from "./FileQueries/useRequiredProjectFiles";
import "./styles/project-files.css";
import UploadFilesModal from "./UploadFilesModal/UploadFilesModal";

// Component to display non-uploaded (admin only) or required files for a project
const RequiredFiles = ({ isAdmin, projectId, userType }) => {
  const missingFileRefs = useRef({});
  const newFileRefs = useRef({});
  const [openSettings, setOpenSettings] = useState(false);
  const [openCheckbox, setOpenCheckbox] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requireFiles, setRequireFiles] = useState({
    required: [],
    notRequired: [],
  });
  const [selectedFile, setSelectedFile] = useState({
    id: "",
    name: "",
    file: "",
    required: false,
  });
  const { clientId, queryClient } = useContext(DashboardContext);
  const { enqueueSnackbar } = useSnackbar();

  // Query to get the required files for a project
  const { data: requiredFiles, isFetched: isRequiredFilesFetched } =
    useRequiredProjectFiles(projectId, userType);

  // Mutation to notify the client of updated required files
  const notifyClient = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.post(
          `/api/client-notification/${clientId}/${projectId}/`,
          { location: "required-files" },
          {
            withCredentials: true,
          },
        );

        return res.data;
      } catch (err) {
        console.log("NOTIFY CLIENT ERR: ", err);
      }
    },
    onError: (err) => {
      enqueueSnackbar("Error notifying submitter!", {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      enqueueSnackbar(
        "The submitter of this request has been notified of the updated required files",
        {
          variant: "success",
        },
      );
    },
  });

  // Mutation to send the required files to the backend
  const sendRequireFiles = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.patch(
          `/api/require-files/${projectId}/`,
          {
            files: requireFiles,
          },
          {
            withCredentials: true,
          },
        );

        return res.data;
      } catch (err) {
        console.log("SEND REQUIRE FILES ERR: ", err);
      }
    },
    onSuccess: async (data) => {
      enqueueSnackbar("File(s) requirement status successfully updated", {
        variant: "success",
      });
      setOpenCheckbox(false);
      if (data.newRequiredFiles > 1 && data.requestStatus) {
        if (data.requestStatus === "C" || data.requestStatus === "P") {
          await notifyClient.mutateAsync();
        }
      }
    },
    onError: (err) => {
      enqueueSnackbar("Error requiring file(s)! Please try again!", {
        variant: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["required-project-files", projectId, userType],
      });
      queryClient.invalidateQueries({
        queryKey: ["uploaded-project-files", projectId, userType],
      });
    },
  });

  // Create a ref for each file icon to be able to trigger the file input
  useEffect(() => {
    if (isRequiredFilesFetched && requiredFiles?.files.length > 0) {
      requiredFiles.files.forEach((file) => {
        if (file.name) {
          missingFileRefs.current[file.name] = createRef();
        }
        if (file.id) {
          newFileRefs.current[file.id] = createRef();
        }
      });
    }
  }, [isRequiredFilesFetched, requiredFiles]);

  // Update the file requirement status
  useEffect(() => {
    if (isRequiredFilesFetched && requiredFiles?.files.length > 0) {
      let notRequiredFiles = [];
      let currentRequiredFiles = [];
      requiredFiles.files.forEach((file) => {
        if (!file.required) {
          notRequiredFiles.push(file.id);
        } else {
          currentRequiredFiles.push(file.id);
        }
      });
      setRequireFiles({
        required: currentRequiredFiles,
        notRequired: notRequiredFiles,
      });
    }
  }, [isRequiredFilesFetched, requiredFiles]);

  // Set the active file when openSettings is true
  useEffect(() => {
    if (openSettings && selectedFile.id && selectedFile.name) {
      const currentFile = document.getElementById(
        `${selectedFile.name}-${selectedFile.id}`,
      );

      if (currentFile && !currentFile.classList.contains("active-file")) {
        // add active class to the selected file
        currentFile.classList.add("active-file");
      }
    } else {
      // find the active file and remove the active class
      const activeFiles = document.getElementsByClassName("active-file");
      if (activeFiles.length > 0) {
        for (let i = 0; i < activeFiles.length; i++) {
          activeFiles[i].classList.remove("active-file");
        }
      }
    }
  }, [openSettings, selectedFile]);

  return (
    <div className="file-type-container" id="missing-files-container">
      <div
        className="file-settings-container"
        style={{
          visibility: openSettings ? "visible" : "hidden",
        }}
      >
        <Button
          className="update-file-button"
          id="update-file-button"
          variant="contained"
          onClick={(e) => setOpenModal(true)}
        >
          Upload
        </Button>
      </div>
      {userType === "admin" && (
        <>
          <Button
            className={`require-files-button ${openCheckbox ? "close" : ""}`}
            id="require-files-button"
            variant="contained"
            onClick={() => {
              setOpenCheckbox((prev) => !prev);
            }}
          >
            {openCheckbox ? "Close" : "Require Files"}
          </Button>
          {openCheckbox && (
            <Button
              className="submit-required-files-button"
              id="submit-required-files-button"
              variant="contained"
              onClick={async () => {
                await sendRequireFiles.mutateAsync();
              }}
            >
              Submit
            </Button>
          )}
        </>
      )}
      <div className="files-container">
        {isRequiredFilesFetched && requiredFiles?.files.length > 0
          ? requiredFiles.files.map((file) => {
              if (userType !== "admin" && !file.required) {
                return null;
              }
              return (
                <div
                  key={`${file.name}-${file.id}`}
                  className={`file-container missing ${
                    file.required
                      ? "required-file"
                      : openCheckbox
                      ? "require-file"
                      : ""
                  }`}
                  id={`${file.name}-${file.id}`}
                  ref={missingFileRefs.current[file.name]}
                  onClick={(e) => {
                    if (openCheckbox) return;
                    const activeFiles =
                      document.getElementsByClassName("active-file");
                    if (activeFiles.length > 0) {
                      for (let i = 0; i < activeFiles.length; i++) {
                        activeFiles[i].classList.remove("active-file");
                      }
                    }
                    e.currentTarget.classList.add("active-file");
                    const fileInfo = {
                      id: file.id,
                      name: file.name,
                      file: "",
                      required: file.required,
                    };
                    setSelectedFile(() => fileInfo);
                    setOpenSettings(() => true);
                  }}
                >
                  {openCheckbox && (
                    <div className="require-file-checkbox-container">
                      <Checkbox
                        className="require-file-checkbox"
                        value={file.id}
                        checked={requireFiles.required.includes(file.id)}
                        onChange={(e) => {
                          let file_id = +e.target.value;
                          let checked = e.target.checked;

                          if (checked) {
                            if (!requireFiles.required.includes(file_id)) {
                              let newRequireFiles = [
                                ...requireFiles.required,
                                file_id,
                              ];
                              let newNotRequireFiles =
                                requireFiles.notRequired.filter(
                                  (id) => id !== file_id,
                                );
                              setRequireFiles({
                                required: newRequireFiles,
                                notRequired: newNotRequireFiles,
                              });
                            }
                          } else {
                            if (!requireFiles.notRequired.includes(file_id)) {
                              let newRequireFiles =
                                requireFiles.required.filter(
                                  (id) => id !== file_id,
                                );
                              let newNotRequireFiles = [
                                ...requireFiles.notRequired,
                                file_id,
                              ];
                              setRequireFiles({
                                required: newRequireFiles,
                                notRequired: newNotRequireFiles,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="file-container missing">
                    <SvgIcon className="file-icon" inheritViewBox>
                      <BlankFileIcon
                        extension={`${file.required ? "required" : ""}`}
                        uniqueId={`${file.name}_${file.id}`}
                        {...customStyles[
                          `${file.required ? "required" : "unknown"}`
                        ]}
                      />
                    </SvgIcon>
                    <p
                      className={`file-name missing-text ${
                        file.required ? "required-file-text" : ""
                      }`}
                    >
                      {file.name !== "endangeredSpeciesReport"
                        ? file.displayName
                        : "Endangered Species Report"}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <UploadFilesModal
        open={openModal}
        setOpen={setOpenModal}
        projectId={projectId}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setOpenSettings={setOpenSettings}
      />
      {selectedFile && openSettings && (
        <ClickAwayListener
          onClickAway={(e) => {
            if (
              openSettings == false ||
              e.target.id === `${selectedFile.name}-file` ||
              e.target.className.includes("file-settings-button") ||
              e.target.className.includes("uploaded-file-button-group")
            ) {
              return;
            }
            if (!e.target.className.includes("active-file")) {
              setOpenSettings(() => false);
              setSelectedFile(() => ({
                id: "",
                name: "",
                url: "",
              }));
            }
          }}
        >
          <div className="click-away-area" />
        </ClickAwayListener>
      )}
    </div>
  );
};

export default RequiredFiles;
